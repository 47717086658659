import React, { useEffect, useState } from "react";
import Axios from "axios";
import Navbar from "./Navbar";
import Moment from "moment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { useHistory, useParams } from "react-router-dom";
import { message, Spin } from "antd";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Grid } from "semantic-ui-react";
import classes from "./ViewOpp.module.css";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { SiGmail } from "react-icons/si";
import { IconContext } from "react-icons";

import EmailModal from "./Components/Promotional/EmailModal";
import SMSModal from "./Components/Promotional/SMSModal";
import WhatsappModal from "./Components/Promotional/WhatsappModal";
import { RiWhatsappFill } from "react-icons/ri";

function ViewOpp(props) {
  Axios.defaults.withCredentials = true;
  const user = useSelector((states) => states.auth);
  const [opp, setOpp] = useState([]);
  // const params = useParams();
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [statuses, setStatuses] = useState([]);
  const [log, setLog] = useState([]);
  const [logError, setLogError] = useState(false);
  const [remarksError, setRemarksError] = useState(false);
  const [LogType, setLogType] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [followupDate, setFollowupDate] = useState(opp.FollowupDate);
  const [followupDateError, setFollowupDateError] = useState(false);
  const [price, setPrice] = useState(opp.CoursePrice);
  const [priceError, setPriceError] = useState(false);
  const [course, setCourse] = useState(opp.Course);
  const [courseError, setCourseError] = useState(false);
  const [courseDetail, setCourseDetail] = useState([]);

  const [mobile2, setMobile2] = useState(0);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const params = useParams();
  const viewId = props.viewId || params.id;

  useEffect(() => {
    document.title = "View Opportunity";
    Axios.get("/api/lead/readOneLead/" + viewId).then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 100);
      setOpp(response.data.lead);
    });

    Axios.get("/api/status/readAllStatus").then((response) => {
      setStatuses(response.data.statuss);
    });

    Axios.get("/api/lead/readOneLead/" + viewId).then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 100);
      setLog(response.data.lead);
    });

    Axios.get("/api/course/readAllCourse").then((response) => {
      setCourseDetail(response.data.courses);
    });
  }, [viewId]);

  const addToList = () => {
    // if (status !== statuses[0].StatusName) {
    //   setStatus(statuses[0].StatusName)
    // }
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put(
      "/api/lead/modifyLead/" + viewId,
      {
        Status: status,
        whoChangeState: user.username,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        // setTimeout(function () {
        //   window.location.reload()
        // }, 800)
        Axios.get("/api/lead/readOneLead/" + viewId).then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setOpp(response.data.lead);
        });
        Axios.get("/api/lead/readOneLead/" + viewId).then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setLog(response.data.lead);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const addFollowup = () => {
    setFollowupDateError(false);

    if (followupDate === "") {
      setFollowupDateError(true);
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.put(
      "/api/lead/modifyLead/" + viewId,
      {
        FollowupDate: Moment(followupDate).format("YYYY-MM-DD"),
      },
      config
    )
      .catch((err) => {
        console.log(err);
      })
      .then(function (response) {
        message.success(" Updated successfully");
        Axios.get("/api/lead/readOneLead/" + viewId).then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setOpp(response.data.lead);
        });
        Axios.get("/api/lead/readOneLead/" + viewId).then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setLog(response.data.lead);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const addToNote = () => {
    setLogError(false);
    setRemarksError(false);

    if (LogType === "") {
      setLogError(true);
      return;
    }
    if (Remarks === "") {
      setRemarksError(true);
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put(
      "/api/lead/modifyLead/" + viewId,
      {
        LogType,
        Remarks,
        CreatedBy: user.name,
      },
      config
    )
      .then(function (response) {
        message.success(" Added successfully");
        // setTimeout(function () {
        //   window.location.reload()
        // }, 800)
        Axios.get("/api/lead/readOneLead/" + viewId).then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setLog(response.data.lead);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });

    setLogType("");
    setRemarks("");
  };

  const addPrice = () => {
    setPriceError(false);
    if (price.toString() === "") {
      setPriceError(true);
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put(
      "/api/lead/modifyLead/" + viewId,
      {
        CoursePrice: price,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        Axios.get("/api/lead/readOneLead/" + viewId).then((response) => {
          setOpp(response.data.lead);
          setLog(response.data.lead);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const addCourse = () => {
    setCourseError(false);
    if (course === "") {
      setCourseError(true);
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put(
      "/api/lead/modifyLead/" + viewId,
      {
        Course: course,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        Axios.get("/api/lead/readOneLead/" + viewId).then((response) => {
          setOpp(response.data.lead);
          setLog(response.data.lead);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  useEffect(() => {
    followupDate && addFollowup();
    document.getElementById("closeModalbtn").click();
    // eslint-disable-next-line
  }, [followupDate]);

  const addEmail = () => {
    setEmailError(false);
    if (
      email !== "" &&
      !email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setEmailError(true);
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put(
      "/api/lead/modifyLead/" + viewId,
      {
        Email: email,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        Axios.get("/api/lead/readOneLead/" + viewId).then((response) => {
          setOpp(response.data.lead);
          setLog(response.data.lead);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const addPhone2 = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.put(
      "/api/lead/modifyLead/" + viewId,
      {
        Phone2: mobile2,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        Axios.get("/api/lead/readOneLead/" + viewId).then((response) => {
          setOpp(response.data.lead);
          setLog(response.data.lead);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  // start promotion

  const [whatsappMessageTemplates, setWhatsappMessageTemplates] = useState();
  const [whatsappLinkTemplates, setWhatsappLinkTemplates] = useState();
  const [whatsappImageTemplates, setWhatsappImageTemplates] = useState();
  const [whatsappDocumentTemplates, setWhatsappDocumentTemplates] = useState();
  const [smsTemplates, setsmsTemplates] = useState();
  const [mailTemplates, setmailTemplates] = useState();

  useEffect(() => {
    Axios.get("/api/template/readAllTemplate").then((response) => {
      setWhatsappMessageTemplates(response.data.Templates);
    });
    Axios.get("/api/templatelink/readAllTemplate").then((response) => {
      setWhatsappLinkTemplates(response.data.Templates);
    });
    Axios.get("/api/templateImage/readAllTemplate").then((response) => {
      setWhatsappImageTemplates(response.data.Templates);
    });
    Axios.get("/api/templateDoc/readAllTemplate").then((response) => {
      setWhatsappDocumentTemplates(response.data.Templates);
    });
    Axios.get("/api/templatesms/readAllTemplate").then((response) => {
      setsmsTemplates(response.data.Templates);
    });
    Axios.get("/api/templatemail/readAllTemplate").then((response) => {
      setmailTemplates(response.data.Templates);
    });
  }, []);

  const [openMail, setOpenMail] = useState(false);
  const [openSms, setOpenSms] = useState(false);
  const [openWh, setOpenWh] = useState(false);

  const forwh = () => {
    openWh ? setOpenWh(false) : setOpenWh(true);
  };

  const formail = () => {
    openMail ? setOpenMail(false) : setOpenMail(true);
  };

  const forsms = () => {
    openSms ? setOpenSms(false) : setOpenSms(true);
  };

  const sendpromoformsg = (id) => {
    let arr = [viewId];

    Axios.post(`/api/lead/Sendwhatsapp/${id}`, {
      arr: arr,
    })
      .then(function (response) {
        message.success("Sent successfully");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const sendpromoforlink = (id) => {
    let arr = [viewId];

    Axios.post(`/api/lead/SendwhatsappLink/${id}`, {
      arr: arr,
    })
      .then(function (response) {
        message.success("Sent successfully");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const sendpromoforimg = (id) => {
    let arr = [viewId];

    Axios.post(`/api/lead/SendwhatsappImage/${id}`, {
      arr: arr,
    })
      .then(function (response) {
        message.success("Sent successfully");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const sendpromofordoc = (id) => {
    let arr = [viewId];

    Axios.post(`/api/lead/SendwhatsappDoc/${id}`, {
      arr: arr,
    })
      .then(function (response) {
        message.success("Sent successfully");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const sendpromoforsms = (id) => {
    let arr = [viewId];

    Axios.post(`/api/lead/Sendsms/${id}`, {
      arr: arr,
    })
      .then(function (response) {
        message.success("Sent successfully");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const sendpromoformail = (id) => {
    let arr = [viewId];

    Axios.post(`/api/lead/Sendmail/${id}`, {
      arr: arr,
    })
      .then(function (response) {
        message.success("Sent successfully");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Opportunity" />
      <div
        className="container-fluid1 pt-6"
        style={{
          backgroundColor: "#F0F2FA",
        }}
      >
        <div className="mt-2 mb-4 pr-3 pl-3">
          <div className="col-lg-13">
            <div
              className="table-full-3 viewopp"
              style={{ minHeight: "initial" }}
            >
              <div className="row" style={{ padding: "0px" }}>
                {/* <div className="col-3">
                <div className="list-group">
                  <a
                    className="text-decoration-none text-reset list-group-item list-group-item-action active"
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="nav-icon fa fa-info-circle"></i> Information
                  </a>
                  <Link to={'/log/' + params.id}>
                    <a
                      className="text-decoration-none text-reset list-group-item list-group-item-action "
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="nav-icon fa fa-phone"></i> Call Logs
                    </a>
                  </Link>

                  <Link to={'/note/' + params.id}>
                    <a
                      className="text-decoration-none text-reset list-group-item list-group-item-action "
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="nav-icon fa fa-sticky-note"></i> Note
                    </a>
                  </Link>
                </div>
              </div> */}

                <div className={`col-8 ${classes.mainDiv}`}>
                  <div className={`logDiv ${classes.logDiv}`}>
                    {/* <div className="text-right">
                        <button
                          type="button"
                          className="btn btn-primary btn-flat btn-sm"
                          data-toggle="modal"
                          data-target="#addModal"
                        >
                          Add new note
                        </button>
                      </div>
                      <hr /> */}

                    {loading && <Spin size="large" />}

                    <div
                      className={`list-group ${classes.listGroup}`}
                      id="lead-list"
                    >
                      <p className={classes.history}>
                        Log History :
                        {log.LogType && log.LogType.length === 0 && (
                          <span style={{ fontWeight: 300, fontSize: "0.8em" }}>
                            {" "}
                            N/A
                          </span>
                        )}
                      </p>
                      <div className={classes.logHistory}>
                        {log.LogType &&
                          log.LogType.map((val, idx) => {
                            let alter = idx % 2 === 0 ? "#fff" : "#E9F0FF";
                            return (
                              <div
                                className="list-group-item list-group-item-action list-item rounded-0"
                                key={idx}
                                style={{ backgroundColor: alter }}
                              >
                                <div className="row">
                                  <div className="col-12">
                                    <div className="col-auto text-muted pl-3">
                                      <p
                                        style={{
                                          fontFamily: "Prox",
                                        }}
                                      >
                                        Log Type:{" "}
                                        <b style={{ color: "blue" }}>
                                          {log.LogType[idx]}
                                        </b>
                                        <span
                                          style={{
                                            float: "right",
                                          }}
                                        >
                                          Created by {log.CreatedBy} at{" "}
                                          {log.InformationDate[idx] &&
                                            log.InformationDate[idx]
                                              .slice(0, 10)
                                              .split("-")
                                              .reverse()
                                              .join("/")}
                                        </span>
                                      </p>
                                      <p
                                        style={{
                                          wordBreak: " break-all",
                                          fontFamily: "Prox",
                                          width: "100%",
                                        }}
                                      >
                                        Remarks:{" "}
                                        <b style={{ color: "black" }}>
                                          {log.Remarks[idx]}
                                        </b>
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="text-right">
                                  <a
                                    data-toggle="modal"
                                    data-target="#editModal"
                                    onClick={() => setIndex(idx)}
                                  >
                                    <i className="fa fa-edit pl-2 pr-2 actionBtn"></i>
                                  </a>
                                  <a
                                    data-toggle="modal"
                                    data-target="#deleteModal"
                                    //   onClick={() => setID(val._id)}
                                    onClick={() => setIndex(idx)}
                                  >
                                    <i className="fa fa-trash pl-2 pr-2 actionBtn"></i>
                                  </a>
                                </div> */}
                              </div>
                            );
                          })}
                      </div>
                      <p className={classes.history}>
                        Course History :{" "}
                        {log.prevCourse && log.prevCourse.length === 0 && (
                          <span style={{ fontWeight: 300, fontSize: "0.8em" }}>
                            {" "}
                            N/A
                          </span>
                        )}
                      </p>
                      {log.prevCourse &&
                        log.prevCourse.map((val, idx) => {
                          let alter = idx % 2 === 0 ? "#fff" : "#E9F0FF";
                          return (
                            <div
                              className="list-group-item list-group-item-action list-item rounded-0"
                              key={idx}
                              style={{ backgroundColor: alter }}
                            >
                              <div className="row">
                                <div className="col-12">
                                  <div className="col-auto text-muted pl-3">
                                    <p
                                      style={{
                                        fontFamily: "Prox",
                                      }}
                                    >
                                      Previous Course:{" "}
                                      <b style={{ color: "blue" }}>
                                        {log.prevCourse[idx]}
                                      </b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      <p className={classes.history}>
                        Price History :{" "}
                        {log.prevPrice && log.prevPrice.length === 0 && (
                          <span style={{ fontWeight: 300, fontSize: "0.8em" }}>
                            {" "}
                            N/A
                          </span>
                        )}
                      </p>

                      {log.prevPrice &&
                        log.prevPrice.map((val, idx) => {
                          let alter = idx % 2 === 0 ? "#fff" : "#E9F0FF";
                          return (
                            <div
                              className="list-group-item list-group-item-action list-item rounded-0"
                              key={idx}
                              style={{ backgroundColor: alter }}
                            >
                              <div className="row">
                                <div className="col-12">
                                  <div className="col-auto text-muted pl-3">
                                    <p
                                      style={{
                                        fontFamily: "Prox",
                                      }}
                                    >
                                      Previous Price:{" "}
                                      <b style={{ color: "blue" }}>
                                        &#8377;{log.prevPrice[idx]}
                                      </b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      <p className={classes.history}>
                        Status History :{" "}
                        {log.prevStatus && log.prevStatus.length === 0 && (
                          <span style={{ fontWeight: 300, fontSize: "0.8em" }}>
                            {" "}
                            N/A
                          </span>
                        )}
                      </p>

                      {log.prevStatus &&
                        log.prevStatus.map((val, idx) => {
                          let alter = idx % 2 === 0 ? "#fff" : "#E9F0FF";
                          return (
                            <div
                              className="list-group-item list-group-item-action list-item rounded-0"
                              key={idx}
                              style={{ backgroundColor: alter }}
                            >
                              <div className="row">
                                <div className="col-12">
                                  <div className="col-auto text-muted pl-3">
                                    <p
                                      style={{
                                        fontFamily: "Prox",
                                      }}
                                    >
                                      Status:{" "}
                                      <b style={{ color: "blue" }}>
                                        {log.prevStatus[idx]}
                                      </b>
                                      <span
                                        style={{
                                          float: "right",
                                        }}
                                      >
                                        Created By {log.whoChangesState[idx]} at{" "}
                                        {log.prevStatusDate[idx] &&
                                          log.prevStatusDate[idx]
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      <p className={classes.history}>
                        Followup History :{" "}
                        {log.PrevFollowupDate &&
                          log.PrevFollowupDate.length === 0 && (
                            <span
                              style={{ fontWeight: 300, fontSize: "0.8em" }}
                            >
                              {" "}
                              N/A
                            </span>
                          )}
                      </p>

                      {log.PrevFollowupDate &&
                        log.PrevFollowupDate.map((val, idx) => {
                          let alter = idx % 2 === 0 ? "#fff" : "#E9F0FF";
                          return (
                            <div
                              className="list-group-item list-group-item-action list-item rounded-0"
                              key={idx}
                              style={{ backgroundColor: alter }}
                            >
                              <div className="row">
                                <div className="col-12">
                                  <div className="col-auto text-muted pl-3">
                                    <p
                                      style={{
                                        fontFamily: "Prox",
                                      }}
                                    >
                                      Previous Date:{" "}
                                      <b style={{ color: "blue" }}>
                                        {log.PrevFollowupDate[idx] &&
                                          log.PrevFollowupDate[idx]
                                            .slice(0, 10)
                                            .split("-")
                                            .reverse()
                                            .join("/")}
                                      </b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className={`addLog ${classes.addLog}`}>
                    <div style={{ minHeight: "90px" }}>
                      <Grid columns={4}>
                        <Grid.Column
                          style={{
                            padding: "4px 0",
                            margin: "4px 0",
                            fontWeight: "600",
                            fontSize: "15px",
                            width: "5rem",
                          }}
                        >
                          Log Type :{" "}
                        </Grid.Column>
                        <Grid.Column style={{ width: "190px", padding: 0 }}>
                          <FormControl>
                            {/* <InputLabel id="demo-simple-select-label">
                              Log Type
                            </InputLabel>
                            <Select
                              value={LogType}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Log Type"
                              required
                              onChange={(e) => setLogType(e.target.value)}
                              error={logError}
                            >
                              <MenuItem
                                value="Inbound"
                                style={{
                                  margin: '0px',
                                  padding: '0px',
                                }}
                              >
                                Inbound
                              </MenuItem>
                              <MenuItem
                                value="Outbound"
                                style={{
                                  margin: '0px',
                                  padding: '0px',
                                }}
                              >
                                Outbound
                              </MenuItem>
                            </Select> */}

                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={LogType}
                              onChange={(e) => setLogType(e.target.value)}
                            >
                              <FormControlLabel
                                style={{ margin: "4px" }}
                                value="Inbound"
                                control={<Radio style={{ padding: "4px" }} />}
                                label="Inbound"
                              />
                              <FormControlLabel
                                style={{ margin: "4px" }}
                                value="Outbound"
                                control={<Radio style={{ padding: "4px" }} />}
                                label="Outbound"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid.Column>
                        <Grid.Column style={{ width: "18rem", padding: 0 }}>
                          <TextareaAutosize
                            maxRows={5}
                            value={Remarks}
                            className="lead-fields"
                            minRows={5}
                            style={{
                              padding: "0.5rem",
                              borderRadius: "5px",
                              resize: "none",
                              width: "18rem",
                            }}
                            required
                            aria-label="maximum height"
                            placeholder="Remarks"
                            onChange={(e) => setRemarks(e.target.value)}
                            error={remarksError.toString()}
                          />
                        </Grid.Column>
                        <Grid.Column width={4} style={{ padding: 0 }}>
                          <button
                            onClick={addToNote}
                            className="btn btn-primary add-button"
                          >
                            {" "}
                            Add Log{" "}
                          </button>
                          {params.id ? (
                            <Link to="/">
                              <button className="btn btn-danger"> Back </button>
                            </Link>
                          ) : (
                            <button
                              onClick={() => props.hideView("closed")}
                              className="btn btn-danger"
                            >
                              {" "}
                              Back{" "}
                            </button>
                          )}
                        </Grid.Column>
                      </Grid>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  {loading && <Spin size="large" />}
                  <div className="row" style={{ padding: "0px" }}>
                    <div className="col-4 border">
                      <b>Name</b>
                    </div>
                    <div className="col-8 border">{opp.Name}</div>
                    <div className="col-4 border">
                      <b>City</b>
                    </div>
                    <div className="col-8 border">{opp.City} </div>
                    <div className="col-4 border">
                      <b>Phone</b>
                    </div>
                    <div className="col-8 border">{opp.Phone1} </div>
                    <div className="col-4 border">
                      <b>Phone2</b>
                    </div>
                    <div className="col-8 border">
                      {opp.Phone2 == 0 ? (
                        <div
                          className="col "
                          style={{ color: "blue", padding: "0px" }}
                        >
                          <a data-toggle="modal" data-target="#phone2Modal">
                            Add Phone2
                          </a>
                        </div>
                      ) : (
                        opp.Phone2
                      )}{" "}
                    </div>
                    <div className="col-4 border">
                      <b>Email</b>
                    </div>
                    <div className="col-8 border">
                      {opp.Email == "" ? (
                        <div
                          className="col "
                          style={{ color: "blue", padding: "0px" }}
                        >
                          <a data-toggle="modal" data-target="#emailModal">
                            Add Email
                          </a>
                        </div>
                      ) : (
                        opp.Email
                      )}{" "}
                    </div>
                    <div className="col-4 border">
                      <b>Assigned </b>
                    </div>
                    <div className="col-8 border">{opp.AssignTo}</div>
                    <div className="col-4 border">
                      <b>Source</b>
                    </div>
                    <div className="col-8 border">{opp.Source}</div>
                    <div className="col-4 border">
                      <b>Course</b>
                    </div>
                    <div className="col-8 border">
                      <div className="row">
                        <div className="col" style={{ padding: "0px" }}>
                          <span className="badge badge-primary bg-gradient-primary px-3 rounded-pill">
                            {opp.Course}
                          </span>{" "}
                        </div>
                        <div
                          className="col "
                          style={{ color: "blue", padding: "0px" }}
                        >
                          <a data-toggle="modal" data-target="#courseModal">
                            Update Course
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 border">
                      <b> Price</b>
                    </div>
                    <div className="col-8 border">
                      <div className="row">
                        <div className="col" style={{ padding: "0px" }}>
                          <span className="badge badge-primary bg-gradient-primary px-3 rounded-pill">
                            {opp.CoursePrice &&
                              opp.CoursePrice.toLocaleString()}
                          </span>{" "}
                        </div>
                        <div
                          className="col "
                          style={{ color: "blue", padding: "0px" }}
                        >
                          <a data-toggle="modal" data-target="#priceModal">
                            Update Price
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 border">
                      <b>Address</b>
                    </div>
                    <div className="col-8 border">{opp.Address}</div>
                    <div className="col-4 border">
                      <b>Status</b>
                    </div>
                    <div className="col-8 border">
                      <div className="row">
                        <div className="col" style={{ padding: "0px" }}>
                          <span className="badge badge-primary bg-gradient-primary px-3 rounded-pill">
                            {opp.Status}
                          </span>{" "}
                        </div>
                        <div
                          className="col "
                          style={{ color: "blue", padding: "0px" }}
                        >
                          <a data-toggle="modal" data-target="#updateModal">
                            Update Status
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-4 border">
                      <b>All Status</b>
                    </div>
                    <div className="col-8 border">
                      <div style={{ color: 'blue', padding: '0px' }}>
                        <a data-toggle="modal" data-target="#statusModal">
                          See here
                        </a>
                      </div>
                    </div> */}
                    <div className="col-4 border">
                      <b>Remarks</b>
                    </div>
                    <div className="col-8 border" style={{ color: "red" }}>
                      {opp.Remark}{" "}
                    </div>
                    <div className="col-4 border">
                      <b>Created By</b>
                    </div>
                    <div className="col-8 border">{opp.CreatedBy}</div>
                    <div className="col-4 border">
                      <b>Enquiry Date</b>
                    </div>
                    <div className="col-8 border">
                      {opp.EnquiryDate &&
                        opp.EnquiryDate.slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("/")}
                    </div>
                    <div className="col-4 border">
                      <b>Followup </b>
                    </div>
                    <div className="col-8 border">
                      <div className="row">
                        <div className="col" style={{ padding: "0px" }}>
                          <span className="badge badge-primary bg-gradient-primary px-3 rounded-pill">
                            {opp.FollowupDate &&
                              opp.FollowupDate.slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("/")}
                          </span>{" "}
                        </div>
                        <div
                          className="col"
                          style={{ color: "blue", padding: "0px" }}
                        >
                          {/* eslint-disable-next-line */}
                          <a data-toggle="modal" data-target="#followupModal">
                            Update Date
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-4 border">
                      <b>All Followup</b>
                    </div>
                    <div className="col-8 border">
                      <div style={{ color: 'blue', padding: '0px' }}>
                        <a data-toggle="modal" data-target="#followModal">
                          See here
                        </a>
                      </div>
                    </div> */}
                    <div className="col-4 border">
                      <b>Attachment</b>
                    </div>
                    {opp.Attachments === "" ? (
                      <div className="col-8 border">
                        <a style={{ cursor: "default" }}>No Attachments</a>
                      </div>
                    ) : (
                      <div className="col-8 border">
                        {/* eslint-disable-next-line */}
                        <a
                          href={opp.Attachments}
                          target="_blank"
                          style={{ color: "blue" }}
                        >
                          Click Here
                        </a>
                      </div>
                    )}
                    <div className="col-4 border">
                      <b>Promotion</b>
                    </div>
                    <div className="col-8 border">
                      <div>
                        <IconContext.Provider
                          value={{
                            color: "green",
                            size: "20px",
                            cursor: "pointer",
                          }}
                        >
                          <span style={{ cursor: "pointer" }} onClick={forwh}>
                            <RiWhatsappFill />
                          </span>
                        </IconContext.Provider>

                        <IconContext.Provider
                          value={{ color: "#E12929", size: "20px" }}
                        >
                          <a onClick={formail}>
                            <SiGmail
                              style={{
                                margin: "0px 10px",
                              }}
                            />
                          </a>
                        </IconContext.Provider>

                        {/* <IconContext.Provider
                          value={{
                            color: "blue",
                            size: "20px",
                            cursor: "pointer",
                          }}
                        >
                          <a onClick={forsms}>
                            <FaSms />
                          </a>
                        </IconContext.Provider> */}
                      </div>
                      {/* for whatsapp */}
                      {openWh && (
                        <div
                          style={{
                            minHeight: "100vh",
                            minWidth: "100vw",
                            zIndex: 99,
                            float: "top",
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backdropFilter: "blur(4px)",
                            backgroundColor: "rgb(0 0 0 / 58%)",
                          }}
                        >
                          <WhatsappModal
                            forwh={forwh}
                            sendpromoforimg={sendpromoforimg}
                            sendpromoformsg={sendpromoformsg}
                            sendpromoforlink={sendpromoforlink}
                            sendpromofordoc={sendpromofordoc}
                            whatsappMessageTemplates={whatsappMessageTemplates}
                            whatsappLinkTemplates={whatsappLinkTemplates}
                            whatsappImageTemplates={whatsappImageTemplates}
                            whatsappDocumentTemplates={
                              whatsappDocumentTemplates
                            }
                          />
                        </div>
                      )}

                      {/* for sms */}

                      {openSms && (
                        <div
                          style={{
                            minHeight: "100vh",
                            minWidth: "100vw",
                            zIndex: 99,
                            float: "top",
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backdropFilter: "blur(4px)",
                            backgroundColor: "rgb(0 0 0 / 58%)",
                          }}
                        >
                          <SMSModal
                            sendpromoforsms={sendpromoforsms}
                            smsTemplates={smsTemplates}
                            forsms={forsms}
                          />
                        </div>
                      )}

                      {/* for Email */}
                      {openMail && (
                        <div
                          style={{
                            minHeight: "100vh",
                            minWidth: "100vw",
                            zIndex: 99,
                            float: "top",
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            backdropFilter: "blur(4px)",
                            backgroundColor: "rgb(0 0 0 / 58%)",
                          }}
                        >
                          <EmailModal
                            sendpromoformail={sendpromoformail}
                            mailTemplates={mailTemplates}
                            formail={formail}
                          />
                        </div>
                      )}
                      {/* Promotion End */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Price Modal */}

      <div
        className="modal fade show"
        id="phone2Modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Phone2
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form method="post" id="username-verification">
                <div className="form-group">
                  <TextField
                    className="lead-fields"
                    fullWidth
                    type="number"
                    id="outlined-required"
                    label="Enter Phone no 2"
                    onChange={(e) => {
                      setMobile2(e.target.value);
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-flat"
                id="submit"
                onClick={addPhone2}
                data-dismiss="modal"
              >
                Add
              </button>
              <button
                type="button"
                className="btn  border btn-flat"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Email Modal */}

      <div
        className="modal fade show"
        id="emailModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Email
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form method="post" id="username-verification">
                <div className="form-group">
                  <TextField
                    className="lead-fields"
                    fullWidth
                    id="outlined-required"
                    label="Enter Email id"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    error={emailError}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-flat"
                id="submit"
                onClick={addEmail}
                data-dismiss="modal"
              >
                Add
              </button>
              <button
                type="button"
                className="btn  border btn-flat"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}

      <div
        className="modal fade show"
        id="updateModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Update Status
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form method="post" id="username-verification">
                <div className="form-group">
                  <select
                    value={status || opp.Status}
                    name="log_type"
                    id="log_type"
                    className="form-control form-control-sm form-control-border"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value=""></option>
                    {statuses &&
                      statuses.map((item, index) => {
                        return (
                          <option key={index} value={item.StatusName}>
                            {item.StatusName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-flat"
                id="submit"
                onClick={addToList}
                data-dismiss="modal"
              >
                Update
              </button>
              <button
                type="button"
                className="btn  border btn-flat"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* followup Modal */}

      <div
        className="modal fade show"
        id="followupModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Update Followup
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form method="post" id="username-verification">
                <div className="form-group">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    className="lead-fields"
                  >
                    <DatePicker
                      label="Followup Date"
                      inputFormat="dd/MM/yyyy"
                      value={followupDate || opp.FollowupDate}
                      onChange={(newValue) => {
                        setFollowupDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="lead-fields"
                          required
                          fullWidth
                          {...params}
                          error={followupDateError}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                id="closeModalbtn"
                type="button"
                className="btn  border btn-flat"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*status history Modal */}

      <div
        className="modal fade show"
        id="statusModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Status History:
              </h5>
            </div>
            <div className="modal-body">
              {log.prevStatus &&
                log.prevStatus.map((val, idx) => {
                  let alter = idx % 2 === 0 ? "#fff" : "#E9F0FF";
                  return (
                    <div
                      className="list-group-item list-group-item-action list-item rounded-0"
                      key={idx}
                      style={{ backgroundColor: alter }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="col-auto text-muted pl-3">
                            <p
                              style={{
                                fontFamily: "Prox",
                              }}
                            >
                              Status: <b>{log.prevStatus[idx]}</b>
                              <span
                                style={{
                                  float: "right",
                                }}
                              >
                                Date:
                                {log.prevStatusDate[idx] &&
                                  log.prevStatusDate[idx]
                                    .slice(0, 10)
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                              </span>
                            </p>
                            <p
                              style={{
                                wordBreak: " break-all",
                                fontFamily: "Prox",
                                width: "100%",
                              }}
                            >
                              Changed by: <b>{log.whoChangesState[idx]}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn  border btn-flat"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}

      <div
        className="modal fade show"
        id="followModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Followup Date History:
              </h5>
            </div>
            <div className="modal-body">
              {log.PrevFollowupDate &&
                log.PrevFollowupDate.map((val, idx) => {
                  let alter = idx % 2 === 0 ? "#fff" : "#E9F0FF";
                  return (
                    <div
                      className="list-group-item list-group-item-action list-item rounded-0"
                      key={idx}
                      style={{ backgroundColor: alter }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="col-auto text-muted pl-3">
                            <p
                              style={{
                                fontFamily: "Prox",
                              }}
                            >
                              Previous Date:{" "}
                              <b>
                                {log.PrevFollowupDate[idx] &&
                                  log.PrevFollowupDate[idx]
                                    .slice(0, 10)
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                              </b>
                              {/* <em
                                style={{
                                  float: 'right',
                                }}
                              >
                                Date:
                                {Moment(log.prevStatusDate[idx]).format(
                                  'DD MMMM YY hh:mm:ss'
                                )}
                              </em> */}
                            </p>
                            {/* <p
                              style={{
                                wordBreak: ' break-all',
                                fontFamily: 'Prox',
                                width: '100%',
                              }}
                            >
                              Changed by: <b>{log.whoChangesState[idx]}</b>
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn  border btn-flat"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Course Modal */}

      <div
        className="modal fade show"
        id="courseModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Update Course
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form method="post" id="username-verification">
                <div className="form-group">
                  <select
                    value={course || opp.Course}
                    name="log_type"
                    id="log_type"
                    className="form-control form-control-sm form-control-border"
                    onChange={(e) => setCourse(e.target.value)}
                  >
                    <option value=""></option>
                    {courseDetail &&
                      courseDetail.map((item, index) => {
                        return (
                          <option key={index} value={item.CourseName}>
                            {item.CourseName}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-flat"
                id="submit"
                onClick={addCourse}
                data-dismiss="modal"
              >
                Update
              </button>
              <button
                type="button"
                className="btn  border btn-flat"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*price Modal */}

      <div
        className="modal fade show"
        id="priceModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Update Price
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form method="post" id="username-verification">
                <div className="form-group">
                  <TextField
                    className="lead-fields"
                    value={price || opp.CoursePrice}
                    required
                    fullWidth
                    type="number"
                    id="outlined-required"
                    label="Course Price"
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    error={priceError}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary btn-flat"
                id="submit"
                onClick={addPrice}
                data-dismiss="modal"
              >
                Update
              </button>
              <button
                type="button"
                className="btn  border btn-flat"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewOpp;
