import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
import TextField from "@mui/material/TextField";
// popup
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";

import { Spin, message } from "antd";
import Moment from "moment";
import InputAdornment from "@mui/material/InputAdornment";
import { AiOutlineSearch, AiOutlineFolderOpen } from "react-icons/ai";
import {
  BsArrowDown,
  BsArrowUp,
  BsFillArrowRightSquareFill,
  BsFillArrowLeftSquareFill,
} from "react-icons/bs";

import { TbFilterOff } from "react-icons/tb";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// import { DateRangePicker } from "react-date-range";
import { addYears } from "date-fns";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import classes from "./OppComponent.module.css";

import { HiOutlineUserGroup } from "react-icons/hi";

import { HiFire } from "react-icons/hi";
import { IconContext } from "react-icons";
import { MdOpenInNew } from "react-icons/md";
import { FaSellsy } from "react-icons/fa";

import { DateRangePicker } from "rsuite";
import "./rsuite.css";
import ViewOpp from "../ViewOpp";
import Navbar from "../Navbar";

import { SiGmail } from "react-icons/si";
import { useChecklist } from "react-checklist";

import { useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import WhatsappModal from "./Promotional/WhatsappModal";
import SMSModal from "./Promotional/SMSModal";
import EmailModal from "./Promotional/EmailModal";
import LeadPeek from "./LeadPeek";
import { Button } from "semantic-ui-react";
import { RiWhatsappFill } from "react-icons/ri";

export default function OppComponent() {
  Axios.defaults.withCredentials = true;
  const user = useSelector((states) => states.auth);

  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [leadOwner, setLeadOwner] = useState([]);
  const [courseDetail, setCourseDetail] = useState([]);
  const [sourceDetail, setSourceDetail] = useState([]);
  const [branchDetail, setBranchDetail] = useState([]);
  const [cities, setCities] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [range1, setRange1] = useState(
    localStorage.getItem("range1Filter")
      ? [
          {
            startDate: new Date(
              localStorage.getItem("range1Filter").split(",")[0]
            ),
            endDate: new Date(
              localStorage.getItem("range1Filter").split(",")[1]
            ),
            key: "selection",
          },
        ]
      : [
          {
            startDate: addYears(new Date(), -10),
            endDate: addYears(new Date(), 10),
            key: "selection",
          },
        ]
  );

  const [range2, setRange2] = useState(
    localStorage.getItem("range2Filter")
      ? [
          {
            startDate: new Date(
              localStorage.getItem("range2Filter").split(",")[0]
            ),
            endDate: new Date(
              localStorage.getItem("range2Filter").split(",")[1]
            ),
            key: "selection",
          },
        ]
      : [
          {
            startDate: addYears(new Date(), -10),
            endDate: addYears(new Date(), 10),
            key: "selection",
          },
        ]
  );

  // const [from1, setfrom1] = useState("1922-01-01");
  // const [to1, setto1] = useState("2042-12-31");
  // const [from2, setfrom2] = useState("1922-01-01");
  // const [to2, setto2] = useState("2042-12-31");

  const [page, setpage] = useState(1);
  const [resultPerPage, setresultPerPage] = useState(100);

  const [City, setCity] = useState(
    localStorage.getItem("cityFilter")
      ? JSON.parse(localStorage.getItem("cityFilter"))
      : []
  );
  const [Source, setSource] = useState(
    localStorage.getItem("sourceFilter")
      ? JSON.parse(localStorage.getItem("sourceFilter"))
      : []
  );
  const [Status, setStatus] = useState(
    localStorage.getItem("statusFilter")
      ? JSON.parse(localStorage.getItem("statusFilter"))
      : ["Open", "Final Lead", "Hot Lead"]
  );
  const [Branch, setBranch] = useState(
    localStorage.getItem("branchFilter")
      ? JSON.parse(localStorage.getItem("branchFilter"))
      : []
  );
  const [Course, setCourse] = useState(
    localStorage.getItem("courseFilter")
      ? JSON.parse(localStorage.getItem("courseFilter"))
      : []
  );
  const [Owner, setOwner] = useState(
    localStorage.getItem("assignedFilter")
      ? JSON.parse(localStorage.getItem("assignedFilter"))
      : []
  );
  const [keyword, setKeyword] = useState(
    localStorage.getItem("searchKeyword")
      ? localStorage.getItem("searchKeyword")
      : ""
  );
  const [order, setOrder] = useState("ASC");
  const [oorder, setOOrder] = useState("ASC");
  const [sorder, setSOrder] = useState("ASC");
  const [norder, setNOrder] = useState("ASC");
  const [m1order, setM1Order] = useState("ASC");
  const [m2order, setM2Order] = useState("ASC");
  const [eorder, setEOrder] = useState("ASC");
  const [corder, setCOrder] = useState("ASC");
  const [porder, setPOrder] = useState("ASC");
  const [dorder, setDOrder] = useState("ASC");
  const [ciorder, setCiOrder] = useState("ASC");
  const [forder, setFOrder] = useState("ASC");
  const [aorder, setAOrder] = useState("ASC");
  const [lforder, setLForder] = useState("ASC");
  const [enqDorder, setEnqDorder] = useState("ASC");

  const [leadData, setLeadData] = useState([]);

  // storing all the permission of the user for assign to filter
  const [permissions, setPermissions] = useState([]);

  // storing all the access branches of the user branch
  const [branchPermissions, setBranchPermissions] = useState([]);

  const clearFilter = () => {
    localStorage.removeItem("courseFilter");
    localStorage.removeItem("cityFilter");
    localStorage.removeItem("sourceFilter");
    localStorage.removeItem("branchFilter");
    localStorage.removeItem("statusFilter");
    localStorage.removeItem("searchKeyword");
    localStorage.removeItem("range1Filter");
    localStorage.removeItem("range2Filter");
    const statusFilter = ["Open", "Final Lead", "Hot Lead"];
    setStatus(statusFilter);
    localStorage.removeItem("assignedFilter");
    setCity([]);
    setSource([]);
    setBranch([]);
    setCourse([]);
    setOwner([]);
    setKeyword("");
    setRange1([
      {
        startDate: addYears(new Date(), -10),
        endDate: addYears(new Date(), 10),
        key: "selection",
      },
    ]);
    setRange2([
      {
        startDate: addYears(new Date(), -10),
        endDate: addYears(new Date(), 10),
        key: "selection",
      },
    ]);
    setRange1Filter([]);
    setRange2Filter([]);
  };

  useEffect(() => {
    if (performance.navigation.type === 1) {
      clearFilter();
    }
  }, []);

  const [range1Filter, setRange1Filter] = useState(
    localStorage.getItem("range1Filter")
      ? [
          new Date(localStorage.getItem("range1Filter").split(",")[0]),
          new Date(localStorage.getItem("range1Filter").split(",")[1]),
        ]
      : []
  );
  const [range2Filter, setRange2Filter] = useState(
    localStorage.getItem("range2Filter")
      ? [
          new Date(localStorage.getItem("range2Filter").split(",")[0]),
          new Date(localStorage.getItem("range2Filter").split(",")[1]),
        ]
      : []
  );

  const sorting = (col) => {
    console.log(col);
    if (order === "ASC") {
      const sorted = [...leads].sort((a, b) => {
        let value;

        if (col === "Days" || col === "Days2" || col === "CoursePrice") {
          value = a[col] > b[col] ? 1 : -1;
        } else if (col === "LastFollowup") {
          value =
            Moment(a[col]).format("DD/MM/YY").toString() >
            Moment(b[col]).format("DD/MM/YY").toString()
              ? 1
              : -1;
        } else {
          value =
            a[col].toString().toLowerCase() > b[col].toString().toLowerCase()
              ? 1
              : -1;
        }

        return value;
      });

      if (col === "Days2") setLForder("DSC");
      if (col === "enqDate") setEnqDorder("DSC");
      if (col === "CreatedBy") setOOrder("DSC");
      if (col === "Source") setSOrder("DSC");
      if (col === "Name") setNOrder("DSC");
      if (col === "Phone1") setM1Order("DSC");
      if (col === "Phone2") setM2Order("DSC");
      if (col === "Status") setEOrder("DSC");
      if (col === "Course") setCOrder("DSC");
      if (col === "Price") setPOrder("DSC");
      if (col === "Days") setDOrder("DSC");
      if (col === "City") setCiOrder("DSC");
      if (col === "FollowupDate") setFOrder("DSC");
      if (col === "AssignedTo") setAOrder("DSC");

      setLeads(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...leads].sort((a, b) => {
        let value;
        if (col === "Days" || col === "Days2" || col === "CoursePrice") {
          value = a[col] < b[col] ? 1 : -1;
        } else {
          value =
            a[col].toString().toLowerCase() < b[col].toString().toLowerCase()
              ? 1
              : -1;
        }

        return value;
      });

      if (col === "Days2") setLForder("ASC");
      if (col === "enqDate") setEnqDorder("ASC");
      if (col === "CreatedBy") setOOrder("ASC");
      if (col === "Source") setSOrder("ASC");
      if (col === "Name") setNOrder("ASC");
      if (col === "Phone1") setM1Order("ASC");
      if (col === "Phone2") setM2Order("ASC");
      if (col === "Status") setEOrder("ASC");
      if (col === "Course") setCOrder("ASC");
      if (col === "Price") setPOrder("ASC");
      if (col === "Days") setDOrder("ASC");
      if (col === "City") setCiOrder("ASC");
      if (col === "FollowupDate") setFOrder("ASC");
      if (col === "AssignedTo") setAOrder("ASC");
      setLeads(sorted);
      setOrder("ASC");
    }
  };

  const link1 = `/api/lead/filterForAdmin?keyword=${keyword}&City=${City}&Source=${Source}&Course=${Course}&Status=${Status}&Branch=${Branch}&AssignTo=${Owner}&page=${page}&resultPerPage=${resultPerPage}&FollowupDate[gte]=${Moment(
    range1[0].startDate
  ).format("YYYY-MM-DD")}T00:00:00.000Z&FollowupDate[lte]=${Moment(
    range1[0].endDate
  ).format("YYYY-MM-DD")}T23:59:59.999Z&EnquiryDate[gte]=${Moment(
    range2[0].startDate
  ).format("YYYY-MM-DD")}T00:00:00.000Z&EnquiryDate[lte]=${Moment(
    range2[0].endDate
  ).format("YYYY-MM-DD")}T23:59:59.999Z`;

  const link2 = `/api/lead/filterForUser/${
    user.userId
  }?keyword=${keyword}&City=${City}&Source=${Source}&Course=${Course}&Status=${Status}&Branch=${Branch}&AssignTo=${Owner}&page=${page}&resultPerPage=${resultPerPage}&FollowupDate[gte]=${Moment(
    range1[0].startDate
  ).format("YYYY-MM-DD")}T00:00:00.000Z&FollowupDate[lte]=${Moment(
    range1[0].endDate
  ).format("YYYY-MM-DD")}T23:59:59.999Z&EnquiryDate[gte]=${Moment(
    range2[0].startDate
  ).format("YYYY-MM-DD")}T00:00:00.000Z&EnquiryDate[lte]=${Moment(
    range2[0].endDate
  ).format("YYYY-MM-DD")}T23:59:59.999Z`;

  let ID = 0;

  const setID = (id) => {
    ID = id;
  };

  let link = user.role === "admin" ? link1 : link2;

  useEffect(() => {
    setLoading(true);
    Axios.get(link).then((response) => {
      setLeads(response.data.lead);
      setLeadData(response.data);
      // console.log(response.data);
      setLoading(false);
    });

    Axios.get("/api/status/readAllStatus").then((response) => {
      setStatuses(response.data.statuss);
    });

    Axios.get("/api/course/readAllCourse").then((response) => {
      setCourseDetail(response.data.courses);
    });
    Axios.get("/api/source/readAllSource").then((response) => {
      setSourceDetail(response.data.sources);
    });
    Axios.get("/api/branch/readAllBranch").then((response) => {
      setBranchDetail(response.data.branchs);
    });

    Axios.get("/api/city/readAllCity").then((response) => {
      setCities(response.data.citys);
    });

    Axios.get("/api/user/readAllTeamMember").then((response) => {
      setLeadOwner(response.data.teams);
    });

    Axios.get("/api/user/readTeamMember/" + user.userId).then((response) => {
      setPermissions(response.data.team.Permission);
      setBranchPermissions(response.data.team.Branch);
    });
  }, [link, user.userId]);

  const deleteLead = (id) => {
    Axios.delete(`/api/lead/deleteLead/${id}`)
      .then(function (response) {
        message.success("Deleted successfully");
        Axios.get(`${link}`).then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setLeads(response.data.lead);
          setresultPerPage(100);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  function prevpage() {
    if (page > 1) {
      setpage(page - 1);
    }
  }

  function nextpage() {
    if (page * resultPerPage < leadData.filteredCount) {
      setpage(page + 1);
    }
  }

  const handleChangeForCity = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (City.length !== cities.length) {
        let arr = [];
        cities.forEach((cityObj) => {
          arr.push(cityObj.CityName);
        });
        setCity(arr);
        localStorage.setItem("cityFilter", JSON.stringify(arr));
        return;
      } else {
        setCity([]);
        localStorage.setItem("cityFilter", JSON.stringify([]));
        return;
      }
    }
    setCity(value);
    localStorage.setItem("cityFilter", JSON.stringify(value));
  };

  const handleChangeForSource = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (Source.length !== sourceDetail.length) {
        let arr = [];
        sourceDetail.forEach((sourceObj) => {
          arr.push(sourceObj.SourceName);
        });
        setSource(arr);
        localStorage.setItem("cityFilter", JSON.stringify(arr));
        return;
      } else {
        setSource([]);
        localStorage.setItem("sourceFilter", JSON.stringify(value));
        return;
      }
    }
    setSource(value);
    localStorage.setItem("sourceFilter", JSON.stringify(value));
  };

  const handleChangeForStatus = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (Status.length !== statuses.length) {
        let arr = [];
        statuses.forEach((statusObj) => {
          arr.push(statusObj.StatusName);
        });
        setStatus(arr);
        localStorage.setItem("statusFilter", JSON.stringify(arr));
        return;
      } else {
        setStatus([]);
        localStorage.setItem("statusFilter", JSON.stringify([]));
        return;
      }
    }
    setStatus(value);
    localStorage.setItem("statusFilter", JSON.stringify(value));
  };
  const handleChangeForBranch = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (Branch.length !== branchDetail.length) {
        let arr = [];
        branchDetail.forEach((branchObj) => {
          arr.push(branchObj.BranchName);
        });
        setBranch(arr);
        localStorage.setItem("branchFilter", JSON.stringify(arr));
        return;
      } else {
        setBranch([]);
        localStorage.setItem("branchFilter", JSON.stringify([]));
        return;
      }
    }
    setBranch(value);
    localStorage.setItem("branchFilter", JSON.stringify(value));
  };
  const handleChangeForBranchToUser = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (Branch.length !== branchPermissions.length) {
        let arr = [];
        branchPermissions.forEach((branch) => {
          arr.push(branch);
        });
        setBranch(arr);
        localStorage.setItem("branchFilter", JSON.stringify(arr));
        return;
      } else {
        setBranch([]);
        localStorage.setItem("branchFilter", JSON.stringify([]));
        return;
      }
    }
    setBranch(value);
    localStorage.setItem("branchFilter", JSON.stringify(value));
  };

  const handleChangeForCourse = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (Course.length !== courseDetail.length) {
        let arr = [];
        courseDetail.forEach((courseObj) => {
          arr.push(courseObj.CourseName);
        });
        setCourse(arr);
        localStorage.setItem("courseFilter", JSON.stringify(arr));
        return;
      } else {
        setCourse([]);
        localStorage.setItem("courseFilter", JSON.stringify([]));
        return;
      }
    }
    setCourse(value);
    localStorage.setItem("courseFilter", JSON.stringify(value));
  };

  const handleChangeForOwner = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      if (Owner.length !== leadOwner.length) {
        let arr = [];
        leadOwner.forEach((ownerObj) => {
          arr.push(ownerObj.UserName);
        });
        setOwner(arr);
        localStorage.setItem("assignedFilter", JSON.stringify(arr));
        return;
      } else {
        setOwner([]);
        localStorage.setItem("assignedFilter", JSON.stringify([]));
        return;
      }
    }
    setOwner(value);
    localStorage.setItem("assignedFilter", JSON.stringify(value));
  };

  const [isView, setView] = useState(false);
  const [viewId, setViewId] = useState();

  const hideViewPage = (value) => {
    setView(false);
    setViewId();
  };

  // start promotion
  const { handleCheck, isCheckedAll, checkedItems } = useChecklist(leads, {
    key: "_id",
    keyType: "string",
  });

  const [whatsappMessageTemplates, setWhatsappMessageTemplates] = useState();
  // const [whatsappLinkTemplates, setWhatsappLinkTemplates] = useState();
  const [whatsappImageTemplates, setWhatsappImageTemplates] = useState();
  const [whatsappDocumentTemplates, setWhatsappDocumentTemplates] = useState();
  const [smsTemplates, setsmsTemplates] = useState();
  const [mailTemplates, setmailTemplates] = useState();

  useEffect(() => {
    Axios.get("/api/template/readAllTemplate").then((response) => {
      setWhatsappMessageTemplates(response.data.Templates);
    });
    // Axios.get("/api/templatelink/readAllTemplate").then((response) => {
    //   setWhatsappLinkTemplates(response.data.Templates);
    // });
    Axios.get("/api/templateImage/readAllTemplate").then((response) => {
      setWhatsappImageTemplates(response.data.Templates);
    });
    Axios.get("/api/templateDoc/readAllTemplate").then((response) => {
      setWhatsappDocumentTemplates(response.data.Templates);
    });
    Axios.get("/api/templatesms/readAllTemplate").then((response) => {
      setsmsTemplates(response.data.Templates);
    });
    Axios.get("/api/templatemail/readAllTemplate").then((response) => {
      setmailTemplates(response.data.Templates);
    });
  }, []);

  const [openMail, setOpenMail] = useState(false);
  const [openSms, setOpenSms] = useState(false);
  const [openWh, setOpenWh] = useState(false);

  const forwh = () => {
    openWh ? setOpenWh(false) : setOpenWh(true);
  };

  const formail = () => {
    openMail ? setOpenMail(false) : setOpenMail(true);
  };

  const forsms = () => {
    openSms ? setOpenSms(false) : setOpenSms(true);
  };

  const sendpromoformsg = (id) => {
    let arr = [...checkedItems];

    Axios.post(`/api/lead/Sendwhatsapp/${id}`, {
      arr: arr,
    })
      .then(function (response) {
        message.success("Sent successfully");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  // const sendpromoforlink = (id) => {
  //   let arr = [...checkedItems];

  //   Axios.post(`/api/lead/SendwhatsappLink/${id}`, {
  //     arr: arr,
  //   })
  //     .then(function (response) {
  //       message.success("Sent successfully");
  //     })
  //     .catch(function (error) {
  //       message.error(`${error.response.data.message}`);
  //     });
  // };

  const sendpromoforimg = (id) => {
    let arr = [...checkedItems];
    setLoading(true);
    Axios.post(`/api/lead/SendwhatsappImage/${id}`, {
      arr: arr,
    })
      .then(function (response) {
        message.success("Sent successfully");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendpromofordoc = (id) => {
    let arr = [...checkedItems];
    setLoading(true);

    Axios.post(`/api/lead/SendwhatsappDoc/${id}`, {
      arr: arr,
    })
      .then(function (response) {
        message.success("Sent successfully");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendpromoforsms = (id) => {
    let arr = [...checkedItems];
    setLoading(true);
    Axios.post(`/api/lead/Sendsms/${id}`, {
      arr: arr,
    })
      .then(function (response) {
        message.success("Sent successfully");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendpromoformail = (id) => {
    let arr = [...checkedItems];
    setLoading(true);
    Axios.post(`/api/lead/Sendmail/${id}`, {
      arr: arr,
    })
      .then(function (response) {
        message.success("Sent successfully");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    clearFilter();
    setStatus([]);
    setKeyword(e.target.value);
    localStorage.setItem("searchKeyword", e.target.value);
  };

  return isView === false ? (
    <>
      <Navbar title="Enquiry Management System" />
      <div
        className="sticky-dash row row-cols-12 pl-1 d-flex justify-content-between"
        style={{ marginBottom: "20px" }}
      >
        <div className="dash col">
          <div
            className="edu_cat_2 cat-1"
            style={{
              backgroundImage:
                "linear-gradient(to right,  #E5E5E5, #6b74d6, #2d3de3)",
            }}
          >
            <div className="edu_cat_icons2">
              <HiOutlineUserGroup size={28} />
            </div>
            <div className="edu_cat_data">
              <h4 className="title">
                <p>
                  Total Leads
                  <strong className="pl-2">{leadData.totalLeads}</strong>
                </p>
              </h4>
              <div className="">
                <b style={{ fontSize: "17px" }}>
                  &#8377;
                  {leadData.totalPrice && leadData.totalPrice.toLocaleString()}
                </b>
              </div>
            </div>
          </div>
        </div>

        <div className="dash col">
          <div
            className="edu_cat_2 cat-1"
            style={{
              backgroundImage:
                "linear-gradient(to right, #E5E5E5, #dee66e, #e5e825)",
            }}
          >
            <div className="edu_cat_icons2">
              <AiOutlineFolderOpen size={28} />
            </div>
            <div className="edu_cat_data">
              <h4 className="title">
                <p>
                  Open Leads
                  <strong className="pl-2">{leadData.openLeads}</strong>
                </p>
              </h4>
              <div className="">
                <b style={{ fontSize: "17px" }}>
                  &#8377;
                  {leadData.openPrice && leadData.openPrice.toLocaleString()}
                </b>
              </div>
            </div>
          </div>
        </div>

        <div className="dash col">
          <div
            className="edu_cat_2 cat-1"
            style={{
              backgroundImage:
                "linear-gradient(to right, #E5E5E5, #7ce670, #22e325)",
            }}
          >
            <div className="edu_cat_icons2">
              <FaSellsy size={28} />
            </div>
            <div className="edu_cat_data">
              <h4 className="title">
                <p>
                  Final Leads
                  <strong className="pl-2">{leadData.finalLeads}</strong>
                </p>
              </h4>
              <div className="">
                <b style={{ fontSize: "17px" }}>
                  &#8377;
                  {leadData.finalPrice && leadData.finalPrice.toLocaleString()}
                </b>
              </div>
            </div>
          </div>
        </div>

        <div className="dash col">
          <div
            className="edu_cat_2 cat-1"
            style={{
              backgroundImage:
                "linear-gradient(to right, #E5E5E5, #a265db,  #9127f5)",
            }}
          >
            <IconContext.Provider
              value={{ color: "orange", className: "global-class-name" }}
            >
              <div className="edu_cat_icons2">
                <HiFire size={28} />
              </div>
            </IconContext.Provider>
            <div className="edu_cat_data">
              <h4 className="title">
                <p>
                  Hot Leads
                  <strong className="pl-2">{leadData.hotLeads}</strong>
                </p>
              </h4>
              <div className="">
                <b style={{ fontSize: "17px" }}>
                  &#8377;
                  {leadData.hotPrice && leadData.hotPrice.toLocaleString()}
                </b>
              </div>
            </div>
          </div>
        </div>

        <div className="dash col">
          <div
            className="edu_cat_2 cat-1"
            style={{
              backgroundImage:
                "linear-gradient(to right, #E5E5E5, #edabab, #ed3434 )",
            }}
          >
            <div className="edu_cat_icons2">
              <MdOpenInNew size={28} />
            </div>
            <div className="edu_cat_data">
              <h4 className="title">
                <p>
                  Enrolled
                  <strong className="pl-2">{leadData.enrolled}</strong>
                </p>
              </h4>
              <div className="">
                <b style={{ fontSize: "17px" }}>
                  &#8377;
                  {leadData.enrolledPrice &&
                    leadData.enrolledPrice.toLocaleString()}
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-1 pl-3">
        <div
          className="col-lg-12"
          style={loading ? { pointerEvents: "none", opacity: "0.4" } : {}}
        >
          <div style={{ backgroundColor: "#F3F3F3" }}>
            <div className="table-full-2">
              <div style={{ padding: 0, margin: 0 }}>
                <div className="row" style={{ padding: "3px" }}>
                  <div className="col-md-2 col-sm-3 col-xs-4">
                    <FormControl
                      style={{
                        width: "100%",
                        overflow: "eclipse",
                      }}
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        style={{
                          padding: "0px 2px 0 0",
                          backgroundColor: "white",
                        }}
                        size="small"
                      >
                        City
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={City}
                        onChange={handleChangeForCity}
                        input={
                          <OutlinedInput
                            style={{
                              fontFamily: "Prox",
                              fontSize: "13px",
                              marginLeft: "0px",
                              padding: "0px",
                            }}
                            label="Tag"
                          />
                        }
                        renderValue={(selected) => selected.join(", ")}
                      >
                        <MenuItem
                          disabled={loading}
                          value="all"
                          style={{
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <Checkbox
                            style={{
                              marginLeft: "0px",
                              padding: "0px 2px",
                            }}
                            checked={City.length === cities.length}
                          />
                          <ListItemText
                            style={{
                              marginLeft: "0px",
                              paddingRight: "2px",
                            }}
                            primary="All Cities"
                          />
                        </MenuItem>
                        {cities &&
                          cities.map((name) => (
                            <MenuItem
                              disabled={loading}
                              key={name.CityName}
                              value={name.CityName}
                              style={{
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              <Checkbox
                                style={{
                                  marginLeft: "0px",
                                  padding: "0px 2px",
                                }}
                                checked={City.indexOf(name.CityName) > -1}
                              />
                              <ListItemText
                                style={{
                                  marginLeft: "0px",
                                  paddingRight: "2px",
                                }}
                                primary={name.CityName}
                              />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-4">
                    <FormControl
                      style={{
                        width: "100%",
                        overflow: "eclipse",
                      }}
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        style={{
                          padding: "0px 2px 0 0",
                          backgroundColor: "white",
                        }}
                        size="small"
                      >
                        Source
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={Source}
                        onChange={handleChangeForSource}
                        input={
                          <OutlinedInput
                            style={{
                              fontFamily: "Prox",
                              fontSize: "13px",
                              marginLeft: "0px",
                              padding: "0px",
                            }}
                            label="Tag"
                          />
                        }
                        renderValue={(selected) => selected.join(", ")}
                      >
                        <MenuItem
                          value="all"
                          style={{
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <Checkbox
                            style={{
                              marginLeft: "0px",
                              padding: "0px 2px",
                            }}
                            checked={sourceDetail.length === Source.length}
                          />
                          <ListItemText
                            style={{
                              marginLeft: "0px",
                              paddingRight: "2px",
                            }}
                            primary="All Sources"
                          />
                        </MenuItem>
                        {sourceDetail &&
                          sourceDetail.map((name) => (
                            <MenuItem
                              key={name.SourceName}
                              value={name.SourceName}
                              style={{
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              <Checkbox
                                style={{
                                  marginLeft: "0px",
                                  padding: "0px 2px",
                                }}
                                checked={Source.indexOf(name.SourceName) > -1}
                              />
                              <ListItemText
                                style={{
                                  marginLeft: "0px",
                                  paddingRight: "2px",
                                }}
                                primary={name.SourceName}
                              />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-4">
                    <FormControl
                      style={{
                        width: "100%",
                        overflow: "eclipse",
                      }}
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        style={{
                          padding: "0px 2px 0 0",
                          backgroundColor: "white",
                        }}
                        size="small"
                      >
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={Status}
                        onChange={handleChangeForStatus}
                        input={
                          <OutlinedInput
                            style={{
                              fontFamily: "Prox",
                              fontSize: "13px",
                              marginLeft: "0px",
                              padding: "0px",
                            }}
                            label="Tag"
                          />
                        }
                        renderValue={(selected) => selected.join(", ")}
                      >
                        <MenuItem
                          value="all"
                          style={{
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <Checkbox
                            style={{
                              marginLeft: "0px",
                              padding: "0px 2px",
                            }}
                            checked={statuses.length === Status.length}
                          />
                          <ListItemText
                            style={{
                              marginLeft: "0px",
                              paddingRight: "2px",
                            }}
                            primary="All Statuses"
                          />
                        </MenuItem>
                        {statuses &&
                          statuses.map((name) => (
                            <MenuItem
                              key={name.StatusName}
                              value={name.StatusName}
                              style={{
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              <Checkbox
                                style={{
                                  marginLeft: "0px",
                                  padding: "0px 2px",
                                }}
                                checked={Status.indexOf(name.StatusName) > -1}
                              />
                              <ListItemText
                                style={{
                                  marginLeft: "0px",
                                  paddingRight: "2px",
                                }}
                                primary={name.StatusName}
                              />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-4">
                    {user.role === "admin" ? (
                      <FormControl
                        style={{
                          width: "100%",
                          overflow: "eclipse",
                        }}
                      >
                        <InputLabel
                          id="demo-multiple-checkbox-label"
                          style={{
                            padding: "0px 2px 0 0",
                            backgroundColor: "white",
                          }}
                          size="small"
                        >
                          Branch
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={Branch}
                          onChange={handleChangeForBranch}
                          input={
                            <OutlinedInput
                              style={{
                                fontFamily: "Prox",
                                fontSize: "13px",
                                marginLeft: "0px",
                                padding: "0px",
                              }}
                              label="Tag"
                            />
                          }
                          renderValue={(selected) => selected.join(", ")}
                        >
                          <MenuItem
                            value="all"
                            style={{
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            <Checkbox
                              style={{
                                marginLeft: "0px",
                                padding: "0px 2px",
                              }}
                              checked={Branch.length === branchDetail.length}
                            />
                            <ListItemText
                              style={{
                                marginLeft: "0px",
                                paddingRight: "2px",
                              }}
                              primary="All Branches"
                            />
                          </MenuItem>
                          {branchDetail &&
                            branchDetail.map((name) => (
                              <MenuItem
                                key={name.BranchName}
                                value={name.BranchName}
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <Checkbox
                                  style={{
                                    marginLeft: "0px",
                                    padding: "0px 2px",
                                  }}
                                  checked={Branch.indexOf(name.BranchName) > -1}
                                />
                                <ListItemText
                                  style={{
                                    marginLeft: "0px",
                                    paddingRight: "2px",
                                  }}
                                  primary={name.BranchName}
                                />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl
                        style={{
                          width: "100%",
                          overflow: "eclipse",
                        }}
                      >
                        <InputLabel
                          id="demo-multiple-checkbox-label"
                          style={{
                            padding: "0px 2px 0 0",
                            backgroundColor: "white",
                          }}
                          size="small"
                        >
                          Branch
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={Branch}
                          onChange={handleChangeForBranchToUser}
                          input={
                            <OutlinedInput
                              style={{
                                fontFamily: "Prox",
                                fontSize: "13px",
                                marginLeft: "0px",
                                padding: "0px",
                              }}
                              label="Tag"
                            />
                          }
                          renderValue={(selected) => selected.join(", ")}
                        >
                          <MenuItem
                            value="all"
                            style={{
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            <Checkbox
                              style={{
                                marginLeft: "0px",
                                padding: "0px 2px",
                              }}
                              checked={
                                Branch.length === branchPermissions.length
                              }
                            />
                            <ListItemText
                              style={{
                                marginLeft: "0px",
                                paddingRight: "2px",
                              }}
                              primary="All Branches"
                            />
                          </MenuItem>
                          {branchPermissions &&
                            branchPermissions.map((branch, index) => (
                              <MenuItem
                                disabled={loading}
                                key={index}
                                value={branch}
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <Checkbox
                                  style={{
                                    marginLeft: "0px",
                                    padding: "0px 2px",
                                  }}
                                  checked={Branch.indexOf(branch) > -1}
                                />
                                <ListItemText
                                  style={{
                                    marginLeft: "0px",
                                    paddingRight: "2px",
                                  }}
                                  primary={branch}
                                />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>

                  <div className="col-md-2 col-sm-3 col-xs-4">
                    <FormControl
                      style={{
                        width: "100%",
                        overflow: "eclipse",
                      }}
                    >
                      <InputLabel
                        id="demo-multiple-checkbox-label"
                        style={{
                          padding: "0px 2px 0 0",
                          backgroundColor: "white",
                        }}
                        size="small"
                      >
                        Course
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={Course}
                        onChange={handleChangeForCourse}
                        input={
                          <OutlinedInput
                            style={{
                              fontFamily: "Prox",
                              fontSize: "13px",
                              marginLeft: "0px",
                              padding: "0px",
                            }}
                            label="Tag"
                          />
                        }
                        renderValue={(selected) => selected.join(", ")}
                      >
                        <MenuItem
                          value="all"
                          style={{
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <Checkbox
                            style={{
                              marginLeft: "0px",
                              padding: "0px 2px",
                            }}
                            checked={Course.length === courseDetail.length}
                          />
                          <ListItemText
                            style={{
                              marginLeft: "0px",
                              paddingRight: "2px",
                            }}
                            primary="All Courses"
                          />
                        </MenuItem>
                        {courseDetail &&
                          courseDetail.map((name) => (
                            <MenuItem
                              key={name.CourseName}
                              value={name.CourseName}
                              style={{
                                margin: "0px",
                                padding: "0px",
                              }}
                            >
                              <Checkbox
                                style={{
                                  marginLeft: "0px",
                                  padding: "0px 2px",
                                }}
                                checked={Course.indexOf(name.CourseName) > -1}
                              />
                              <ListItemText
                                style={{
                                  marginLeft: "0px",
                                  paddingRight: "2px",
                                }}
                                primary={name.CourseName}
                              />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-md-2">
                    {user.role === "admin" ? (
                      <FormControl
                        style={{
                          width: "100%",
                          overflow: "eclipse",
                        }}
                      >
                        <InputLabel
                          style={{
                            padding: "0px 2px 0 0",
                            backgroundColor: "white",
                          }}
                          id="demo-multiple-checkbox-label"
                          size="small"
                        >
                          Assign To
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={Owner}
                          onChange={handleChangeForOwner}
                          input={<OutlinedInput label="Owner" />}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          <MenuItem
                            value="all"
                            style={{
                              margin: "0px",
                              padding: "0px",
                            }}
                          >
                            <Checkbox
                              style={{
                                marginLeft: "0px",
                                padding: "0px 2px",
                              }}
                              checked={leadOwner.length === Owner.length}
                            />
                            <ListItemText
                              style={{
                                marginLeft: "0px",
                                paddingRight: "2px",
                              }}
                              primary="All"
                            />
                          </MenuItem>
                          {leadOwner &&
                            leadOwner.map((name) => (
                              <MenuItem
                                key={name.UserName}
                                value={name.UserName}
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <Checkbox
                                  style={{
                                    marginLeft: "0px",
                                    padding: "0px 2px",
                                  }}
                                  checked={Owner.indexOf(name.UserName) > -1}
                                />
                                <ListItemText
                                  style={{
                                    marginLeft: "0px",
                                    paddingRight: "2px",
                                  }}
                                  primary={name.UserName}
                                />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl
                        style={{
                          width: "100%",
                          overflow: "eclipse",
                        }}
                      >
                        <InputLabel
                          style={{
                            padding: "0px 2px 0 0",
                            backgroundColor: "white",
                          }}
                          id="demo-multiple-checkbox-label"
                          size="small"
                        >
                          Assign To
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={Owner}
                          onChange={handleChangeForOwner}
                          input={<OutlinedInput label="Owner" />}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {permissions &&
                            [user.username, ...permissions].map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              >
                                <Checkbox
                                  style={{
                                    marginLeft: "0px",
                                    padding: "0px 2px",
                                  }}
                                  checked={Owner.indexOf(name) > -1}
                                />
                                <ListItemText
                                  style={{
                                    marginLeft: "0px",
                                    paddingRight: "2px",
                                  }}
                                  primary={name}
                                />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                </div>

                <div className="row" style={{ padding: "3px" }}>
                  <div className="col-4">
                    <DateRangePicker
                      value={range1Filter}
                      appearance="default"
                      placeholder="Follow Up Date"
                      style={{
                        width: "100%",
                        border: "1px solid black",
                        borderRadius: "5px",
                      }}
                      onChange={(item) => {
                        item &&
                          setRange1([
                            {
                              startDate: item[0],
                              endDate: item[1],
                              key: "selection",
                            },
                          ]);
                        item && setRange1Filter(item);
                        item && localStorage.setItem("range1Filter", item);
                      }}
                      // onSelect={handleDateSelect}
                      ranges={[]}
                      format="dd/MM/yy"
                      onClean={() => {
                        setRange1([
                          {
                            startDate: addYears(new Date(), -10),
                            endDate: addYears(new Date(), 10),
                            key: "selection",
                          },
                        ]);
                        setRange1Filter([]);
                        localStorage.removeItem("range1Filter");
                      }}
                      showOneCalendar={true}
                    />
                  </div>

                  <div className="col-4 ">
                    <DateRangePicker
                      value={range2Filter}
                      appearance="default"
                      placeholder="Select Enquiry Date"
                      style={{
                        width: "100%",
                        border: "1px solid black",
                        borderRadius: "5px",
                      }}
                      onChange={(item) => {
                        item &&
                          setRange2([
                            {
                              startDate: item[0],
                              endDate: item[1],
                              key: "selection",
                            },
                          ]);
                        item && setRange2Filter(item);
                        item && localStorage.setItem("range2Filter", item);
                      }}
                      ranges={[]}
                      format="dd/MM/yy"
                      onClean={() => {
                        setRange2([
                          {
                            startDate: addYears(new Date(), -10),
                            endDate: addYears(new Date(), 10),
                            key: "selection",
                          },
                        ]);
                        setRange2Filter([]);
                        localStorage.removeItem("range2Filter");
                      }}
                      showOneCalendar={true}
                    />
                  </div>

                  <div
                    className="col-4 search-dash"
                    style={{
                      width: "100%",
                    }}
                  >
                    <TextField
                      fullWidth
                      id="search-bar"
                      value={keyword}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AiOutlineSearch />
                          </InputAdornment>
                        ),
                        endAdornment: keyword.length > 0 && (
                          <Button
                            compact
                            color="white"
                            onClick={() => {
                              setKeyword("");
                            }}
                            size="tiny"
                            icon="close"
                          ></Button>
                        ),
                      }}
                      label="Search"
                      variant="outlined"
                      placeholder=""
                      size="small"
                    />
                  </div>
                </div>
              </div>
              <div id={classes.clearFilter}>
                <IconContext.Provider value={{ color: "purple", size: 24 }}>
                  <button onClick={clearFilter}>
                    <TbFilterOff />
                  </button>
                </IconContext.Provider>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span
                    style={{
                      marginLeft: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Leads :{" "}
                  </span>
                  <select
                    onChange={(e) => {
                      setresultPerPage(e.target.value);
                      setpage(1);
                    }}
                  >
                    <option value="100">100</option>
                    <option value="50">50</option>
                    <option value="20">20</option>
                    <option value="10">10</option>
                  </select>
                </div>

                {/* Promotion Start */}

                <div>
                  <IconContext.Provider
                    value={{ color: "green", size: "20px", cursor: "pointer" }}
                  >
                    <span
                      className="pointer"
                      onClick={() =>
                        checkedItems.size === 0
                          ? alert("Please Select atleast One lead")
                          : checkedItems.size > 3
                          ? alert("You Can Select Only 3 Leads at a Time")
                          : forwh()
                      }
                    >
                      <RiWhatsappFill />
                    </span>
                  </IconContext.Provider>

                  <IconContext.Provider
                    value={{ color: "#E12929", size: "20px" }}
                  >
                    <span
                      className="pointer"
                      onClick={() =>
                        checkedItems.size > 0
                          ? formail()
                          : alert("Please Select atleast One lead")
                      }
                    >
                      <SiGmail
                        style={{
                          margin: "0px 10px",
                        }}
                      />
                    </span>
                  </IconContext.Provider>
                  {/* <IconContext.Provider
                    value={{ color: "blue", size: "20px", cursor: "pointer" }}
                  >
                    <span
                      className="pointer"
                      onClick={() =>
                        checkedItems.size > 0
                          ? forsms
                          : alert("Please Select atleast One lead")
                      }
                    >
                      <FaSms />
                    </span>
                  </IconContext.Provider> */}
                </div>

                {/* for whatsapp */}
                {openWh && (
                  <div
                    style={{
                      minHeight: "100vh",
                      minWidth: "100vw",
                      zIndex: 99,
                      position: "absolute",
                      top: -100,
                      left: 0,
                      backdropFilter: "blur(4px)",
                      backgroundColor: "rgb(0 0 0 / 58%)",
                    }}
                  >
                    <WhatsappModal
                      forwh={forwh}
                      sendpromoforimg={sendpromoforimg}
                      sendpromoformsg={sendpromoformsg}
                      sendpromofordoc={sendpromofordoc}
                      whatsappMessageTemplates={whatsappMessageTemplates}
                      // whatsappLinkTemplates={whatsappLinkTemplates}
                      whatsappImageTemplates={whatsappImageTemplates}
                      whatsappDocumentTemplates={whatsappDocumentTemplates}
                    />
                  </div>
                )}

                {/* for sms */}

                {openSms && (
                  <div
                    style={{
                      minHeight: "100vh",
                      minWidth: "100vw",
                      zIndex: 99,
                      position: "absolute",
                      top: -100,
                      left: 0,
                      backdropFilter: "blur(4px)",
                      backgroundColor: "rgb(0 0 0 / 58%)",
                    }}
                  >
                    <SMSModal
                      sendpromoforsms={sendpromoforsms}
                      smsTemplates={smsTemplates}
                      forsms={forsms}
                    />
                  </div>
                )}

                {/* for Email */}
                {openMail && (
                  <div
                    style={{
                      minHeight: "100vh",
                      minWidth: "100vw",
                      zIndex: 99,
                      position: "absolute",
                      top: -100,
                      left: 0,
                      backdropFilter: "blur(4px)",
                      backgroundColor: "rgb(0 0 0 / 58%)",
                    }}
                  >
                    <EmailModal
                      sendpromoformail={sendpromoformail}
                      mailTemplates={mailTemplates}
                      formail={formail}
                    />
                  </div>
                )}

                {/* Promotion End */}

                <div
                  style={{
                    display: "flex",
                    marginRight: "20px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={prevpage}
                  >
                    <BsFillArrowLeftSquareFill />
                  </div>

                  <p
                    style={{
                      fontFamily: "Prox",
                      margin: "0px 10px",
                    }}
                  >
                    {(page - 1) * resultPerPage + 1} -
                    {resultPerPage * page >= leadData.filteredCount
                      ? leadData.filteredCount
                      : resultPerPage * page}{" "}
                    Leads From {leadData.filteredCount}
                  </p>

                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={nextpage}
                  >
                    <BsFillArrowRightSquareFill />
                  </div>
                </div>
              </div>
              {loading && <Spin style={{ zIndex: 1000 }} size="large" />}
              <table
                className={`table table-bordered table-sm ${classes.dashboardTable}`}
                id={classes.dashboardTable}
              >
                <thead>
                  <tr className="bg-secondary text-white table-sel">
                    <td className={classes.checkbox}>
                      <input
                        type="checkbox"
                        onChange={handleCheck}
                        checked={isCheckedAll}
                      />
                    </td>

                    <td className={classes.index}>UID</td>

                    <td
                      onClick={() => sorting("AssignTo")}
                      className={classes.AssignTo}
                    >
                      Ass. To{" "}
                      {aorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>
                    <td
                      onClick={() => sorting("Source")}
                      className={classes.source}
                    >
                      Source{" "}
                      {sorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>
                    <td
                      onClick={() => sorting("City")}
                      className={classes.city}
                    >
                      City {ciorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>
                    <td
                      className={classes.status}
                      onClick={() => sorting("Status")}
                    >
                      Status{" "}
                      {ciorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>
                    <td
                      onClick={() => sorting("EnquiryDate")}
                      className={classes.followup}
                    >
                      Enq. Date{" "}
                      {enqDorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}{" "}
                    </td>
                    <td
                      onClick={() => sorting("Days2")}
                      className={classes.followup}
                    >
                      LFD
                      {lforder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}{" "}
                    </td>
                    <td
                      onClick={() => sorting("Name")}
                      className={classes.name}
                    >
                      Name {norder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>
                    <td
                      onClick={() => sorting("Phone1")}
                      className={classes.mob}
                    >
                      Mob_1{" "}
                      {m1order === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>

                    <td
                      onClick={() => sorting("Course")}
                      className={classes.course}
                    >
                      Course{" "}
                      {corder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>
                    <td
                      onClick={() => sorting("CoursePrice")}
                      className={classes.price}
                    >
                      Price {porder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>
                    <td
                      onClick={() => sorting("Days")}
                      className={classes.days}
                    >
                      Days {dorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>

                    <td
                      onClick={() => sorting("FollowupDate")}
                      className={classes.followup}
                    >
                      Followup{" "}
                      {forder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}{" "}
                    </td>

                    <td className={classes.action}>Action</td>
                  </tr>
                </thead>

                <tbody className={user.role === "admin" ? "tbody1" : "tbody1"}>
                  {leads &&
                    leads.map((val, idx) => {
                      let alter = idx % 2 === 1 ? "#fff" : "#E9F0FF";

                      let color =
                        val.Repeat === true
                          ? "red"
                          : val.Status === "Hot Lead"
                          ? "black"
                          : val.Status === "Final Lead"
                          ? "#eee"
                          : val.Status === "Unsubscribe"
                          ? "#eee"
                          : "black";
                      let backgroundColor =
                        val.Repeat === true
                          ? "#fff"
                          : val.Status === "Hot Lead"
                          ? "rgb(255 241 137)"
                          : val.Status === "Final Lead"
                          ? "#8282f6"
                          : val.Status === "Unsubscribe"
                          ? "#e75b5b"
                          : alter;
                      return (
                        <tr
                          key={idx}
                          className="table-details"
                          style={{ backgroundColor: backgroundColor }}
                        >
                          <td className={classes.checkbox}>
                            <input
                              type="checkbox"
                              data-key={val._id}
                              onChange={handleCheck}
                              checked={checkedItems.has(val._id)}
                            />
                          </td>
                          <td
                            className={classes.index}
                            style={{
                              color: color,
                            }}
                          >
                            {val.UID}
                          </td>
                          <td
                            className={classes.AssignTo}
                            style={{
                              color: color,
                            }}
                          >
                            {val.AssignTo}
                          </td>
                          <td
                            className={classes.source}
                            style={{
                              color: color,
                            }}
                          >
                            &nbsp;{val.Source}
                          </td>
                          <td
                            className={classes.city}
                            style={{
                              color: color,
                            }}
                          >
                            {val.City}
                          </td>
                          <td
                            className={classes.status}
                            style={{
                              color: color,
                            }}
                          >
                            {val.Status}
                          </td>
                          {/* new last Follow up date and  date of enquiry */}
                          <td
                            className={classes.followup}
                            style={{
                              color: color,
                            }}
                          >
                            {val.EnquiryDate &&
                              val.EnquiryDate.slice(5, 10)
                                .split("-")
                                .reverse()
                                .join("/") +
                                "/" +
                                val.EnquiryDate.slice(2, 4)}
                          </td>
                          <td
                            className={classes.followup}
                            style={{
                              color: color,
                            }}
                          >
                            {val.Days2}
                          </td>

                          {/* end here */}
                          <td
                            className={classes.name}
                            style={{
                              color: color,
                            }}
                          >
                            &nbsp;{val.Name}
                          </td>
                          <td
                            className={classes.mob}
                            style={{
                              color: color,
                            }}
                          >
                            {val.Phone1 !== "0" ? <>{val.Phone1}</> : <></>}
                            {/* {val.Phone1} */}
                          </td>
                          {/* <td
                                className={classes.mob}
                                style={{
                                  color: color,
                                }}
                              >
                                {val.Phone2 !== "0" ? <>{val.Phone2}</> : <></>}
                              </td> */}

                          <td
                            className={classes.course}
                            style={{
                              color: color,
                            }}
                          >
                            &nbsp;{val.Course}
                          </td>
                          <td
                            className={classes.price}
                            style={{
                              color: color,
                            }}
                          >
                            {val.CoursePrice &&
                              val.CoursePrice.toLocaleString()}
                            &nbsp;
                          </td>
                          <td
                            className={classes.days}
                            style={{
                              color: color,
                            }}
                          >
                            {val.Days}
                          </td>

                          <td
                            className={classes.followup}
                            style={{
                              color: color,
                            }}
                          >
                            {val.FollowupDate &&
                              val.FollowupDate.slice(5, 10)
                                .split("-")
                                .reverse()
                                .join("/") +
                                "/" +
                                val.FollowupDate.slice(2, 4)}
                          </td>

                          <td className={classes.action}>
                            <Popup
                              contentStyle={{
                                width: "20rem",
                                border: "2px solid red",
                              }}
                              trigger={
                                <i
                                  className="fa fa-eye   actionBtn"
                                  style={{
                                    marginRight: "5px",
                                  }}
                                  onClick={() => {
                                    setViewId(val._id);
                                    setView(true);
                                  }}
                                ></i>
                              }
                              on={["hover", "focus"]}
                              position="left top"
                            >
                              <LeadPeek viewId={val._id} />
                            </Popup>

                            {user.role === "admin" ? (
                              <span
                                data-toggle="modal"
                                data-target="#mymodal"
                                onClick={() => setID(val._id)}
                              >
                                <i
                                  className="fa fa-trash   actionBtn"
                                  style={
                                    val.Status === "Unsubscribe"
                                      ? { color: "white" }
                                      : {}
                                  }
                                ></i>
                              </span>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div style={{ backgroundColor: "#fff" }}>
                <Pagination
                  style={{ float: "right" }}
                  variant="outlined"
                  color="primary"
                  size="small"
                  page={page}
                  onChange={(e, value) => setpage(value)}
                  count={Math.ceil(leadData.filteredCount / resultPerPage) || 1}
                />
              </div>
              <div
                className="modal fade"
                id="mymodal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-md modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Confirmation</h5>
                    </div>
                    <div className="modal-body">
                      Are you sure to delete this Lead Information permanently?
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary btn-flat"
                        id="confirm"
                        onClick={() => deleteLead(ID)}
                        data-dismiss="modal"
                      >
                        Continue
                      </button>
                      <button
                        type="button"
                        className="btn  border btn-flat"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <ViewOpp viewId={viewId} hideView={hideViewPage} />
  );
}
