import React, { useEffect, useState } from "react";
import Axios from "axios";
import Navbar from "./Navbar";
import TextField from "@mui/material/TextField";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { message, Spin } from "antd";
import { useHistory } from "react-router-dom";

const SendImage = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = "Send Image";
  }, []);

  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [imgurl, setImgurl] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [leads, setLeads] = useState([]);
  const [lead, setLead] = useState("");
  const [loading, setLoading] = useState(false);
  const [leadError, setLeadError] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);

  const link1 = "/api/lead/readAllLeads";
  const link2 =
    "/api/lead/showLeadAccToPermission/" + localStorage.getItem("userid");

  let link = "";

  if (localStorage.getItem("role") === "admin") {
    link = link1;
  } else {
    link = link2;
  }

  useEffect(() => {
    Axios.get(`${link}`).then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 100);
      setLeads(response.data.lead);
    });
  }, []);

  const setImageUploadFunction = (file) => {
    setImageUpload(file);
    const imageRef = ref(storage, `images/${file.name + v4()}`);
    uploadBytes(imageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
        setImgurl(url);
      });
    });
  };

  const addToList = () => {
    const config = { headers: { "Content-Type": "application/json" } };

    Axios.post(
      "/api/lead/SendwhatsappImage/" + lead,
      {
        msg: title,
        img: imgurl,
      },
      config
    )
      .then(function (response) {
        message.success(" Message Sent successfully");
        setTimeout(function () {
          history.push("/whatsapp-template");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Send Image" show="false" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingBottom: "10px",
            fontSize: "25px",
          }}
        >
          {/* <h2>Add Branch</h2> */}
        </div>
        <div className="row mt-3 mb-2 pr-3 pl-3">
          <div className="col-lg-12">
            <div className="card" style={{ backgroundColor: "#F3F3F3" }}>
              <div className="card-body">
                <div className="row form-group  " style={{ padding: "10px" }}>
                  <div className="col-lg-6">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Lead
                      </InputLabel>
                      <Select
                        defaultValue=""
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Lead"
                        onChange={(e) => setLead(e.target.value)}
                        error={leadError}
                      >
                        {leads &&
                          leads.map((item) => {
                            return (
                              <MenuItem value={item._id}>{item.Name}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-6">
                    <div className="dropzone lead-fields pt-1 pb-3 pl-3">
                      <input
                        style={{ maxWidth: "90%" }}
                        type="file"
                        accept="image/*"
                        required
                        onChange={(event) => {
                          setImageUploadFunction(event.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <ul>
                      {fileNames.map((fileName) => (
                        <li key={fileName}>{fileName}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="row form-group  " style={{ padding: "10px" }}>
                  <div className="col-lg-6">
                    <TextField
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Title"
                      onChange={(e) => setTitle(e.target.value)}
                      error={titleError}
                    />
                  </div>
                </div>

                <div className="float-right pr-3">
                  {" "}
                  <button
                    onClick={addToList}
                    className="btn btn-primary add-button"
                  >
                    {" "}
                    Send Image{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendImage;
