import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Transition } from "semantic-ui-react";
import classes from "./Whatsapp.module.css";
import Navbar from "./Navbar";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";
import Axios from "axios";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { AiOutlineSearch } from "react-icons/ai";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { message, Spin } from "antd";

const LinkTemplate = () => {
  const [keyword, setKeyword] = useState("");
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("ASC");
  const [torder, setTOrder] = useState("ASC");
  const [morder, setMOrder] = useState("ASC");
  const [lorder, setLOrder] = useState("ASC");

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...rowData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );

      if (col === "Title") setTOrder("DSC");
      if (col === "Description") setMOrder("DSC");
      if (col === "Link") setLOrder("DSC");
      setRowData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...rowData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );

      if (col === "Title") setTOrder("ASC");
      if (col === "Description") setMOrder("ASC");
      if (col === "Link") setLOrder("ASC");

      setRowData(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    document.title = "Link Template";
  }, []);

  useEffect(() => {
    Axios.get("/api/templatelink/readAllTemplate").then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 100);
      setRowData(response.data.Templates);
    });
  }, []);

  let ID = 0;

  const setID = (id) => {
    ID = id;
  };

  const deleteLead = (id) => {
    Axios.delete(`/api/templatelink/removeTemplate/${id}`)
      .then(function (response) {
        message.success(" Deleted successfully");
        Axios.get("/api/templatelink/readAllTemplate").then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setRowData(response.data.Templates);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const activeClick = (val, id) => {
    if (val === "inactive") {
      const config = { headers: { "Content-Type": "application/json" } };

      Axios.put(
        "/api/templatelink/modifyTemplate/" + id,
        {
          active: "active",
        },
        config
      )
        .then(function (response) {
          message.success(" Updated successfully");
          Axios.get("/api/templatelink/readAllTemplate").then((response) => {
            setTimeout(function () {
              setLoading(false);
            }, 100);
            setRowData(response.data.Templates);
          });
        })
        .catch(function (error) {
          message.error(`${error.response.data.message}`);
        });
    } else {
      return;
    }
  };

  const inactiveClick = (val, id) => {
    if (val === "active") {
      const config = { headers: { "Content-Type": "application/json" } };

      Axios.put(
        "/api/templatelink/modifyTemplate/" + id,
        {
          active: "inactive",
        },
        config
      )
        .then(function (response) {
          message.success(" Updated successfully");
          Axios.get("/api/templatelink/readAllTemplate").then((response) => {
            setTimeout(function () {
              setLoading(false);
            }, 100);
            setRowData(response.data.Templates);
          });
        })
        .catch(function (error) {
          message.error(`${error.response.data.message}`);
        });
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      <Navbar title="Link Template" show="false"></Navbar>
      <div
        className="container-fluid pt-2"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <h2>
          <div className="">
            <div className="row">
              <div className="col-3"></div>
              <div className="col-5 search-dash">
                <TextField
                  id="search-bar"
                  fullWidth
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AiOutlineSearch />
                      </InputAdornment>
                    ),
                  }}
                  label="Search"
                  variant="outlined"
                  placeholder=""
                  size="small"
                />
              </div>
              <div className="col-2"></div>
              <div className="col-2 ">
                <Link to="/addlink">
                  <a>
                    <button
                      className="btn  add-button btn-primary float-right"
                      to="/"
                    >
                      {" "}
                      Add Template{" "}
                    </button>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </h2>
        <div className="row mt-3 mb-4 pr-3 pl-3">
          <div className="col-lg-12">
            <div className="" style={{ backgroundColor: "#F3F3F3" }}>
              <div
                class="table-full template"
                style={{
                  maxHeight: "70vh",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Container
                  className={classes.gridContainer}
                  style={{ width: "100%" }}
                >
                  <Grid id={classes.whatsappGridTable}>
                    <div
                      className={classes.gridDiv}
                      style={{
                        backgroundColor: "#8fcaeb",
                        textAlign: "center",
                      }}
                    >
                      <Grid>
                        <Grid.Row id={classes.gridRow} textAlign="centered">
                          <Grid.Column
                            onClick={() => sorting("Title")}
                            textAlign="centered"
                            width={2}
                          >
                            <h5>
                              Title{" "}
                              {torder === "ASC" ? (
                                <BsArrowDown />
                              ) : (
                                <BsArrowUp />
                              )}
                            </h5>
                          </Grid.Column>
                          <Grid.Column
                            onClick={() => sorting("Link")}
                            textAlign="centered"
                            width={2}
                          >
                            <h5>
                              Link{" "}
                              {lorder === "ASC" ? (
                                <BsArrowDown />
                              ) : (
                                <BsArrowUp />
                              )}
                            </h5>
                          </Grid.Column>

                          <Grid.Column
                            onClick={() => sorting("Description")}
                            textAlign="centered"
                            width={7}
                          >
                            <h5>
                              Description{" "}
                              {morder === "ASC" ? (
                                <BsArrowDown />
                              ) : (
                                <BsArrowUp />
                              )}
                            </h5>
                          </Grid.Column>
                          <Grid.Column textAlign="centered" width={2}>
                            <h5>Status</h5>
                          </Grid.Column>
                          <Grid.Column textAlign="centered" width={3}>
                            <h5>Action</h5>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>

                    {loading && <Spin size="large" />}
                    <div
                      className={classes.tableContent}
                      style={{ width: "100%", maxHeight: "70vh" }}
                    >
                      {rowData
                        .filter((e) => {
                          if (keyword === "") {
                            return e;
                          } else if (
                            e.Title.toLowerCase().includes(
                              keyword.toLowerCase()
                            ) ||
                            e.Link.toLowerCase().includes(
                              keyword.toLowerCase()
                            ) ||
                            e.Description.toLowerCase().includes(
                              keyword.toLowerCase()
                            )
                          ) {
                            return e;
                          }
                        })
                        .map((row, index) => {
                          index = index + 1;
                          let alter = index % 2 === 1 ? "#fff" : "#E9F0FF";

                          let activeColor1 =
                            row.active === "active" ? "positive " : "";

                          let activeColor2 =
                            row.active !== "active" ? "negative " : "";

                          return (
                            <Grow appear="false" in>
                              <div
                                className={classes.gridDiv}
                                style={{ backgroundColor: alter }}
                              >
                                <Grid>
                                  <Grid.Row
                                    // color={alter}
                                    textAlign="centered"
                                    key={index}
                                    //   style={{ padding: "2rem 0rem" }}
                                  >
                                    <Grid.Column textAlign="centered" width={2}>
                                      <p>{row.Title}</p>
                                    </Grid.Column>
                                    <Grid.Column textAlign="centered" width={2}>
                                      <p>
                                        {row.Link &&
                                          row.Link.slice(0, 18) + "..."}
                                      </p>
                                    </Grid.Column>

                                    <Grid.Column textAlign="centered" width={7}>
                                      <p>{row.Description}</p>
                                    </Grid.Column>
                                    <Grid.Column textAlign="centered" width={2}>
                                      <Button.Group size="mini">
                                        <Button
                                          color={activeColor1}
                                          onClick={() =>
                                            activeClick(row.active, row._id)
                                          }
                                        >
                                          Active
                                        </Button>
                                        <Button.Or />
                                        <Button
                                          color={activeColor2}
                                          onClick={() =>
                                            inactiveClick(row.active, row._id)
                                          }
                                        >
                                          Inactive
                                        </Button>
                                      </Button.Group>
                                    </Grid.Column>
                                    <Grid.Column textAlign="centered" width={3}>
                                      {/* {row.active === 'active' ? (
                                        <Link to={'/sendmessage/' + row._id}>
                                          <Button circular icon="send"></Button>
                                        </Link>
                                      ) : (
                                        <></>
                                      )} */}

                                      <Link to={"/editlink/" + row._id}>
                                        <Button
                                          circular
                                          color="instagram"
                                          icon="pencil alternate"
                                        ></Button>
                                      </Link>
                                      <a
                                        data-toggle="modal"
                                        data-target="#mymodal"
                                        onClick={() => setID(row._id)}
                                      >
                                        <Button
                                          circular
                                          color="red"
                                          icon="trash alternate outline"
                                        ></Button>
                                      </a>
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </div>
                            </Grow>
                          );
                        })}

                      <div
                        className="modal fade"
                        id="mymodal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-md modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">Confirmation</h5>
                            </div>
                            <div className="modal-body">
                              Are you sure to delete this Information
                              permanently?
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-primary btn-flat"
                                id="confirm"
                                onClick={() => deleteLead(ID)}
                                data-dismiss="modal"
                              >
                                Continue
                              </button>
                              <button
                                type="button"
                                className="btn  border btn-flat"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LinkTemplate;
