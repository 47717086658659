import React, { useEffect, useState } from "react";
import Axios from "axios";
import TextField from "@mui/material/TextField";
import Navbar from "./Navbar";
import { useParams, useHistory } from "react-router-dom";
import Loading from "./Components/Loading";
import { message, Spin } from "antd";

function EditCity() {
  const [list, setList] = useState([]);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    document.title = "Update City";
  }, []);

  useEffect(() => {
    Axios.get("/api/city/readOneCity/" + params.id, {
      withCredentials: true,
    }).then((response) => {
      setList(response.data.city);
    });
  }, []);

  const [city, setCity] = useState(list.CityName);
  const [cityError, setCityError] = useState(false);
  const [loading, setLoading] = useState(false);

  const addToList = () => {
    setCityError(false);

    if (city === "") {
      setCityError(true);
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    Axios.put(
      "/api/city/modifyCity/" + params.id,
      {
        CityName: city,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        setTimeout(function () {
          history.push("/citylist");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      {loading && <Loading />}
      <Navbar title="Update City" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingBottom: "10px",
            fontSize: "25px",
          }}
        >
          {/* <h2>Edit Branch</h2> */}
        </div>
        <div className="row mt-3 mb-4 pr-3 pl-3">
          <div className="col-lg-12">
            <div className="card" style={{ backgroundColor: "#F3F3F3" }}>
              <div className="card-body " key={list._id}>
                <div className="row form-group  " style={{ padding: "10px" }}>
                  <div className="col-lg-6">
                    <TextField
                      defaultValue={list.CityName}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="City Name"
                      onChange={(e) => setCity(e.target.value)}
                      error={cityError}
                    />
                  </div>
                </div>

                <div
                  className="float-right pr-3"
                  style={{ paddingTop: "40px" }}
                >
                  {" "}
                  <button
                    onClick={addToList}
                    className="btn btn-primary add-button"
                  >
                    {" "}
                    Update City{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCity;
