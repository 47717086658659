import React, { useEffect, useState } from "react";
import Axios from "axios";
import TextField from "@mui/material/TextField";
import Navbar from "./Navbar";
import { useParams, useHistory } from "react-router-dom";
import Loading from "./Components/Loading";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextEditor from "./Components/TextEditor";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { message, Spin } from "antd";

function SendWhats() {
  const [title, setTitle] = useState("");
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leads, setLeads] = useState([]);
  const [lead, setLead] = useState("");
  const [leadError, setLeadError] = useState(false);

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    document.title = "Send Whatsapp";
  }, []);

  const link1 = "/api/lead/readAllLeads";
  const link2 =
    "/api/lead/showLeadAccToPermission/" + localStorage.getItem("userid");

  let link = "";

  if (localStorage.getItem("role") === "admin") {
    link = link1;
  } else {
    link = link2;
  }

  useEffect(() => {
    Axios.get("/api/template/readOneTemplate/" + params.id).then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 100);
      setRowData(response.data.Templates);
    });

    Axios.get(`${link}`).then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 100);
      setLeads(response.data.lead);
    });
  }, []);

  const [value, setValue] = useState(rowData.TemplateMessage);
  const getValue = (value) => {
    setValue(value);
  };

  const addToList = () => {
    const config = { headers: { "Content-Type": "application/json" } };

    Axios.post(
      "/api/lead/Sendwhatsapp/" + lead + "/" + params.id,
      {
        // SourceName: source,
      },
      config
    )
      .then(function (response) {
        message.success(" Message Sent successfully");
        setTimeout(function () {
          history.push("/mail-template");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const html = rowData.TemplateMessage;

  return (
    <>
      {loading && <Loading />}
      <Navbar title="Send Whatsapp" show="false" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingBottom: "10px",
            fontSize: "25px",
          }}
        >
          {/* <h2>Edit Branch</h2> */}
        </div>
        <div className="row mt-3 mb-4 pr-3 pl-3">
          <div className="col-lg-12">
            <div className="card" style={{ backgroundColor: "#F3F3F3" }}>
              <div className="card-body " key={rowData._id}>
                <div className="row form-group  " style={{ padding: "10px" }}>
                  <div className="col-lg-6">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Lead
                      </InputLabel>
                      <Select
                        defaultValue=""
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Lead"
                        onChange={(e) => setLead(e.target.value)}
                        error={leadError}
                      >
                        {leads &&
                          leads.map((item) => {
                            return (
                              <MenuItem value={item._id}>{item.Name}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-6">
                    <TextField
                      defaultValue={rowData.Title}
                      className="lead-fields"
                      required
                      disabled
                      fullWidth
                      id="outlined-required"
                      label="Title"
                    />
                  </div>
                </div>

                <div className="row   ">
                  {" "}
                  <h4 style={{ paddingLeft: "250px" }}>Content:</h4>{" "}
                  <div className="col-12 mb-2 d-flex justify-content-center">
                    <div className="row pb-3">
                      <div
                        className="col-md-8 lead-fields"
                        style={{ margin: "auto", marginTop: "10px" }}
                      >
                        <TextEditor
                          initialValue={rowData.TemplateMessage}
                          getValue={getValue}
                        />
                        <br />
                        {/* <div>{value}</div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="float-right pr-3"
                  style={{ paddingTop: "40px" }}
                >
                  {" "}
                  <button
                    onClick={addToList}
                    className="btn btn-primary add-button"
                  >
                    {" "}
                    Send Message{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendWhats;
