import React, { useState } from "react";
import classes from "./WhatsappModal.module.css";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import LinkIcon from "@mui/icons-material/Link";
import PhotoIcon from "@mui/icons-material/Photo";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, Icon } from "semantic-ui-react";
import SendIcon from "@mui/icons-material/Send";
const WhatsAppMessageTemplate = (props) => {
  const whatsappTemplates = props.whatsappMessageTemplates;
  const closeModal = props.closeModal;
  const sendpromoformsg = props.sendpromoformsg;
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <p>Select Template</p>
        <span className="pointer">
          <CloseIcon onClick={closeModal} />
        </span>
      </div>
      <Divider />
      <div className={classes.templates}>
        {whatsappTemplates &&
          whatsappTemplates.map((template) => {
            return (
              <div key={template._id} className={classes.content2}>
                <div>
                  <p className={classes.templateTitle}>{template.Title}</p>
                  <span
                    className={classes.description}
                    dangerouslySetInnerHTML={{
                      __html: template.TemplateMessage,
                    }}
                  ></span>
                </div>
                <div
                  className={classes.shareButton}
                  onClick={() => sendpromoformsg(template._id)}
                >
                  <span>
                    <SendIcon />
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

// const WhatsappLinkTemplate = (props) => {
//   const whatsappTemplates = props.whatsappLinkTemplates;
//   const closeModal = props.closeModal;
//   const sendpromoforlink = props.sendpromoforlink;
//   return (
//     <div className={classes.container}>
//       <div className={classes.title}>
//         <p>Select Template</p>
//         <span className="pointer">
//           <CloseIcon onClick={closeModal} />
//         </span>
//       </div>
//       <Divider />
//       <div className={classes.templates}>
//         {whatsappTemplates &&
//           whatsappTemplates.map((template) => {
//             return (
//               <div key={template._id} className={classes.content2}>
//                 <div>
//                   <p className={classes.templateTitle}>{template.Title}</p>
//                   <p className={classes.description}>
//                     {`Description : ${template.Description}`}
//                   </p>
//                   <a
//                     style={{
//                       color: "blue",
//                     }}
//                     href={template.Link}
//                   >
//                     {template.Link}
//                   </a>
//                 </div>
//                 <div
//                   className={classes.shareButton}
//                   onClick={() => sendpromoforlink(template._id)}
//                 >
//                   <span>
//                     <SendIcon />
//                   </span>
//                 </div>
//               </div>
//             );
//           })}
//       </div>
//     </div>
//   );
// };

const WhatsappImageTemplate = (props) => {
  const whatsappTemplates = props.whatsappImageTemplates;
  const closeModal = props.closeModal;
  const sendpromoforimg = props.sendpromoforimg;
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <p>Select Template</p>
        <span className="pointer">
          <CloseIcon onClick={closeModal} />
        </span>
      </div>
      <Divider />
      <div className={classes.templates}>
        {whatsappTemplates &&
          whatsappTemplates.map((template) => {
            return (
              <div key={template._id} className={classes.imageContainer}>
                <div>
                  <img src={template.Image} alt="Unavailable" />
                </div>
                <div>
                  <p className={classes.templateTitle}>{template.Title}</p>
                  <p className={classes.description}>
                    {`Description : ${template.Description}`}
                  </p>
                  <a
                    style={{
                      color: "blue",
                    }}
                    href={template.Link}
                  >
                    {template.Link}
                  </a>
                </div>
                <div
                  className={classes.shareButton}
                  onClick={() => sendpromoforimg(template._id)}
                >
                  <span>
                    <SendIcon />
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const WhatsappDocumentTemplate = (props) => {
  const whatsappTemplates = props.whatsappDocumentTemplates;
  const closeModal = props.closeModal;
  const sendpromofordoc = props.sendpromofordoc;
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <p>Select Template</p>
        <span className="pointer">
          <CloseIcon onClick={closeModal} />
        </span>
      </div>
      <Divider />
      <div className={classes.templates}>
        {whatsappTemplates &&
          whatsappTemplates.map((template) => {
            return (
              <div key={template._id} className={classes.imageContainer}>
                <div style={{ padding: "1rem" }}>
                  <a href={template.Doc}>
                    <Icon name="file pdf" size="large" />
                  </a>
                </div>
                <div>
                  <p className={classes.templateTitle}>{template.Title}</p>
                  <p className={classes.description}>
                    {`Description : ${template.Description}`}
                  </p>
                  <a
                    style={{
                      color: "blue",
                    }}
                    href={template.Link}
                  >
                    {template.Link}
                  </a>
                </div>
                <div
                  className={classes.shareButton}
                  onClick={() => sendpromofordoc(template._id)}
                >
                  <span>
                    <SendIcon />
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const WhatsappModal = (props) => {
  const [templateType, setTemplateType] = useState("none");
  const closeModal = props.forwh;
  const sendpromoformsg = props.sendpromoformsg;
  return templateType === "none" ? (
    <div className={classes.container}>
      <div className={classes.title}>
        <p>Select Type</p>
        <span className="pointer">
          <CloseIcon onClick={props.forwh} />
        </span>
      </div>
      <Divider />
      <div className={classes.content}>
        <p onClick={() => setTemplateType("message")}>
          <span>
            <ChatBubbleOutlineIcon />
          </span>
          Messages
        </p>
        {/* <p onClick={() => setTemplateType("link")}>
          <span>
            <LinkIcon />
          </span>
          Links
        </p> */}
        <p onClick={() => setTemplateType("image")}>
          {" "}
          <span>
            <PhotoIcon />
          </span>
          Images
        </p>
        <p onClick={() => setTemplateType("documents")}>
          {" "}
          <span>
            <PictureAsPdfIcon />
          </span>
          Documents
        </p>
      </div>
    </div>
  ) : templateType === "message" ? (
    <WhatsAppMessageTemplate
      closeModal={closeModal}
      whatsappMessageTemplates={props.whatsappMessageTemplates}
      sendpromoformsg={sendpromoformsg}
    />
  ) : templateType === "image" ? (
    <WhatsappImageTemplate
      closeModal={closeModal}
      sendpromoforimg={props.sendpromoforimg}
      whatsappImageTemplates={props.whatsappImageTemplates}
    />
  ) : (
    <WhatsappDocumentTemplate
      sendpromofordoc={props.sendpromofordoc}
      whatsappDocumentTemplates={props.whatsappDocumentTemplates}
      closeModal={closeModal}
    />
  );
};

export default WhatsappModal;
