import React, { useEffect, useState } from "react";
import Axios from "axios";

import Navbar from "./Navbar";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { message, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { uploadToCloudinary } from "./cloudinary";
import WhatsappRules from "./Components/Promotional/WhatsappRules";

const AddDoc = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = "Add Document";
  }, []);

  const [fileurl, setFileUrl] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [description, setDescription] = useState("");
  const [isLoading, setLoading] = useState(false);
  const handleDocumentUpload = async (file) => {
    setLoading(true);
    try {
      const url = await uploadToCloudinary(file);
      setFileUrl(url);
    } catch (error) {
      message.error("Failed To Upload the file");
    } finally {
      setLoading(false);
    }
  };
  const addToList = () => {
    setTitleError(false);

    if (title === "") {
      setTitleError(true);
      return;
    }

    const config = { headers: { "Content-Type": "application/json" } };

    Axios.post(
      "/api/templateDoc/insertTemplate",
      {
        Title: title,
        Description: description,
        Doc: fileurl,
      },
      config
    )
      .then(function (response) {
        message.success(" Added successfully");
        setTimeout(function () {
          history.push("/doc-template");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Add Document" show="false" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        {isLoading && <Spin size="large" />}
        <div
          className="card"
          style={{
            backgroundColor: "#F3F3F3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: "20px",
              minWidth: "700px",
            }}
          >
            <TextField
              className="lead-fields"
              required
              style={{ width: "100%" }}
              id="outlined-required"
              label="Title"
              onChange={(e) => setTitle(e.target.value)}
              error={titleError}
            />
          </div>
          <div
            style={{
              minWidth: "700px",
              marginBottom: "20px",
            }}
          >
            <input
              style={{ maxWidth: "90%" }}
              type="file"
              required
              onChange={(event) => {
                handleDocumentUpload(event.target.files[0]);
              }}
            />
          </div>
          <div
            style={{
              minWidth: "700px",
            }}
          >
            <TextareaAutosize
              aria-label="minimum height"
              minRows={5}
              placeholder="Content"
              style={{ width: "100%", padding: "10px" }}
              onChange={(e) => setDescription(e.target.value)}
              // error={remarksError}
            />
          </div>

          <div>
            <button onClick={addToList} className="btn btn-primary add-button">
              Add Document Template
            </button>
          </div>
          <WhatsappRules />
        </div>
      </div>
    </>
  );
};

export default AddDoc;
