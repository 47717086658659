import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { message, Spin } from "antd";
import Switch from "@mui/material/Switch";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { Icon } from "semantic-ui-react";

function TeamList() {
  Axios.defaults.withCredentials = true;

  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("ASC");
  const [norder, setNOrder] = useState("ASC");
  const [eorder, setEOrder] = useState("ASC");
  const [unorder, setUnOrder] = useState("ASC");
  const [utorder, setUtOrder] = useState("ASC");
  const [corder, setCOrder] = useState("ASC");
  const [border, setBOrder] = useState("ASC");
  const [porder, setPOrder] = useState("ASC");

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...team].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      if (col === "Name") setNOrder("DSC");
      if (col === "Email") setEOrder("DSC");
      if (col === "UserName") setUnOrder("DSC");
      if (col === "UserType") setUtOrder("DSC");
      if (col === "City") setCOrder("DSC");
      if (col === "Branch") setBOrder("DSC");
      if (col === "Phone") setPOrder("DSC");
      setTeam(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...team].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );

      if (col === "Name") setNOrder("ASC");
      if (col === "Email") setEOrder("ASC");
      if (col === "UserName") setUnOrder("ASC");
      if (col === "UserType") setUtOrder("ASC");
      if (col === "City") setCOrder("ASC");
      if (col === "Branch") setBOrder("ASC");
      if (col === "Phone") setPOrder("ASC");
      setTeam(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    document.title = "Team List";
  }, []);

  useEffect(() => {
    Axios.get("/api/user/readAllTeamMember").then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 100);
      setTeam(response.data.teams);
    });
  }, []);

  let ID = 0;

  // const setID = (id) => {
  //   ID = id;
  // };

  const deleteLead = () => {
    Axios.delete(`/api/user/deleteTeamMember/${ID}`)
      .then(function (response) {
        message.success(" Deleted successfully");
        response &&
          Axios.get("/api/user/readAllTeamMember").then((response) => {
            setTimeout(function () {
              setLoading(false);
            }, 100);
            setTeam(response.data.teams);
          });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  let activeID = 0;

  const setActiveID = (id) => {
    activeID = id;
  };

  const handleChange1 = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put(
      "/api/user/modifyDetail/" + activeID,
      {
        Active: "Inactive",
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        Axios.get("/api/user/readAllTeamMember", config).then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setTeam(response.data.teams);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const handleChange2 = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put(
      "/api/user/modifyDetail/" + activeID,
      {
        Active: "Active",
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        Axios.get("/api/user/readAllTeamMember", config).then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setTeam(response.data.teams);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Team List" />
      <div
        className="container-fluid pt-2"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "50px",
            fontSize: "25px",
          }}
        >
          <h2>
            <div className="text-right">
              {" "}
              <Link to="/addteam">
                <button className="btn  add-button btn-primary">
                  <Icon name="add user"></Icon>
                  Add User
                </button>
              </Link>
            </div>
          </h2>
        </div>
        <div className="row mt-1 mb-4 pr-3 pl-3">
          <div className="col-lg-12">
            <div style={{ backgroundColor: "#F3F3F3" }}>
              <div className="table-full">
                {loading && <Spin size="large" />}
                <table className="table table-bordered table-sm ">
                  <thead>
                    <tr className="bg-secondary text-white table-sel">
                      <td
                        style={{
                          width: "50px",
                        }}
                      >
                        #
                      </td>
                      <td
                        style={{
                          width: "5rem",
                        }}
                      >
                        Avatar
                      </td>
                      <td onClick={() => sorting("Name")}>
                        Name{" "}
                        {norder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                      </td>
                      <td
                        style={{ width: "15rem" }}
                        onClick={() => sorting("Email")}
                      >
                        Email
                        {eorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                      </td>
                      <td onClick={() => sorting("UserName")}>
                        User Name{" "}
                        {unorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                      </td>
                      <td onClick={() => sorting("UserType")}>
                        User Type{" "}
                        {utorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                      </td>
                      <td onClick={() => sorting("City")}>
                        City{" "}
                        {corder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                      </td>
                      <td onClick={() => sorting("Branch")}>
                        Branch{" "}
                        {border === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                      </td>
                      <td onClick={() => sorting("Phone")}>
                        Phone No{" "}
                        {porder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                      </td>
                      <td
                        style={{
                          width: "5rem",
                        }}
                      >
                        Active
                      </td>
                      <td
                        style={{
                          width: "70px",
                        }}
                      >
                        Action
                      </td>
                    </tr>
                  </thead>

                  <tbody className="tbody2">
                    {team &&
                      team.map((val, idx) => {
                        idx = idx + 1;
                        let alter = idx % 2 === 1 ? "#fff" : "#E9F0FF";
                        return (
                          <tr
                            key={idx}
                            className="table-details"
                            style={{ backgroundColor: alter }}
                          >
                            <td
                              style={{
                                width: "50px",
                              }}
                            >
                              {idx}
                            </td>
                            <td
                              style={{
                                width: "5rem",
                              }}
                            >
                              <img
                                src={val.Image}
                                className="img-fluid"
                                style={{
                                  width: "2rem",
                                  height: "2rem",
                                  borderRadius: "50%",
                                }}
                                alt=""
                              />
                            </td>
                            <td>{val.Name}</td>
                            <td style={{ width: "15rem" }}>{val.Email}</td>
                            <td>{val.UserName}</td>
                            <td>{val.UserType}</td>
                            <td>{val.City}</td>
                            <td>
                              {val.Branch.map((name, index) => {
                                return (
                                  <div key={index}>
                                    <span>{name}</span>
                                    <br />
                                  </div>
                                );
                              })}
                            </td>
                            <td>{val.Phone}</td>
                            <td
                              style={{
                                width: "5rem",
                              }}
                            >
                              {val.Active === "Active" ? (
                                <Switch
                                  checked={
                                    val.Active === "Active" ? true : false
                                  }
                                  onClick={() => setActiveID(val._id)}
                                  onChange={() => handleChange1()}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              ) : (
                                <Switch
                                  onClick={() => setActiveID(val._id)}
                                  onChange={() => handleChange2()}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              )}
                            </td>
                            <td
                              style={{
                                width: "70px",
                              }}
                            >
                              {/* <Link to={'/viewlist/' + val._id}>
                                  <i className="fa fa-eye pl-2 pr-2 actionBtn"></i>
                                </Link> */}
                              <Link to={"/editlist/" + val._id}>
                                {" "}
                                <i className="fa fa-edit pl-1 pr-1 actionBtn"></i>
                              </Link>
                              {/* <a
                                  data-toggle="modal"
                                  data-target="#mymodal"
                                  onClick={() => setID(val._id)}
                                >
                                  <i className="fa fa-trash pl-1 pr-1 actionBtn"></i>
                                </a> */}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div
                  className="modal fade"
                  id="mymodal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-md modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Confirmation</h5>
                      </div>
                      <div className="modal-body">
                        Are you sure to delete this user?
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary btn-flat"
                          id="confirm"
                          onClick={() => deleteLead()}
                          data-dismiss="modal"
                        >
                          Continue
                        </button>
                        <button
                          type="button"
                          className="btn  border btn-flat"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamList;
