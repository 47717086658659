import axios from "axios";

export const uploadToCloudinary = async (file) => {
  try {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "leadmanagement");
    data.append("cloud_name", "dkhh3ayz8");

    const res = await axios({
      method: "post",
      url: `https://api.cloudinary.com/v1_1/dkhh3ayz8/auto/upload`,
      withCredentials: false,
      data,
    });

    return res.data.secure_url;
  } catch (error) {
    console.log(error);
    return error;
  }
};
