import React, { useState } from "react";
import Axios from "axios";
import Navbar from "./Navbar";
import TextField from "@mui/material/TextField";
import TextEditor from "./Components/TextEditor";
import { useEffect } from "react";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import WhatsappRules from "./Components/Promotional/WhatsappRules";

const AddWhats = () => {
  useEffect(() => {
    document.title = "Add Whatsapp";
  }, []);

  const history = useHistory();

  const [Title, setTitle] = useState("");
  const [TemplateMessage, setTemplateMessage] = useState();

  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  const addToList = () => {
    const config = { headers: { "Content-Type": "application/json" } };

    Axios.post(
      "/api/template/insertTemplate",
      {
        Title,
        TemplateMessage,
      },
      config
    )
      .then(function (response) {
        message.success(" Added successfully");
        setTimeout(function () {
          history.push("/whatsapp-template");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Add Whatsapp" show="false" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          className="card"
          style={{
            backgroundColor: "#F3F3F3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: "20px",
              minWidth: "700px",
            }}
          >
            <TextField
              className="lead-fields"
              required
              style={{ width: "100%" }}
              id="outlined-required"
              label="Message Title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div
            style={{
              minWidth: "700px",
            }}
          >
            <TextareaAutosize
              aria-label="minimum height"
              minRows={5}
              placeholder="Content"
              style={{ width: "100%", padding: "10px" }}
              onChange={(e) => setTemplateMessage(e.target.value)}
            />
          </div>

          <div>
            <button onClick={addToList} className="btn btn-primary add-button">
              Add Whatsapp
            </button>
          </div>
          <WhatsappRules />
        </div>
      </div>
    </>
  );
};

export default AddWhats;
