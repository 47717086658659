import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyDoJZbGri9G6S8TEQo0FUBvzWqwN3fUemA',
  authDomain: 'toppers-materials.firebaseapp.com',
  projectId: 'toppers-materials',
  storageBucket: 'toppers-materials.appspot.com',
  messagingSenderId: '13245882389',
  appId: '1:13245882389:web:92afa63f6acb21338c6e1c',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const storage = getStorage(app)
