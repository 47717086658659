import React from "react";
import classes from "./WhatsappModal.module.css";
const WhatsappRules = () => {
  return (
    <div style={{ margin: "2rem", textAlign: "center" }}>
      <h3>Whatsapp Rules</h3>
      <table className={classes.table}>
        <thead>
          <th style={{ fontSize: "1.5rem" }}>Sr.</th>
          <th style={{ fontSize: "1.5rem" }}>Code</th>
          <th style={{ fontSize: "1.5rem" }}>Meaning</th>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>{"{{user}}"}</td>
            <td>Name from the Lead</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>{"{{userName}}"}</td>
            <td>Name of the user who is sending the message</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>{"{{userPhone}}"}</td>
            <td>Phone Number of the user who is sending the message</td>
          </tr>
          <tr>
            <td>4.</td>
            <td>{"*text*"}</td>
            <td>To bold the text wrap the text with a asterisk(*)</td>
          </tr>
          <tr>
            <td>5.</td>
            <td>{"_text_"}</td>
            <td>
              To <em>italicize</em> your message, place an underscore on both
              sides of the text:
            </td>
          </tr>
          <tr>
            <td>6.</td>
            <td>{"```text```"}</td>
            <td>
              To <span style={{ fontFamily: "monospace" }}>monospace</span> your
              message, place three backticks on both sides of the text:
            </td>
          </tr>
          <tr>
            <td>7.</td>
            <td>{"~text~"}</td>
            <td>
              To <s>strikethrough</s> your message, place a tilde on both sides
              of the text:
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default WhatsappRules;
