import React, { useState, useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import "antd/dist/antd.css";
import { HashRouter, Redirect, Route } from "react-router-dom";
import Dashboard from "./dashboard";
import Login from "./login";
import Newcustomer from "./newcustomer";
import Sidebar from "./Components/Sidebar/Sidebar";
import Opportunities from "./Opportunities";
import TeamList from "./TeamList";
import AddTeam from "./AddTeam";
import SourceList from "./SourceList";
import AddCourse from "./AddCourse";
import CourseList from "./CourseList";
import Settings from "./Settings";
import ViewOpp from "./ViewOpp";
import EditOpp from "./EditOpp";
import AddSource from "./AddSource";
import EditList from "./EditList";
import EditSource from "./EditSource";
import BranchList from "./BranchList";
import AddBranch from "./AddBranch";
import EditBranch from "./EditBranch";
import EditCourse from "./EditCourse";
import CityList from "./CityList";
import AddCity from "./AddCity";
import EditCity from "./EditCity";
import StatusList from "./StatusList";
import AddStatus from "./AddStatus";
import EditStatus from "./EditStatus";
import Password from "./Password";
import ResetPassword from "./ResetPassword";
import Profile from "./Profile";
import MailList from "./MailList";
import WhatsappTemplate from "./WhatsappTemplate";
import AddWhats from "./AddWhats";
import AddSms from "./AddSms";
import AddMail from "./AddMail";
import SendWhats from "./SendWhats";
import SendMail from "./SendMail";
import MessageTemplate from "./MessageTemplate";
import SendMessage from "./SendMessage";
import ImgList from "./ImgList";
import VideoList from "./VideoList";
import DocList from "./DocList";
import AddImage from "./AddImage";
import AddVideo from "./AddVideo";
import AddDoc from "./AddDoc";
import EditWhats from "./EditWhats";
import EditSms from "./EditSms";
import EditMail from "./EditMail";
import AddLink from "./AddLink";
import ImgTemplate from "./ImgTemplate";
import DocTemplate from "./DocTemplate";
import LinkTemplate from "./LinkTemplate";
import SendImage from "./SendImage";
import SendLink from "./SendLink";
import SendDoc from "./SendDoc";
import EditImage from "./EditImage";
import EditLink from "./EditLink";
import EditDoc from "./EditDoc";
import Axios from "axios";

import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "./features/auth-slice";
import PuffLoader from "react-spinners/PuffLoader";
import RaiseTicket from "./Components/RaiseTicket";
//const Allcustomer = ()=> <h2 className="text-center">All Customer</h2>;
//const Followup = ()=> <h2 className="text-center">Follow up Customer</h2>;

import { useBeforeunload } from 'react-beforeunload';
import { message } from "antd";
import { logout as reduxLogout } from "./features/auth-slice";

function App() {
  const user = useSelector((states) => states.auth);

  // useBeforeunload((event) => {
  //   event.preventDefault();
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   };

  //   Axios.put("/api/user/logout/" + user?.userId, {}, config)
  //     .then(function (response) {
  //       setTimeout(function () {
  //         setLoading(true);
  //       }, 1);
  //       message.success("Logged out successfully");
  //       // window.location.reload();
  //     })
  //     .catch(function (error) {
  //       message.error(`${error.response.data.message}`);
  //     });
  //   dispatch(reduxLogout());

  //   return;
  // });

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      setLoading(true);
      Axios.post("/api/auth/verifyJWT", {}, { withCredentials: true })
        .then((response) => {
          const { _id, Name, Image, UserName, role, Email, Phone } =
            response.data.user;
          dispatch(
            login({
              userId: _id,
              name: Name,
              image: Image,
              username: UserName,
              role: role,
              email: Email,
              phone: Phone,
            })
          );
          setLoading(false);
        })
        .catch(() => {
          dispatch(logout());
          setLoading(false);
        });
    } catch (error) {
      console.log("from Error");
      setLoading(false);
      dispatch(logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LoadingComponent = () => {
    return (
      <React.Fragment>
        <div
          style={{
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "50%",
              width: "100vw",
            }}
          >
            <div
              style={{
                margin: "auto",
                width: "250px",
              }}
            >
              <div style={{ width: "100px", margin: "auto" }}>
                <img
                  style={{
                    width: "100px",
                    margin: "auto",
                    top: "75px",
                    position: "absolute",
                  }}
                  src="/logoxl.jpeg"
                  alt=""
                />
              </div>
              <PuffLoader size={250} speedMultiplier={1.2} color="#004e92" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const linked = window.location.href.split("/");
  const index = linked.length - 2;
  if (linked[index] === "password") {
    return <Password />;
  } else if (linked[index] === "resetpassword") {
    return <ResetPassword />;
  } else {
    return loading ? (
      <LoadingComponent />
    ) : !user.userId ? (
      <Login />
    ) : (
      <>
        <HashRouter>
          <Sidebar>
            <Route exact path="/login">
              {!user.userId ? <Login /> : <Redirect to="/" />}
            </Route>
            <Route exact path="/dashboard">
              <Redirect to="/" />
            </Route>
            <Route exact path="/" component={user.userId ? Dashboard : Login} />
            <Route exact path="/opportunities" component={Opportunities} />
            <Route exact path="/newlead" component={Newcustomer} />
            <Route exact path="/teamlist" component={TeamList} />
            <Route exact path="/addteam" component={AddTeam} />
            <Route exact path="/sourcelist" component={SourceList} />
            <Route exact path="/addsource" component={AddSource} />
            <Route exact path="/addcourse" component={AddCourse} />
            <Route exact path="/courselist" component={CourseList} />
            <Route exact path="/branchlist" component={BranchList} />
            <Route exact path="/citylist" component={CityList} />
            <Route exact path="/addcity" component={AddCity} />
            <Route exact path="/addbranch" component={AddBranch} />
            <Route exact path="/statuslist" component={StatusList} />
            <Route exact path="/addstatus" component={AddStatus} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/viewopp/:id" component={ViewOpp} />
            <Route exact path="/editopp/:id" component={EditOpp} />
            <Route exact path="/editlist/:id" component={EditList} />
            <Route exact path="/editsource/:id" component={EditSource} />
            <Route exact path="/editbranch/:id" component={EditBranch} />
            <Route exact path="/editcourse/:id" component={EditCourse} />
            <Route exact path="/editcity/:id" component={EditCity} />
            <Route exact path="/editstatus/:id" component={EditStatus} />
            <Route exact path="/mail-template" component={MailList} />
            <Route
              exact
              path="/whatsapp-template"
              component={WhatsappTemplate}
            />
            <Route exact path="/addwhatsapp" component={AddWhats} />
            <Route exact path="/addmail" component={AddMail} />
            <Route exact path="/addsms" component={AddSms} />
            <Route exact path="/editwhatsapp/:id" component={EditWhats} />
            <Route exact path="/editmail/:id" component={EditMail} />
            <Route exact path="/editsms/:id" component={EditSms} />
            <Route exact path="/sendwhatsapp/:id" component={SendWhats} />
            <Route exact path="/sendmail/:id" component={SendMail} />
            <Route exact path="/message-template" component={MessageTemplate} />
            <Route exact path="/sendmessage/:id" component={SendMessage} />
            <Route exact path="/whatsapp-img" component={ImgList} />
            <Route exact path="/whatsapp-video" component={VideoList} />
            <Route exact path="/whatsapp-doc" component={DocList} />
            <Route exact path="/addimage" component={AddImage} />
            <Route exact path="/addvideo" component={AddVideo} />
            <Route exact path="/adddoc" component={AddDoc} />
            <Route exact path="/addlink" component={AddLink} />
            <Route exact path="/sendimage" component={SendImage} />
            <Route exact path="/sendlink" component={SendLink} />
            <Route exact path="/senddoc" component={SendDoc} />
            <Route exact path="/editimage/:id" component={EditImage} />
            <Route exact path="/editlink/:id" component={EditLink} />
            <Route exact path="/editdoc/:id" component={EditDoc} />
            <Route exact path="/img-template" component={ImgTemplate} />
            <Route exact path="/doc-template" component={DocTemplate} />
            <Route exact path="/link-template" component={LinkTemplate} />
            <Route exact path="/helpdesk" component={RaiseTicket} />
            <Route
              exact
              path="/resetpassword/:token"
              component={ResetPassword}
            />
            <Route exact path="/password/:token" component={Password} />
          </Sidebar>
        </HashRouter>
      </>
    );
  }
}

export default App;
