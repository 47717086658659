import { NavLink } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineMenuAlt3, HiStatusOnline } from "react-icons/hi";
import { TbLayoutDashboard } from "react-icons/tb";
import { RiUserAddLine } from "react-icons/ri";
import { GrGroup, GrDocumentText } from "react-icons/gr";
import { MdOutlineMoveToInbox, MdOutlineTopic } from "react-icons/md";
// import { FiSettings } from "react-icons/fi";
import { BiMessageAltDetail, BiImageAdd } from "react-icons/bi";
import {
  AiOutlineBranches,
  AiOutlineMail,
  // AiOutlineVideoCameraAdd,
  // AiOutlineLink,
} from "react-icons/ai";
import { BsBuilding, BsChatLeftText } from "react-icons/bs";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import SidebarMenu from "./SidebarMenu";
import logo from "./logo.jpeg";
// import { message } from "antd";
import { useSelector } from "react-redux";

const SideBar = ({ children }) => {
  const user = useSelector((states) => states.auth);
  const routes1 = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <TbLayoutDashboard />,
    },
    {
      path: "/newlead",
      name: "Add Lead",
      icon: <RiUserAddLine />,
    },

    // {
    //   path: "/whatsapp-template",
    //   name: "Whatsapp List",
    //   icon: <FaWhatsapp />,
    // },

    // {
    //   // path: '/lead',
    //   name: 'Whatsapp',
    //   icon: <FaWhatsapp />,
    //   subRoutes: [
    //     {
    //       path: '/whatsapp-template',
    //       name: 'Message',
    //       icon: <BiMessageAltDetail />,
    //     },
    //     {
    //       path: '/whatsapp-img',
    //       name: 'Image',
    //       icon: <BiImageAdd />,
    //     },
    //     {
    //       path: '/whatsapp-video',
    //       name: 'Video',
    //       icon: <AiOutlineVideoCameraAdd />,
    //     },
    //     {
    //       path: '/whatsapp-doc',
    //       name: 'Document',
    //       icon: <GrDocumentText />,
    //     },
    //   ],
    // },

    // {
    //   path: '/mail-template',
    //   name: 'Mail List',
    //   icon: <AiOutlineMail />,
    // },
    // {
    //   path: '/message-template',
    //   name: 'SMS List',
    //   icon: <BsChatLeftText />,
    // },
  ];

  if (user.role === "admin") {
    var routes2 = [
      {
        path: "/teamlist",
        name: "Team List",
        icon: <GrGroup />,
      },
      {
        path: "/sourcelist",
        name: "Source List",
        icon: <MdOutlineMoveToInbox />,
      },
      {
        path: "/courselist",
        name: "Course List",
        icon: <MdOutlineTopic />,
      },

      {
        path: "/branchlist",
        name: "Branch List",
        icon: <AiOutlineBranches />,
      },

      {
        path: "/citylist",
        name: "City List",
        icon: <BsBuilding />,
      },

      {
        path: "/statuslist",
        name: "Status List",
        icon: <HiStatusOnline />,
      },
    ];
  }

  var routes3 = [
    {
      // path: '/lead',
      name: "Whatsapp",
      icon: <FaWhatsapp />,
      subRoutes: [
        {
          path: "/whatsapp-template",
          name: "Message",
          icon: <BiMessageAltDetail />,
        },
        {
          path: "/img-template",
          name: "Image",
          icon: <BiImageAdd />,
        },
        // {
        //   path: "/link-template",
        //   name: "Link",
        //   icon: <AiOutlineLink />,
        // },
        {
          path: "/doc-template",
          name: "Document",
          icon: <GrDocumentText />,
        },
      ],
    },

    {
      path: "/mail-template",
      name: "Mail List",
      icon: <AiOutlineMail />,
    },
    // {
    //   path: "/message-template",
    //   name: "SMS List",
    //   icon: <BsChatLeftText />,
    // },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="containerAdmin">
        <motion.div
          animate={{
            width: isOpen ? "158px" : "60px",

            transition: {
              duration: 0.5,
            },
          }}
          className={`sidebarAdmin `}
        >
          <div className="top_sectionAdmin">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                >
                  <img src={logo} className="img-fluid" alt="" />
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="barsAdmin" style={{ cursor: "pointer" }}>
              <HiOutlineMenuAlt3 onClick={toggle} />
            </div>
          </div>
          <div className="search">
            <AnimatePresence>
              {isOpen && (
                <div
                  className="search_icon d-flex justify-content-center"
                  style={{ fontSize: "30px", paddingBottom: "5px" }}
                >
                  {/* Welcome */}
                </div>
              )}
            </AnimatePresence>
          </div>
          <section className="user-panel">
            {routes1.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    key={index}
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={isOpen}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  className="linkAdmin "
                  activeClassName="active"
                >
                  <div className="iconAdmin">{route.icon}</div>
                  <AnimatePresence>
                    {isOpen && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_textAdmin"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>

          {user.role === "admin" ? (
            <div className="search">
              <AnimatePresence>
                {isOpen && (
                  <div
                    className="search_icon "
                    style={{ fontSize: "18px", padding: "5px " }}
                  >
                    <div className="pt-2 "></div>
                    Maintenance
                  </div>
                )}
              </AnimatePresence>
            </div>
          ) : (
            <div
              className="search mt-6 pb-6"
              style={{ paddingTop: "85px" }}
            ></div>
          )}

          <section className="user-panel">
            {routes2 &&
              routes2.map((route, index) => {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      key={index}
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    />
                  );
                }

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="linkAdmin "
                    activeClassName="active"
                  >
                    <div className="iconAdmin">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_textAdmin"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );
              })}

            {/* <div className="search" style={{ paddingTop: '30px ' }}>
              <AnimatePresence>
                {isOpen && (
                  <div
                    className="search_icon text-center"
                    style={{ fontSize: '20px', padding: '10px ' }}
                  >
                    <button className="btn btn-danger" onClick={logout}>
                      Logout
                    </button>
                  </div>
                )}
              </AnimatePresence>
            </div> */}
          </section>

          {user.role === "admin" ? (
            <>
              <div className="search">
                <AnimatePresence>
                  {isOpen && (
                    <div
                      className="search_icon "
                      style={{ fontSize: "18px", padding: "5px " }}
                    >
                      <div className="pt-1 "></div>
                      Promotional
                    </div>
                  )}
                </AnimatePresence>
              </div>

              <section className="user-panel">
                {routes3.map((route, index) => {
                  if (route.subRoutes) {
                    return (
                      <SidebarMenu
                        key={index}
                        setIsOpen={setIsOpen}
                        route={route}
                        showAnimation={showAnimation}
                        isOpen={isOpen}
                      />
                    );
                  }

                  return (
                    <NavLink
                      to={route.path}
                      key={index}
                      className="linkAdmin "
                      activeClassName="active"
                    >
                      <div className="iconAdmin">{route.icon}</div>
                      <AnimatePresence>
                        {isOpen && (
                          <motion.div
                            variants={showAnimation}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_textAdmin"
                          >
                            {route.name}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </NavLink>
                  );
                })}
              </section>
            </>
          ) : (
            <></>
          )}
        </motion.div>

        <main
          style={{
            paddingLeft: isOpen ? "158px" : "60px",
          }}
        >
          {children}
        </main>
      </div>
    </>
  );
};

export default SideBar;
