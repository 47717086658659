import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { message, Spin } from "antd";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import useTable from "./Components/useTable";
import TableFooter from "./Components/TableFooter";
import Moment from "moment";
import classes from "./Courselist.module.css";

function CourseList() {
  const [course, setCourse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("ASC");
  const [corder, setCOrder] = useState("ASC");
  const [coorder, setCoOrder] = useState("ASC");
  const [vorder, setVOrder] = useState("ASC");

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...course].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );

      if (col === "createdAt") setCOrder("DSC");
      if (col === "CourseValue") setVOrder("DSC");
      if (col === "CourseName") setCoOrder("DSC");
      setCourse(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...course].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );

      if (col === "createdAt") setCOrder("ASC");
      if (col === "CourseValue") setVOrder("ASC");
      if (col === "CourseName") setCoOrder("ASC");
      setCourse(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    document.title = "Course List";
  }, []);

  useEffect(() => {
    Axios.get("/api/course/readallcourse", {
      withCredentials: true,
    }).then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 100);
      setCourse(response.data.courses);
    });
  }, []);

  let ID = 0;

  const setID = (id) => {
    ID = id;
  };

  const deleteLead = (id) => {
    Axios.delete(`/api/course/deleteCourse/${id}`, {
      withCredentials: true,
    })
      .then(function (response) {
        message.success(" Deleted successfully");
        Axios.get("/api/course/readallcourse", {
          withCredentials: true,
        }).then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setCourse(response.data.courses);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title=" Course List" />
      <div
        className="container-fluid pt-2"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "50px",
            fontSize: "25px",
          }}
        >
          <h2>
            <div className="text-right">
              {" "}
              <Link to="/addcourse">
                <a>
                  <button className="btn  add-button btn-primary" to="/">
                    {" "}
                    Add Course{" "}
                  </button>
                </a>
              </Link>
            </div>
          </h2>
        </div>
        <div className="row mt-3 mb-4 pr-3 pl-3">
          <div className="col-lg-12">
            <div className="" style={{ backgroundColor: "#F3F3F3" }}>
              <table className="table table-bordered table-sm ">
                <thead>
                  <tr className="bg-secondary text-white table-sel">
                    <td
                      style={{
                        width: "50px",
                      }}
                    >
                      Id
                    </td>
                    <td
                      onClick={() => sorting("createdAt")}
                      style={{
                        width: "150px",
                      }}
                    >
                      Date Created{" "}
                      {corder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>
                    <td
                      onClick={() => sorting("CourseName")}
                      style={{ width: "5rem" }}
                    >
                      Course Name{" "}
                      {coorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>
                    <td
                      onClick={() => sorting("CourseValue")}
                      style={{
                        width: "200px",
                      }}
                    >
                      Value {vorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                    </td>
                    <td
                      style={{
                        width: "70px",
                      }}
                    >
                      Action
                    </td>
                  </tr>
                </thead>

                {loading && <Spin size="large" />}
                <tbody className="tbody2" id={classes.tbody}>
                  {course &&
                    course.map((val, idx) => {
                      idx = idx + 1;
                      let alter = idx % 2 === 1 ? "#fff" : "#E9F0FF";
                      return (
                        <>
                          <tr
                            className="table-details"
                            style={{ backgroundColor: alter }}
                          >
                            <td
                              style={{
                                width: "50px",
                              }}
                            >
                              {idx}
                            </td>
                            <td
                              style={{
                                width: "150px",
                              }}
                            >
                              {val.createdAt &&
                                val.createdAt
                                  .slice(0, 10)
                                  .split("-")
                                  .reverse()
                                  .join("/")}
                            </td>
                            <td style={{ width: "5rem" }}>{val.CourseName} </td>
                            <td
                              style={{
                                width: "200px",
                              }}
                            >
                              {"₹"}
                              {val.CourseValue &&
                                val.CourseValue.toLocaleString()}
                            </td>
                            <td
                              style={{
                                width: "70px",
                              }}
                            >
                              {/* <Link to={'/viewcourse/' + val._id}>
                                  <i className="fa fa-eye pl-2 pr-2 actionBtn"></i>
                                </Link> */}
                              <Link to={"/editcourse/" + val._id}>
                                {" "}
                                <i className="fa fa-edit pl-2 pr-2 actionBtn"></i>
                              </Link>
                              <a
                                data-toggle="modal"
                                data-target="#mymodal"
                                onClick={() => setID(val._id)}
                              >
                                <i className="fa fa-trash pl-2 pr-2 actionBtn"></i>
                              </a>
                            </td>
                          </tr>

                          <div
                            className="modal fade"
                            id="mymodal"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-md modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">Confirmation</h5>
                                </div>
                                <div className="modal-body">
                                  Are you sure to delete this Information
                                  permanently?
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-flat"
                                    id="confirm"
                                    onClick={() => deleteLead(ID)}
                                    data-dismiss="modal"
                                  >
                                    Continue
                                  </button>
                                  <button
                                    type="button"
                                    className="btn  border btn-flat"
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseList;
