import React, { Fragment, useEffect, useRef, useState } from "react";
import Navbar from "../Navbar";
import classes from "./RaiseTicket.module.css";
import { Button, Form, Input, TextArea } from "semantic-ui-react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../firebase";
import axios from "axios";
import { message } from "antd";

const RaiseTicket = () => {
  const fileInputRef1 = useRef();
  const fileInputRef2 = useRef();

  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [value, setValue] = useState({ issue: "", pageName: "" });
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(false);

  const getIssues = () => {
    axios
      .get("/api/issue/getIssues")
      .then((res) => {
        setIssues(res?.data?.reverse());
      })
      .catch((err) => {
        message.error(err.response.message);
      });
  };

  useEffect(() => {
    getIssues();
  }, []);

  const imgToFirebase = async (imageFile) => {
    const imageRef = ref(storage, `profiles/${imageFile.name + uuidv4()}`);
    const snapshot = await uploadBytes(imageRef, imageFile);
    return getDownloadURL(snapshot.ref);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = async () => {
    try {
      if (value.issue === "")
        return alert("Please Explain The Issue BeforeSsubmit");
      if (value.issue.length < 10)
        return alert("Please Explain Issue in atleast 10 Characters");
      setLoading(true);
      const screenshot1 = image1 ? await imgToFirebase(image1) : "";
      const screenshot2 = image2 ? await imgToFirebase(image2) : "";
      const config = { withCredentials: true };
      const data = { ...value, screenshot1, screenshot2 };
      console.log(data);
      const response = await axios.post(
        "/api/issue/generateIssue",
        data,
        config
      );
      getIssues();
      setValue({ issue: "", pageName: "" });
      setImage1(null);
      setImage2(null);
      message.success(response.data.message);
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <Navbar title="Raise Ticket" show="false"></Navbar>
      <div className={classes.container}>
        <div className={classes.raiseTicket}>
          <div className={classes.header}>
            <h2>Raise Issue</h2>
          </div>
          <div className={classes.forms}>
            <div>
              <Form>
                <span>Issue : </span>
                <TextArea
                  onChange={handleChange}
                  value={value.issue}
                  name="issue"
                  style={{ height: "150", minHeight: 100, maxHeight: 150 }}
                  placeholder="Explain your issue here"
                />
              </Form>
            </div>
            <div>
              <span>Page Name : </span>
              <Input
                onChange={handleChange}
                value={value.pageName}
                name="pageName"
                placeholder="Page Name Where Problem Faced"
                fluid
              />
            </div>
            <div className={classes.screenshots}>
              <p>Screenshots :</p>
              <div>
                <Button
                  content="Choose File"
                  labelPosition="left"
                  icon="file"
                  onClick={() => fileInputRef1.current.click()}
                />
                <span>
                  {image1 ? image1.name.slice(0, 50) : "No file selected"}
                </span>
                <input
                  ref={fileInputRef1}
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => setImage1(e.target.files[0])}
                />
              </div>
              <div>
                <Button
                  content="Choose File"
                  labelPosition="left"
                  icon="file"
                  onClick={() => fileInputRef2.current.click()}
                />
                <span>
                  {image2 ? image2.name.slice(0, 50) : "No file Selected"}
                </span>

                <input
                  ref={fileInputRef2}
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => setImage2(e.target.files[0])}
                />
              </div>
            </div>
            <div className={classes.submitBtn}>
              <Button
                onClick={handleSubmit}
                disabled={loading}
                icon={loading ? "wait" : "share"}
                primary
                content="Submit"
              />
            </div>
          </div>
        </div>
        <div className={classes.ticketHistory}>
          <h2>
            {issues.length > 0 ? "History" : "Your histories will show here"}
          </h2>
          <div className={classes.issueDiv}>
            {issues.map((issue) => {
              return (
                <div
                  className={classes.issueContainer}
                  style={
                    issue.active
                      ? { borderTop: "4px solid red" }
                      : { borderTop: "4px solid green" }
                  }
                >
                  <div>
                    <h6>
                      Issue raised by{" "}
                      <i style={{ color: "red" }}>{issue.raisedBy}</i> :
                    </h6>
                    <span className={classes.issue}>{issue.issue}</span>
                    <span className={classes.date}>
                      {issue.createdAt && issue.createdAt.slice(0, 10)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          {issues.length === 0 && (
            <div className={classes.nothingToShow}>
              <p>Nothing to show yet</p>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default RaiseTicket;
