import React, { useState, useEffect } from "react";
import Axios from "axios";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";
import Moment from "moment";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Navbar from "./Navbar";
import { useHistory, Link } from "react-router-dom";
import Loading from "./Components/Loading";
import { message, Spin } from "antd";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

const Newcustomer = () => {
  Axios.defaults.withCredentials = true;

  const user = useSelector((states) => states.auth);
  const history = useHistory();

  useEffect(() => {
    document.title = "Add Lead";
  }, []);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [enquiry, setEnquiry] = useState("");
  const [enquiryError, setEnquiryError] = useState(false);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState(false);
  const [Address, setAddress] = useState("");
  const [AddressError, setAddressError] = useState(false);
  const [mobile1, setMobile1] = useState(0);
  const [mobile1Error, setMobile1Error] = useState(false);
  const [mobile2, setMobile2] = useState(0);
  // const [mobile2Error, setMobile2Error] = useState(false)
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [followupDate, setFollowupDate] = useState("");
  const [followupDateError, setFollowupDateError] = useState(false);
  const [status, setStatus] = useState("");
  const [statusError, setStatusError] = useState(false);
  const [assignedTo, setAssignedTo] = useState("");
  const [assignedToError, setAssignedToError] = useState(false);
  const [branch, setBranch] = useState("");
  const [branchError, setBranchError] = useState(false);
  const [course, setCourse] = useState("");
  const [courseError, setCourseError] = useState(false);
  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [remarksError, setRemarksError] = useState(false);
  /* eslint-disable*/
  const [fileNames, setFileNames] = useState([]);
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  /* eslint-enable*/

  const [imgurl, setImgurl] = useState(
    "https://thumbs.dreamstime.com/b/print-197235928.jpg"
  );
  const [source, setSource] = useState("");
  const [sourceError, setSourceError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // loader for duplicate lead request
  const [duplicateLoading, setDuplicateLoading] = useState(false);

  const setImageUploadFunction = (file) => {
    setImageUpload(file);
    const imageRef = ref(storage, `images/${file.name + v4()}`);
    uploadBytes(imageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
        setImgurl(url);
      });
    });
  };

  useEffect(() => {
    document.title = "New Lead";
  }, []);

  const addToList = () => {
    setNameError(false);
    setEnquiryError(false);
    setCityError(false);
    setAddressError(false);
    setMobile1Error(false);
    // setMobile2Error(false)
    setEmailError(false);
    setFollowupDateError(false);
    setStatusError(false);
    setAssignedToError(false);
    setBranchError(false);
    setCourseError(false);
    setPriceError(false);
    setSourceError(false);
    setRemarksError(false);

    if (name === "") {
      setNameError(true);
      return;
    }
    if (enquiry === "") {
      setEnquiryError(true);
      return;
    }
    if (city === "") {
      setCityError(true);
      return;
    }

    if (Address === "") {
      setAddressError(true);
    }

    if (mobile1.toString().length !== 10) {
      setMobile1Error(true);
      return;
    }
    // if (mobile2.toString().length !== 10) {
    //   setMobile2Error(true)
    //   return
    // }

    if (
      email !== "" &&
      !email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      message.error("Enter valid email address");
      setEmailError(true);
      return;
    }

    if (followupDate === "") {
      setFollowupDateError(true);
      return;
    }
    if (status === "") {
      setStatusError(true);
      return;
    }

    if (branch === "") {
      setBranchError(true);
      return;
    }
    if (course === "") {
      setCourseError(true);
      return;
    }
    if (price.toString() === "") {
      setPriceError(true);
      return;
    }
    if (source === "") {
      setSourceError(true);
      return;
    }

    if (remarks === "") {
      setRemarksError(true);
      return;
    }

    setLoading(true);

    const data = {
      Name: name,
      Email: email,
      City: city,
      Phone1: mobile1,
      Phone2: mobile2,
      AssignTo:
        assignedTo === "" || assignedTo === "Self" ? user.username : assignedTo,
      EnquiryDate: Moment(enquiry).format("YYYY-MM-DD"),
      Source: source,
      Course: course,
      CoursePrice: price,
      Status: status,
      Attachments: imgurl,
      FollowupDate: Moment(followupDate).format("YYYY-MM-DD"),
      Branch: branch,
      CreatedBy: user.username,
      Remark: remarks,
      Address: Address,
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    Axios.post("/api/lead/insertLead", data, config)
      .then(function (response) {
        message.success(" Added successfully");

        //{
        // window.confirm('Lead Added. Do you want to go to Dashboard?')
        //   ? setTimeout(function () {
        //       history.push('/dashboard')
        //     }, 1000)
        //   : setTimeout(function () {
        //       window.location.reload()
        //     }, 800)
        //}
        setLoading(false);

        setOpen(true);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        message.error(`${error.response.data.message}`);
      });
  };

  const [teamDetail, setTeamDetail] = useState([]);
  const [courseDetail, setCourseDetail] = useState([]);
  const [sourceDetail, setSourceDetail] = useState([]);
  const [branchDetail, setBranchDetail] = useState([]);
  const [cities, setCities] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [leads, setLeads] = useState([]);

  const selectBranch = async (Branch) => {
    setBranch(Branch);

    const res = await Axios.get(`/api/user/teamToBranch?Branch=${Branch}`);

    setTeamDetail(res.data.teams);
  };

  const selectCourse = async (Course) => {
    setCourse(Course);

    const res = await Axios.get(`/api/course/readPrice?CourseName=${Course}`);

    setPrice(res.data.data[0].CourseValue);
  };

  useEffect(() => {
    Axios.get("/api/course/readAllCourse").then((response) => {
      setCourseDetail(response.data.courses);
    });
    Axios.get("/api/source/readAllSource").then((response) => {
      setSourceDetail(response.data.sources);
    });
    Axios.get("/api/branch/readAllBranch").then((response) => {
      setBranchDetail(response.data.branchs);
    });

    Axios.get("/api/city/readAllCity").then((response) => {
      setCities(response.data.citys);
    });

    Axios.get("/api/status/readAllStatus").then((response) => {
      setStatuses(response.data.statuss);
    });
  }, []);

  //  this will assign a token on every request and if two or more req send together only last will give results and rest will be canceled
  let checkDuplicacyController;

  const checkDuplicate = async (ph1, ph2, email) => {
    const phone1 = ph1.toString();
    const phone2 = ph2.toString();
    if (phone1.length < 7 && phone2.length < 7 && email.length < 3) {
      setLeads([]);
    }
    if (phone1.length >= 7 || phone2.length >= 7 || email.length >= 3) {
      if (checkDuplicacyController) {
        checkDuplicacyController.abort("Previous req cancelled due to new req");
        console.log("previous cancelled");
      }
      checkDuplicacyController = new AbortController();
      try {
        setDuplicateLoading(true);
        const response = await Axios.post(
          "/api/lead/checkDuplicate",
          { phone1, phone2, email },
          { signal: checkDuplicacyController.signal }
        );
        setLeads(response.data);
        setDuplicateLoading(false);
      } catch (error) {
        console.log(error);
        setDuplicateLoading(false);
      }
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      {loading && <Loading />}
      <Navbar title="New Lead" />
      <div
        className="container-fluid pt-1"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you want to go to Dashboard?
            </Typography>
            <div className="mt-3 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary btn-flat"
                id="confirm"
                data-dismiss="modal"
                onClick={() => history.push("/dashboard")}
              >
                Continue
              </button>
              <button
                type="button"
                className="btn  border btn-flat"
                onClick={() => window.location.reload()}
              >
                Close
              </button>
            </div>
          </Box>
        </Modal>
        <div
          style={{
            paddingLeft: "20px",
            paddingBottom: "5px",
            fontSize: "25px",
          }}
        >
          <div className="float-right pr-3">
            {" "}
            <button
              disabled={loading}
              onClick={addToList}
              className="btn btn-primary add-button"
            >
              {" "}
              Add Lead{" "}
            </button>
          </div>
        </div>
        <div className="row mt-1 mb-4 pl-3">
          <div className="col-lg-12">
            <div className="card" style={{ backgroundColor: "#F3F3F3" }}>
              <div className="card-body">
                <div className="row form-group  " style={{ padding: "5px" }}>
                  <div className="col-lg-3">
                    <TextField
                      value={user.username}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Owner"
                      disabled
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter Name"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      error={nameError}
                    />
                  </div>

                  <div className="col-lg-3">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Enquiry Date"
                        inputFormat="dd/MM/yyyy"
                        id="outlined-required"
                        value={enquiry || null}
                        onChange={(newValue) => {
                          setEnquiry(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="lead-fields"
                            required
                            fullWidth
                            {...params}
                            error={enquiryError}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Enquiry Course
                      </InputLabel>
                      <Select
                        defaultValue=""
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Enquiry Course"
                        onChange={(e) => {
                          selectCourse(e.target.value);
                        }}
                        error={courseError}
                      >
                        {courseDetail &&
                          courseDetail.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.CourseName}>
                                {item.CourseName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="row form-group " style={{ padding: "5px" }}>
                  <div className="col-lg-3">
                    <TextField
                      className="lead-fields"
                      value={price}
                      required
                      fullWidth
                      type="number"
                      id="outlined-required"
                      label="Course Price"
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 6);
                      }}
                      error={priceError}
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      className="lead-fields"
                      required
                      fullWidth
                      type="number"
                      id="outlined-required"
                      label="Enter Phone no 1"
                      onChange={(e) => {
                        setMobile1(e.target.value);
                        checkDuplicate(
                          e.target.value,
                          mobile2.toString(),
                          email
                        );
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      error={mobile1Error}
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      className="lead-fields"
                      fullWidth
                      type="number"
                      id="outlined-required"
                      label="Enter Phone no 2"
                      onChange={(e) => {
                        setMobile2(e.target.value);
                        checkDuplicate(
                          mobile1.toString(),
                          e.target.value,
                          email
                        );
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      className="lead-fields"
                      fullWidth
                      id="outlined-required"
                      label="Enter Email id"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        checkDuplicate(
                          mobile1.toString(),
                          mobile2.toString(),
                          e.target.value
                        );
                      }}
                      error={emailError}
                      value={email}
                    />
                  </div>
                </div>

                <div className="row form-group " style={{ padding: "5px" }}>
                  <div className="col-lg-3">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      className="lead-fields"
                    >
                      <DatePicker
                        label="Followup Date"
                        inputFormat="dd/MM/yyyy"
                        value={followupDate || null}
                        onChange={(newValue) => {
                          setFollowupDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="lead-fields"
                            required
                            fullWidth
                            {...params}
                            error={followupDateError}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        defaultValue=""
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status"
                        onChange={(e) => setStatus(e.target.value)}
                        error={statusError}
                      >
                        {statuses &&
                          statuses.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.StatusName}>
                                {item.StatusName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Branch
                      </InputLabel>
                      <Select
                        defaultValue=""
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Branch"
                        onChange={(e) => {
                          selectBranch(e.target.value);
                        }}
                        error={branchError}
                      >
                        {branchDetail &&
                          branchDetail.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.BranchName}>
                                {item.BranchName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    {/* <TextField
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter City"
                      onChange={(e) => setCity(e.target.value)}
                      error={cityError}
                    /> */}

                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        City
                      </InputLabel>
                      <Select
                        defaultValue=""
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="City"
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        error={cityError}
                      >
                        {cities &&
                          cities.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.CityName}>
                                {item.CityName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="row form-group " style={{ padding: "5px" }}>
                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Source
                      </InputLabel>
                      <Select
                        defaultValue=""
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Source"
                        onChange={(e) => {
                          setSource(e.target.value);
                        }}
                        error={sourceError}
                      >
                        {sourceDetail &&
                          sourceDetail.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.SourceName}>
                                {item.SourceName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Assigned to
                      </InputLabel>
                      <Select
                        defaultValue="Self"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Assigned to"
                        onChange={(e) => {
                          setAssignedTo(e.target.value);
                        }}
                        error={assignedToError}
                      >
                        {teamDetail.length === 0 ? (
                          <MenuItem value="">
                            No list in current location
                          </MenuItem>
                        ) : (
                          [{ UserName: "Self" }, ...teamDetail].map(
                            (item, index) => {
                              return (
                                <MenuItem key={index} value={item.UserName}>
                                  {item.UserName}
                                </MenuItem>
                              );
                            }
                          )
                        )}
                        {/* {!teamDetail && (
                         
                        )}
                        {teamDetail &&
                          teamDetail.map((item) => {
                            return (
                              <MenuItem value={item.UserName}>
                                {item.UserName}
                              </MenuItem>
                            )
                          })} */}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter Location"
                      onChange={(e) => setAddress(e.target.value)}
                      error={AddressError}
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextareaAutosize
                      className="lead-fields"
                      minRows={2}
                      style={
                        remarksError
                          ? { width: "100%", border: "1px solid red" }
                          : { width: "100%" }
                      }
                      aria-label="maximum height"
                      placeholder="Remarks"
                      onChange={(e) => setRemarks(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row form-group " style={{ padding: "5px" }}>
                  <div className="col-lg-3 " style={{ cursor: "pointer" }}>
                    <div className="dropzone lead-fields pt-1 pb-3 pl-3">
                      <input
                        style={{ maxWidth: "90%" }}
                        type="file"
                        required
                        accept="application/pdf, image/*"
                        onChange={(event) => {
                          setImageUploadFunction(event.target.files[0]);
                        }}
                      />
                    </div>
                    <div>
                      <ul>
                        {fileNames.map((fileName) => (
                          <li key={fileName}>{fileName}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                {/* <div className="float-right pr-3">
                  {' '}
                  <button
                    onClick={addToList}
                    className="btn btn-primary add-button"
                  >
                    {' '}
                    Add Lead{' '}
                  </button>
                </div> */}

                <div className="">
                  <table className="table table-bordered table-sm ">
                    <thead>
                      <tr className="bg-secondary text-white table-sel">
                        <td
                          style={{
                            width: "40px",
                          }}
                        >
                          #
                        </td>

                        <td
                          style={{
                            width: "70px",
                          }}
                        >
                          Ass. To{" "}
                        </td>
                        <td
                          style={{
                            width: "100px",
                          }}
                        >
                          Source{" "}
                        </td>
                        <td
                          style={{
                            width: "100px",
                          }}
                        >
                          City
                        </td>
                        <td
                          style={{
                            width: "120px",
                          }}
                        >
                          Name
                        </td>
                        <td
                          style={{
                            width: "90px",
                          }}
                        >
                          Mob_1{" "}
                        </td>
                        <td
                          style={{
                            width: "90px",
                          }}
                        >
                          Mob_2{" "}
                        </td>
                        <td
                          style={{
                            width: "auto",
                          }}
                        >
                          Email
                        </td>
                        <td
                          style={{
                            width: "200px",
                          }}
                        >
                          Course
                        </td>
                        <td
                          style={{
                            width: "100px",
                          }}
                        >
                          Price
                        </td>
                        <td
                          style={{
                            width: "40px",
                          }}
                        >
                          Days
                        </td>

                        <td
                          style={{
                            width: "70px",
                          }}
                        >
                          Followup{" "}
                        </td>
                      </tr>
                    </thead>
                    {duplicateLoading ? (
                      <div style={{ display: "flex" }}>
                        <BeatLoader
                          color="#36d7b7"
                          size={15}
                          cssOverride={{ margin: "auto" }}
                        />
                      </div>
                    ) : (
                      <tbody className="tBody1">
                        {leads.length > 0 &&
                          leads.map((val, idx) => {
                            idx = idx + 1;
                            let color =
                              val.Repeat === true ? "#bf2121" : "black";
                            let alter = idx % 2 === 1 ? "#fff" : "#E9F0FF";
                            console.log(val.CoursePrice);
                            return (
                              <>
                                <tr
                                  className="table-details"
                                  style={{ backgroundColor: alter }}
                                >
                                  <td
                                    style={{
                                      color: color,
                                      width: "40px",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {idx}
                                  </td>
                                  <td
                                    style={{
                                      color: color,
                                      width: "70px",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {val.AssignTo}
                                  </td>
                                  <td
                                    style={{
                                      color: color,
                                      width: "100px",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {val.Source}
                                  </td>
                                  <td
                                    style={{
                                      color: color,
                                      width: "100px",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {val.City}
                                  </td>
                                  <td
                                    style={{
                                      color: color,
                                      width: "120px",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {val.Name}
                                  </td>
                                  <td
                                    style={{
                                      color: color,
                                      width: "90px",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {val.Phone1}
                                  </td>
                                  <td
                                    style={{
                                      color: color,
                                      width: "90px",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {val.Phone2 !== "0" ? (
                                      <>{val.Phone2}</>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      color: color,
                                      width: "auto",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {val.Email}
                                  </td>
                                  <td
                                    style={{
                                      color: color,
                                      width: "200px",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {val.Course}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: color,
                                      width: "100px",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {`₹ ${val.CoursePrice}`}
                                  </td>
                                  <td
                                    style={{
                                      color: color,
                                      width: "40px",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {val.Days}
                                  </td>

                                  <td
                                    style={{
                                      color: color,
                                      width: "70px",
                                      fontFamily: "Prox",
                                    }}
                                  >
                                    {val.FollowupDate &&
                                      val.FollowupDate.slice(0, 10)
                                        .split("-")
                                        .reverse()
                                        .join("/")}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newcustomer;
