import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      const { userId, name, image, username, role, email, phone } =
        action.payload;
      if (userId) state.userId = userId;
      if (name) state.name = name;
      if (image) state.image = image;
      if (username) state.username = username;
      if (role) state.role = role;
      if (email) state.email = email;
      if (phone) state.phone = phone;
    },

    logout: (state) => {
      state = "";
      sessionStorage.clear();
      localStorage.clear();
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
