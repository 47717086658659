import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import TextField from "@mui/material/TextField";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";

const AddVideo = () => {
  useEffect(() => {
    document.title = "Add Video";
  }, []);

  // const [imageUpload, setImageUpload] = useState(null)
  // const [imageUrls, setImageUrls] = useState([])
  // const [imgurl, setImgurl] = useState('')
  // eslint-disable-next-line
  const [fileNames, setFileNames] = useState([]);

  const setImageUploadFunction = (file) => {
    // setImageUpload(file)
    const imageRef = ref(storage, `images/${file.name + v4()}`);
    uploadBytes(imageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        // setImageUrls((prev) => [...prev, url])
        // setImgurl(url)
      });
    });
  };

  return (
    <>
      <Navbar title="Add Video" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingBottom: "10px",
            fontSize: "25px",
          }}
        >
          {/* <h2>Add Branch</h2> */}
        </div>
        <div className="row mt-3 mb-2 pr-3 pl-3">
          <div className="col-lg-12">
            <div className="card" style={{ backgroundColor: "#F3F3F3" }}>
              <div className="card-body">
                <div className="row form-group  " style={{ padding: "10px" }}>
                  <div className="col-lg-6">
                    <TextField
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Video Title"
                    />
                  </div>

                  <div className="col-lg-6">
                    <div className="dropzone lead-fields pt-1 pb-3 pl-3">
                      <input
                        style={{ maxWidth: "90%" }}
                        type="file"
                        accept="image/*"
                        required
                        onChange={(event) => {
                          setImageUploadFunction(event.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <ul>
                      {fileNames.map((fileName) => (
                        <li key={fileName}>{fileName}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="float-right pr-3">
                  {" "}
                  <button className="btn btn-primary add-button">
                    {" "}
                    Add Video{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVideo;
