import React, { useEffect, useState } from "react";
import Axios from "axios";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";
import TextField from "@mui/material/TextField";
import Navbar from "./Navbar";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { useSelector } from "react-redux";

function Settings() {
  Axios.defaults.withCredentials = true;

  const user = useSelector((states) => states.auth);
  const history = useHistory();

  const [name, setName] = useState(0);
  // const [nameError, setNameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [imgurl, setImgurl] = useState(user.image);
  const [loading, setLoading] = useState(false);

  const setImageUploadFunction = (file) => {
    setImageUpload(file);
    const imageRef = ref(storage, `images/${file.name + v4()}`);
    uploadBytes(imageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
        setImgurl(url);
      });
    });
  };

  useEffect(() => {
    document.title = "Settings";
  }, []);

  const addToList = () => {
    setPasswordError(false);

    if (password === "") {
      setPasswordError(true);
      return;
    }
    const id = user.userId;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put(
      `/api/user/modifyDetail/${id}`,
      {
        Image: imgurl,
        Password: password,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        localStorage.setItem("image", imgurl);
        setTimeout(function () {
          history.push("/dashboard");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Settings Update" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingBottom: "10px",
            fontSize: "25px",
          }}
        >
          {/* <h2>Settings Update</h2> */}
        </div>
        <div className="row mt-3 mb-4 pr-3 pl-3">
          <div className="col-lg-12">
            <div className="card" style={{ backgroundColor: "#F3F3F3" }}>
              <div className="card-body">
                <div className="row form-group  " style={{ padding: "10px" }}>
                  <div className="col-lg-6">
                    <TextField
                      defaultValue={user.name}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter Name"
                      onChange={(e) => setName(e.target.value)}
                      disabled
                    />
                  </div>

                  {/* <div className="col-lg-6">
                    <TextField
                      defaultValue={user.username}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="User Name"
                      onChange={(e) => setUsername(e.target.value)}
                      error={userNameError}
                    />
                  </div> */}

                  <div className="col-lg-6">
                    <TextField
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter Password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      error={passwordError}
                    />
                  </div>
                </div>

                <div className="row form-group  " style={{ padding: "10px" }}>
                  <div className="col-lg-6 " style={{ cursor: "pointer" }}>
                    <div className="dropzone lead-fields pt-1 pb-3 pl-3">
                      <input
                        style={{ maxWidth: "90%" }}
                        type="file"
                        accept="image/*"
                        onChange={(event) => {
                          setImageUploadFunction(event.target.files[0]);
                        }}
                      />
                    </div>
                    <div>
                      <ul>
                        {fileNames.map((fileName) => (
                          <li key={fileName}>{fileName}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="float-right pr-3">
                  {" "}
                  <button
                    onClick={addToList}
                    className="btn btn-primary add-button"
                  >
                    {" "}
                    Update Profile{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
