import React, { useEffect, useState } from "react";
import Axios from "axios";
import Navbar from "./Navbar";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { message, Spin } from "antd";
import { useHistory } from "react-router-dom";
import { uploadToCloudinary } from "./cloudinary";
import WhatsappRules from "./Components/Promotional/WhatsappRules";

const AddImage = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = "Add Image";
  }, []);

  const [imgurl, setImgurl] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [description, setDescription] = useState("");

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const url = await uploadToCloudinary(e.target.files[0]);
        setImgurl(url);
      } catch (error) {
        message.error("Failed To Upload the file");
      }
    }
  };

  const addToList = () => {
    setTitleError(false);

    if (title === "") {
      setTitleError(true);
      return;
    }

    const config = { headers: { "Content-Type": "application/json" } };

    Axios.post(
      "/api/templateImage/insertTemplate",
      {
        Title: title,
        Description: description,
        Image: imgurl,
      },
      config
    )
      .then(function (response) {
        message.success(" Added successfully");
        setTimeout(function () {
          history.push("/img-template");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Add Image" show="false" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          className="card"
          style={{
            backgroundColor: "#F3F3F3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: "20px",
              marginTop: "20px",
              minWidth: "700px",
            }}
          >
            <TextField
              className="lead-fields"
              required
              style={{ width: "100%" }}
              id="outlined-required"
              label="Title"
              onChange={(e) => setTitle(e.target.value)}
              error={titleError}
            />
          </div>

          <div
            style={{
              marginBottom: "20px",
              minWidth: "700px",
            }}
          >
            <input
              style={{ maxWidth: "90%" }}
              type="file"
              accept="image/*"
              required
              onChange={(event) => {
                imageChange(event);
              }}
            />
          </div>
          <div
            style={{
              minWidth: "700px",
            }}
          >
            <TextareaAutosize
              aria-label="minimum height"
              minRows={5}
              style={{ width: "100%", padding: "10px" }}
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div
            style={{
              margin: "20px 0px",
            }}
            className="col-lg-2"
          >
            {imgurl && (
              <div
                className="col-lg-12 pt-2 pb-2"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <div className=" d-flex justify-content-center" style={{}}>
                  <img
                    src={imgurl}
                    style={{
                      display: "block",
                      height: "200px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    className=" img-fluid"
                    alt="Thumb"
                  />
                </div>
              </div>
            )}
          </div>

          <div>
            <button onClick={addToList} className="btn btn-primary add-button">
              Add Image Template
            </button>
          </div>
          <WhatsappRules />
        </div>
      </div>
    </>
  );
};

export default AddImage;
