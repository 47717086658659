import React from "react";
import classes from "./WhatsappModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "semantic-ui-react";
import SendIcon from "@mui/icons-material/Send";

const EmailModal = (props) => {
  const mailTemplates = props.mailTemplates;
  const closeModal = props.formail;
  const sendpromoformail = props.sendpromoformail;
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <p>Select Template</p>
        <span className="pointer">
          <CloseIcon onClick={closeModal} />
        </span>
      </div>
      <Divider />
      <div className={classes.templates}>
        {mailTemplates &&
          mailTemplates.map((template) => {
            return (
              <div key={template._id} className={classes.content2}>
                <div>
                  <p className={classes.templateTitle}>{template.Title}</p>
                  <span
                    className={classes.description}
                    dangerouslySetInnerHTML={{
                      __html: template.TemplateMessage,
                    }}
                  ></span>
                </div>
                <div
                  className={classes.shareButton}
                  onClick={() => sendpromoformail(template._id)}
                >
                  <span>
                    <SendIcon />
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EmailModal;
