import React from "react";
import classes from "./WhatsappModal.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "semantic-ui-react";
import SendIcon from "@mui/icons-material/Send";

const SMSModal = (props) => {
  const smsTemplates = props.smsTemplates;
  const closeModal = props.forsms;
  const sendpromoforsms = props.sendpromoforsms;
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <p>Select Template</p>
        <span className="pointer">
          <CloseIcon onClick={closeModal} />
        </span>
      </div>
      <Divider />
      <div className={classes.templates}>
        {smsTemplates &&
          smsTemplates.map((template) => {
            return (
              <div key={template._id} className={classes.content2}>
                <div>
                  <p className={classes.templateTitle}>{template.Title}</p>
                  <span className={classes.description}>
                    {template.TemplateMessage}
                  </span>
                </div>
                <div
                  className={classes.shareButton}
                  onClick={() => sendpromoforsms(template._id)}
                >
                  <span>
                    <SendIcon />
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SMSModal;
