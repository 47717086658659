import React, { useState } from "react";
import Axios from "axios";
import login from "../src/assets/login.png";
import { message, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";

function Password() {
  Axios.defaults.withCredentials = true;

  const linked = window.location.href.split("/");
  const index = linked.length - 1;
  const token = linked[index];
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const addPassword = () => {
    setLoading(true);
    const config = { headers: { "Content-Type": "application/json" } };

    Axios.put(
      "/api/user/setPassword/" + token,
      {
        Password: password,
        ConfirmPassword: confirmPassword,
      },
      config
    )
      .then(function (response) {
        message.success(" Added successfully");
        setTimeout(function () {
          window.location.href = "http://35.91.189.147/#/login";
        }, 1000);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="form-login-body">
          <div className="containerLogin d-flex justify-content-center">
            <div className="row">
              <div className="col-lg-10 mx-auto login-desk">
                <div className="row" style={{ borderRadius: "20px" }}>
                  <div className="col-7 detail-box">
                    {/* <img className="logo" src="assets/images/logo.png" alt="">  */}
                    <div className="detailsh">
                      <img className="help img-fluid" src={login} alt="" />
                      <div className="img-fluid">
                        <h4>"Don't Optimize for conversations,</h4>
                      </div>
                      <h4>optimize for revenue"</h4>
                    </div>
                  </div>
                  <div className="col-5 pt-3 loginform ">
                    <div className="">
                      <div className="welcome">
                        <h3>Welcome !!</h3>
                      </div>

                      <div className="login-det">
                        <div className="form-row">
                          <label htmlFor="">Password</label>
                          <div className="input-group mb-3">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Enter Password"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="form-row">
                          <label htmlFor="">Confirm Password</label>
                          <div className="input-group mb-3">
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Enter Password"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <button
                        className="btn btn-sm btn-login"
                        onClick={addPassword}
                      >
                        Set password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Password;
