import React, { useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { message } from "antd";
import { FiLogOut, FiSettings, FiHelpCircle } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Spin } from "antd";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IconContext } from "react-icons";
import { useSelector, useDispatch } from "react-redux";
import { logout as reduxLogout } from "./features/auth-slice";
const Navbar = (props) => {
  Axios.defaults.withCredentials = true;
  const dispatch = useDispatch();
  const user = useSelector((states) => states.auth);
  const [openM, setOpenM] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put("/api/user/logout/" + user.userId, {}, config)
      .then(function (response) {
        setTimeout(function () {
          setLoading(true);
        }, 1);
        message.success("Logged out successfully");
        window.location.reload();
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
    dispatch(reduxLogout());
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <nav className="header navbar navbar-expand-lg text-right header-fixed">
        <div className="navbar-brand ml-2">
          <strong style={{ color: "#fff" }}>{props.title}</strong>
          {props.btn ? (
            <Link to="/dashboard">
              <div className="pointer">
                <button className="btn add-button btn-primary" to="/">
                  {" "}
                  Back{" "}
                </button>
              </div>
            </Link>
          ) : (
            <></>
          )}
        </div>

        <Modal
          open={openM}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you want to go to Dashboard?
            </Typography>
            <div className="mt-3 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary btn-flat"
                id="confirm"
                data-dismiss="modal"
              >
                Continue
              </button>
              <button type="button" className="btn  border btn-flat">
                Close
              </button>
            </div>
          </Box>
        </Modal>

        <div className="nav navbar-nav navbar-right col-5">
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <h2>
                <div className="text-right">
                  {props.show !== "false" && (
                    <Link to="/newlead">
                      <button className="btn  add-button btn-primary" to="/">
                        {" "}
                        Add Lead{" "}
                      </button>
                    </Link>
                  )}
                </div>
              </h2>
            </div>
            <div>
              <img
                src={
                  user.image
                    ? user.image
                    : "https://i.pinimg.com/736x/64/81/22/6481225432795d8cdf48f0f85800cf66.jpg"
                }
                style={{
                  width: "32",
                  height: "32",
                  borderRadius: "50%",
                  border: "1px solid white",
                }}
                className="user_img "
                alt=""
              />

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Link to="/profile">
                  <MenuItem>
                    <div className="pr-1" style={{ display: "inline-block" }}>
                      <AiOutlineUser />
                    </div>{" "}
                    Profile
                  </MenuItem>
                </Link>
                {user.role === "admin" && (
                  <Link to="/settings">
                    <MenuItem>
                      <div className="pr-1" style={{ display: "inline-block" }}>
                        <FiSettings />
                      </div>
                      Settings
                    </MenuItem>
                  </Link>
                )}
                <Link to="/helpdesk">
                  <MenuItem>
                    <div className="pr-1" style={{ display: "inline-block" }}>
                      <FiHelpCircle />
                    </div>
                    Support
                  </MenuItem>
                </Link>
                {loading && <Spin size="large" />}
                <MenuItem onClick={logout}>
                  <div className="pr-1" style={{ display: "inline-block" }}>
                    <FiLogOut />
                  </div>{" "}
                  Logout
                </MenuItem>
              </Menu>
            </div>
            <div
              style={{
                fontWeight: "800",
              }}
            >
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                style={{ color: "rgb(244 255 0)" }}
              >
                {user.username}
                <IconContext.Provider
                  value={{ color: "rgb(244 255 0)", size: "20px" }}
                >
                  <RiArrowDropDownLine />
                </IconContext.Provider>
              </Button>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
