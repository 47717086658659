/* eslint-disable*/
import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";
import Dropzone from "react-dropzone";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Navbar from "./Navbar";
import { useParams, useHistory } from "react-router-dom";
import Loading from "./Components/Loading";
import { message, Spin } from "antd";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const EditList = () => {
  Axios.defaults.withCredentials = true;
  const user = useSelector((states) => states.auth);

  const params = useParams();
  const history = useHistory();

  const [team, setTeam] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    document.title = "Update User";
  }, []);

  useEffect(() => {
    Axios.get("/api/user/readTeamMember/" + params.id).then((response) => {
      setTeam(response.data.team);
      setPersonName(response.data.team.Permission);
    });

    Axios.get("/api/city/readAllCity").then((response) => {
      setCities(response.data.citys);
    });

    Axios.get("/api/user/readAllTeamMember").then((response) => {
      setNames(response.data.teams);
    });
  }, []);

  const [name, setName] = useState(team.Name);
  const [nameError, setNameError] = useState(false);
  const [macAddress, setMacAddress] = useState(team.MacAddress);
  const [macAddressError, setMacAddressError] = useState(false);
  const [city, setCity] = useState(team.City);
  const [cityError, setCityError] = useState(false);
  const [username, setUsername] = useState(team.UserName);
  const [usernameError, setUsernameError] = useState(false);
  const [mobile, setMobile] = useState(team.Phone);
  const [mobileError, setMobileError] = useState(false);
  const [email, setEmail] = useState(team.Email);
  const [emailError, setEmailError] = useState(false);
  const [profile, setProfile] = useState(team.UserType);
  const [profileError, setProfileError] = useState(false);
  const [branch, setBranch] = useState(team.Branch);
  const [branchError, setBranchError] = useState(false);
  const [active, setActive] = useState(team.Active);
  const [fileNames, setFileNames] = useState([]);
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [imgurl, setImgurl] = useState(team.Image);
  const [loading, setLoading] = useState(false);

  const setImageUploadFunction = (file) => {
    setImageUpload(file);
    const imageRef = ref(storage, `images/${file.name + v4()}`);
    uploadBytes(imageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
        setImgurl(url);
      });
    });
  };

  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [names, setNames] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const addToList = () => {
    setNameError(false);
    setMacAddressError(false);
    setCityError(false);
    setUsernameError(false);
    setMobileError(false);
    setBranchError(false);
    setEmailError(false);
    setProfileError(false);

    if (name === "") {
      setNameError(true);
      return;
    }

    if (city === "") {
      setCityError(true);
      return;
    }

    if (username === "") {
      setUsernameError(true);
      return;
    }

    if (mobile) {
      let newnum = mobile;
      for (var i = 1; i <= 10; i++) {
        newnum = Math.trunc(newnum / 10);
      }

      if (newnum !== 0) {
        setMobileError(true);
        return;
      }
    }

    if (profile === "") {
      setProfileError(true);
      return;
    }

    if (email) {
      if (
        !email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        setEmailError(true);
        return;
      }
    }

    if (branch === "") {
      setBranchError(true);
      return;
    }

    if (personName.includes(user.username)) {
      personName = personName.filter((e) => e !== user.username);
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put(
      "/api/user/modifyDetail/" + params.id,
      {
        Email: email,
        Name: name,
        UserName: username,
        UserType: profile,
        Phone: mobile,
        Image: imgurl,
        City: city,
        MacAddress: macAddress,
        Branch: branch,
        Permission: personName,
        Active: active,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        setTimeout(function () {
          history.push("/teamlist");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const [branchDetail, setBranchDetail] = useState([]);

  useEffect(() => {
    Axios.get("/api/branch/readAllBranch").then((response) => {
      setBranchDetail(response.data.branchs);
    });
  }, []);

  const handleDrop = (acceptedFiles) => {
    setFileNames(acceptedFiles?.map((file) => file.name));
    setImageUpload(acceptedFiles.target.files[0]);
  };

  return (
    <>
      {loading && <Loading />}
      <Navbar title="Update User" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingBottom: "5px",
            fontSize: "25px",
          }}
        >
          <div className="float-right pr-3">
            {" "}
            <button onClick={addToList} className="btn btn-primary add-button">
              {" "}
              Update Team{" "}
            </button>
          </div>
        </div>
        <div className="row mt-1 mb-4 pl-3">
          <div className="col-lg-12">
            <div className="card" style={{ backgroundColor: "#F3F3F3" }}>
              <div className="card-body" key={team._id}>
                <div className="row form-group  " style={{ padding: "5px" }}>
                  <div className="col-lg-3">
                    <TextField
                      defaultValue={team.Name}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter Name"
                      onChange={(e) => setName(e.target.value)}
                      error={nameError}
                    />
                  </div>

                  {/* <div className="col-lg-3">
                    <TextField
                      defaultValue={team.MacAddress}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter MacAddress"
                      onChange={(e) => setMacAddress(e.target.value)}
                      error={macAddressError}
                    />
                  </div> */}

                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        City
                      </InputLabel>
                      <Select
                        defaultValue={team.City}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="City"
                        onChange={(e) => setCity(e.target.value)}
                        error={cityError}
                      >
                        {cities &&
                          cities.map((item) => {
                            return (
                              <MenuItem value={item.CityName}>
                                {item.CityName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      defaultValue={team.UserName}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter unique username"
                      onChange={(e) => setUsername(e.target.value)}
                      error={usernameError}
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      defaultValue={team.Phone}
                      className="lead-fields"
                      required
                      fullWidth
                      type="number"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      id="outlined-required"
                      label="Enter Phone no"
                      onChange={(e) => setMobile(e.target.value)}
                      error={mobileError}
                    />
                  </div>
                </div>

                <div className="row form-group " style={{ padding: "5px" }}>
                  <div className="col-lg-3">
                    <TextField
                      defaultValue={team.UserType}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter User Profile"
                      onChange={(e) => setProfile(e.target.value)}
                      error={profileError}
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      defaultValue={team.Email}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter Email id"
                      onChange={(e) => setEmail(e.target.value)}
                      error={emailError}
                    />
                  </div>

                  <div className="col-lg-3 " style={{ cursor: "pointer" }}>
                    <div className="dropzone lead-fields pt-1 pb-3 pl-3">
                      <input
                        style={{ maxWidth: "90%" }}
                        type="file"
                        // value={team.Image}
                        accept="image/*"
                        onChange={(event) => {
                          setImageUploadFunction(event.target.files[0]);
                        }}
                      />
                    </div>
                    <div>
                      <ul>
                        {fileNames?.map((fileName) => (
                          <li key={fileName}>{fileName}</li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Branch
                      </InputLabel>
                      <Select
                        multiple
                        defaultValue={team.Branch}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Branch"
                        onChange={(e) => setBranch(e.target.value)}
                        error={branchError}
                      >
                        {branchDetail &&
                          branchDetail.map((item) => {
                            return (
                              <MenuItem value={item.BranchName}>
                                {item.BranchName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="row form-group " style={{ padding: "5px" }}>
                  <div className="col-lg-3">
                    <FormControl
                      fullWidth
                      className="lead-fields permissionInUpdate"
                    >
                      <InputLabel id="demo-multiple-chip-label">
                        Permission
                      </InputLabel>
                      <Select
                        defaultValue={team?.Permission}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Permission"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              maxHeight: "50px",
                              overflow: "scroll",
                            }}
                          >
                            {selected?.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {names &&
                          names
                            .filter((name) => !name._id.includes(team._id))
                            ?.map((name) => {
                              return (
                                <MenuItem
                                  key={name._id}
                                  value={name.Name}
                                  style={getStyles(name, personName, theme)}
                                >
                                  {name.Name}
                                </MenuItem>
                              );
                            })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Current Status
                      </InputLabel>
                      <Select
                        defaultValue={team.Active}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Current Status"
                        onChange={(e) => setActive(e.target.value)}
                        // error={branchError}
                      >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditList;
