import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";
import Dropzone from "react-dropzone";
import Moment from "moment";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Navbar from "./Navbar";
import { useParams, useHistory } from "react-router-dom";
import Loading from "./Components/Loading";
import { message, Spin } from "antd";
import { useSelector } from "react-redux";

const EditOpp = () => {
  Axios.defaults.withCredentials = true;
  const user = useSelector((states) => states.auth);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    document.title = "Update Lead";
  }, []);

  useEffect(() => {
    Axios.get("/api/lead/readOneLead/" + params.id).then((response) => {
      setOpp(response.data.lead);
    });
  }, []);

  const [opp, setOpp] = useState([]);
  const [name, setName] = useState(opp.Name);
  const [nameError, setNameError] = useState(false);
  const [enquiry, setEnquiry] = useState(opp.EnquiryDate);
  const [enquiryError, setEnquiryError] = useState(false);
  const [city, setCity] = useState(opp.City);
  const [cityError, setCityError] = useState(false);
  const [Address, setAddress] = useState(opp.Location);
  const [AddressError, setAddressError] = useState(false);
  const [mobile1, setMobile1] = useState(opp.Phone1);
  const [mobile1Error, setMobile1Error] = useState(false);
  const [mobile2, setMobile2] = useState(opp.Phone2);
  const [email, setEmail] = useState(opp.Email);
  const [emailError, setEmailError] = useState(false);
  const [followupDate, setFollowupDate] = useState(opp.FollowupDate);
  const [followupDateError, setFollowupDateError] = useState(false);
  const [status, setStatus] = useState(opp.Status);
  const [statusError, setStatusError] = useState(false);
  const [assignedTo, setAssignedTo] = useState(opp.AssignTo);
  const [assignedToError, setAssignedToError] = useState(false);
  const [course, setCourse] = useState(opp.Course);
  const [courseError, setCourseError] = useState(false);
  const [price, setPrice] = useState(opp.CoursePrice);
  const [priceError, setPriceError] = useState(false);
  const [branch, setBranch] = useState(opp.Branch);
  const [branchError, setBranchError] = useState(false);
  const [remarks, setRemarks] = useState(opp.Remarks);
  const [remarksError, setRemarksError] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [imgurl, setImgurl] = useState(opp.Attachments);
  const [source, setSource] = useState(opp.Source);
  const [sourceError, setSourceError] = useState(false);
  const [loading, setLoading] = useState(false);

  const setImageUploadFunction = (file) => {
    setImageUpload(file);
    const imageRef = ref(storage, `images/${file.name + v4()}`);
    uploadBytes(imageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
        setImgurl(url);
      });
    });
  };

  const addToList = () => {
    setNameError(false);
    setEnquiryError(false);
    setCityError(false);
    setAddressError(false);
    setMobile1Error(false);
    setEmailError(false);
    setFollowupDateError(false);
    setStatusError(false);
    setAssignedToError(false);
    setBranchError(false);
    setCourseError(false);
    setPriceError(false);
    setSourceError(false);
    setRemarksError(false);

    if (name === "") {
      setNameError(true);
      return;
    }
    if (enquiry === "") {
      setEnquiryError(true);
      return;
    }
    if (city === "") {
      setCityError(true);
      return;
    }

    if (Address === "") {
      setAddressError(true);
    }

    if (mobile1) {
      let newnum1 = mobile1;
      for (var i = 1; i <= 10; i++) {
        newnum1 = Math.trunc(newnum1 / 10);
      }

      if (newnum1 !== 0) {
        setMobile1Error(true);
        return;
      }
    }

    if (email === "") {
      setEmailError(true);
      return;
    }

    if (followupDate === "") {
      setFollowupDateError(true);
      return;
    }
    if (status === "") {
      setStatusError(true);
      return;
    }
    if (assignedTo === "") {
      setAssignedToError(true);
      return;
    }
    if (branch === "") {
      setBranchError(true);
      return;
    }
    if (course === "") {
      setCourseError(true);
      return;
    }
    if (price === 0) {
      setPriceError(true);
      return;
    }
    if (source === "") {
      setSourceError(true);
      return;
    }

    if (remarks === "") {
      setRemarksError(true);
      return;
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put(
      "/api/lead/modifyLead/" + params.id,
      {
        Name: name,
        Email: email,
        City: city,
        Phone1: mobile1,
        Phone2: mobile2,
        AssignTo: assignedTo,
        EnquiryDate: enquiry,
        Source: source,
        Course: course,
        CoursePrice: price,
        Status: status,
        FollowupDate: followupDate,
        Attachments: imgurl,
        Branch: branch,
        CreatedBy: user.username,
        Remark: remarks,
        Address: Address,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        setTimeout(function () {
          history.push("/opportunities");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const [teamDetail, setTeamDetail] = useState([]);
  const [courseDetail, setCourseDetail] = useState([]);
  const [sourceDetail, setSourceDetail] = useState([]);
  const [branchDetail, setBranchDetail] = useState([]);
  const [cities, setCities] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [value, setValue] = useState(0);

  const selectBranch = async (Branch) => {
    setBranch(Branch);

    const res = await Axios.get(`/api/user/teamToBranch?Branch=${Branch}`);

    setTeamDetail(res.data.teams);
  };

  const selectCourse = async (Course) => {
    setCourse(Course);

    const res = await Axios.get(`/api/course/readPrice?CourseName=${Course}`);

    setPrice(res.data.data[0].CourseValue);
  };

  useEffect(() => {
    Axios.get(`/api/user/teamToBranch?Branch=${opp.Branch}`).then(
      (response) => {
        setTeamDetail(response.data.teams);
      }
    );
  }, [opp.Branch]);

  useEffect(() => {
    Axios.get("/api/course/readAllCourse").then((response) => {
      setCourseDetail(response.data.courses);
    });
    Axios.get("/api/source/readAllSource").then((response) => {
      setSourceDetail(response.data.sources);
    });
    Axios.get("/api/branch/readAllBranch").then((response) => {
      setBranchDetail(response.data.branchs);
    });

    Axios.get("/api/city/readAllCity").then((response) => {
      setCities(response.data.citys);
    });

    Axios.get("/api/status/readAllStatus").then((response) => {
      setStatuses(response.data.statuss);
    });
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Navbar title="Update Lead" />
      <div
        className="container-fluid pt-1"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingBottom: "5px",
            fontSize: "25px",
          }}
        >
          <div className="float-right pr-3">
            {" "}
            <button onClick={addToList} className="btn btn-primary add-button">
              {" "}
              Update Lead{" "}
            </button>
          </div>
        </div>
        <div className="row mt-1 mb-4 pl-3">
          <div className="col-lg-12">
            <div className="card" style={{ backgroundColor: "#F3F3F3" }}>
              <div className="card-body" key={opp._id}>
                <div className="row form-group  " style={{ padding: "5px" }}>
                  <div className="col-lg-3">
                    <TextField
                      defaultValue={opp.Name}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter Name"
                      onChange={(e) => setName(e.target.value)}
                      error={nameError}
                      disabled
                    />
                  </div>

                  <div className="col-lg-3">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Enquiry Date"
                        id="outlined-required"
                        onChange={(newValue) => {
                          setEnquiry(Moment(newValue).format("DD/MM/YY"));
                        }}
                        renderInput={(params) => (
                          <TextField
                            defaultValue={opp.EnquiryDate}
                            className="lead-fields"
                            required
                            disabled
                            fullWidth
                            {...params}
                            error={enquiryError}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        City
                      </InputLabel>
                      <Select
                        defaultValue={opp.City}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="City"
                        onChange={(e) => setCity(e.target.value)}
                        error={cityError}
                        disabled
                        inputProps={{
                          style: {
                            height: "20px",
                          },
                        }}
                      >
                        {cities &&
                          cities.map((item) => {
                            return (
                              <MenuItem value={item.CityName}>
                                {item.CityName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      defaultValue={opp.Address}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Location"
                      onChange={(e) => setAddress(e.target.value)}
                      error={AddressError}
                      disabled
                      inputProps={{
                        style: {
                          height: "16px",
                        },
                      }}
                    />
                  </div>
                </div>

                <div className="row form-group " style={{ padding: "5px" }}>
                  <div className="col-lg-3">
                    <TextField
                      defaultValue={opp.Phone1}
                      className="lead-fields"
                      required
                      fullWidth
                      type="number"
                      id="outlined-required"
                      label="Enter Phone no 1"
                      onChange={(e) => setMobile1(e.target.value)}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      error={mobile1Error}
                      disabled
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      defaultValue={opp.Phone2}
                      className="lead-fields"
                      required
                      fullWidth
                      type="number"
                      id="outlined-required"
                      label="Enter Phone no 2"
                      onChange={(e) => setMobile2(e.target.value)}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      disabled
                    />
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      defaultValue={opp.Email}
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Enter Email id"
                      onChange={(e) => setEmail(e.target.value)}
                      error={emailError}
                      disabled
                    />
                  </div>

                  <div className="col-lg-3">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      className="lead-fields"
                    >
                      <DatePicker
                        label="Followup Date"
                        value={followupDate || opp.FollowupDate}
                        onChange={(newValue) => {
                          setFollowupDate(Moment(newValue).format("DD/MM/YY"));
                        }}
                        renderInput={(params) => (
                          <TextField
                            defaultValue={opp.FollowupDate}
                            className="lead-fields"
                            required
                            fullWidth
                            {...params}
                            error={followupDateError}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="row form-group " style={{ padding: "5px" }}>
                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Status
                      </InputLabel>
                      <Select
                        defaultValue={opp.Status}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Status"
                        onChange={(e) => setStatus(e.target.value)}
                        error={statusError}
                      >
                        {statuses &&
                          statuses.map((item) => {
                            return (
                              <MenuItem value={item.StatusName}>
                                {item.StatusName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Branch
                      </InputLabel>
                      <Select
                        defaultValue={opp.Branch}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Branch"
                        onChange={(e) => selectBranch(e.target.value)}
                        error={branchError}
                      >
                        {branchDetail &&
                          branchDetail.map((item) => {
                            return (
                              <MenuItem value={item.BranchName}>
                                {item.BranchName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Enquiry Course
                      </InputLabel>
                      <Select
                        defaultValue={opp.Course}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Enquiry Course"
                        onChange={(e) => selectCourse(e.target.value)}
                        error={courseError}
                        disabled
                      >
                        {courseDetail &&
                          courseDetail.map((item) => {
                            return (
                              <MenuItem value={item.CourseName}>
                                {item.CourseName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    <TextField
                      defaultValue={opp.CoursePrice}
                      className="lead-fields"
                      value={price}
                      required
                      fullWidth
                      type="number"
                      id="outlined-required"
                      label="Course Price"
                      onChange={(e) => setPrice(e.target.value)}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 6);
                      }}
                      error={priceError}
                      disabled
                    />
                  </div>
                </div>

                <div className="row form-group " style={{ padding: "5px" }}>
                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Source
                      </InputLabel>
                      <Select
                        defaultValue={opp.Source}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Source"
                        onChange={(e) => setSource(e.target.value)}
                        error={sourceError}
                      >
                        {sourceDetail &&
                          sourceDetail.map((item) => {
                            return (
                              <MenuItem value={item.SourceName}>
                                {item.SourceName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    <FormControl fullWidth className="lead-fields">
                      <InputLabel id="demo-simple-select-label">
                        Assigned to
                      </InputLabel>
                      <Select
                        defaultValue={opp.AssignTo}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Assigned to"
                        onChange={(e) => setAssignedTo(e.target.value)}
                        error={assignedToError}
                      >
                        {teamDetail.length === 0 ? (
                          <MenuItem value="">
                            No list in current location
                          </MenuItem>
                        ) : (
                          teamDetail.map((item) => {
                            return (
                              <MenuItem value={item.UserName}>
                                {item.UserName}
                              </MenuItem>
                            );
                          })
                        )}
                      </Select>
                    </FormControl>
                  </div>

                  <div className="col-lg-3">
                    <TextareaAutosize
                      defaultValue={opp.Remark}
                      className="lead-fields"
                      minRows={2}
                      style={{ width: "100%" }}
                      aria-label="maximum height"
                      placeholder="Remarks"
                      onChange={(e) => setRemarks(e.target.value)}
                      error={remarksError}
                    />
                  </div>

                  <div className="col-lg-3 " style={{ cursor: "pointer" }}>
                    <div className="dropzone lead-fields pt-1 pb-3 pl-3">
                      <input
                        style={{ maxWidth: "90%" }}
                        type="file"
                        onChange={(event) => {
                          setImageUploadFunction(event.target.files[0]);
                        }}
                      />
                    </div>

                    {/* <input
                      className="lead-fields"
                      type="file"
                      onChange={(event) => {
                        setImageUpload(event.target.files[0])
                      }}
                    /> */}
                    <div>
                      <ul>
                        {fileNames.map((fileName) => (
                          <li key={fileName}>{fileName}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditOpp;
