import React, { useEffect, useState } from "react";
import Axios from "axios";
import Navbar from "./Navbar";
import TextField from "@mui/material/TextField";
import TextEditor from "./Components/TextEditor";
import { message } from "antd";
import { useHistory } from "react-router-dom";

const AddMail = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = "Add Mail";
  }, []);

  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [value, setValue] = useState("");
  const getValue = (value) => {
    setValue(value);
  };

  const addToList = () => {
    setTitleError(false);
    setSubjectError(false);

    if (title === "") {
      setTitleError(true);
      return;
    }

    // if (subject === '') {
    //   setSubjectError(true)
    //   return
    // }

    if (value === "") {
      message.error("Please Enter Content");
      return;
    }
    const config = { headers: { "Content-Type": "application/json" } };

    Axios.post(
      "/api/templatemail/insertTemplate",
      {
        Title: title,
        TemplateMessage: value,
        Subject: subject,
      },
      config
    )
      .then(function (response) {
        message.success(" Added successfully");
        setTimeout(function () {
          history.push("/mail-template");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Add Mail" show="false" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingBottom: "10px",
            fontSize: "25px",
          }}
        >
          {/* <h2>Add Branch</h2> */}
        </div>
        <div className="row mt-3 mb-2 pr-3 pl-3">
          <div className="col-lg-12">
            <div className="card" style={{ backgroundColor: "#F3F3F3" }}>
              <div className="card-body">
                <div className="row form-group  " style={{ padding: "10px" }}>
                  <div className="col-lg-6">
                    <TextField
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Mail Title"
                      onChange={(e) => setTitle(e.target.value)}
                      error={titleError}
                    />
                  </div>

                  <div className="col-lg-6">
                    <TextField
                      className="lead-fields"
                      required
                      fullWidth
                      id="outlined-required"
                      label="Mail Subject"
                      onChange={(e) => setSubject(e.target.value)}
                      error={subjectError}
                    />
                  </div>
                </div>
                <div className="row   ">
                  {" "}
                  <h4 style={{ paddingLeft: "250px" }}>Content:</h4>{" "}
                  <div className="col-12 mb-2 d-flex justify-content-center">
                    <div className="row pb-3">
                      <div
                        className="col-md-8 lead-fields"
                        style={{ margin: "auto", marginTop: "10px" }}
                      >
                        <TextEditor initialValue="" getValue={getValue} />
                        <br />
                        <div>{value}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="float-right pr-3">
                  {" "}
                  <button
                    onClick={addToList}
                    className="btn btn-primary add-button"
                  >
                    {" "}
                    Add Mail{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMail;
