import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Axios from "axios";
import { Button, Grid, Image } from "semantic-ui-react";
import classes from "./Profile.module.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";

import { message, Spin } from "antd";

import { v4 } from "uuid";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// import { async } from "@firebase/util";

function Profile() {
  Axios.defaults.withCredentials = true;

  const user = useSelector((states) => states.auth);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [imageUrls, setImageUrls] = useState([]);
  const [imgurl, setImgurl] = useState(user.image);
  const [imageUpload, setImageUpload] = useState(null);
  const [isValueChanged, setValueChanged] = useState(false);

  useEffect(() => {
    document.title = "Profile";
  }, []);

  useEffect(() => {
    Axios.get("/api/user/readTeamMember/" + user.userId).then((response) => {
      console.log(response.data.team);
      setTimeout(function () {
        setLoading(false);
      }, 100);
      setList(response.data.team);
    });
  }, []);

  const handleClick = () => {
    document.getElementById("profile-pic").click();
  };

  const setImageUploadFunction = (file) => {
    setImageUpload(file);
    const imageRef = ref(storage, `images/${file.name + v4()}`);
    uploadBytes(imageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
        setImgurl(url);
      });
    });
    setValueChanged(true);
    return;
  };

  const uploadImage = () => {
    const id = user.userId;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    Axios.put(
      `/api/user/modifyDetail/${id}`,
      {
        Image: imgurl,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        localStorage.setItem("image", imgurl);
        setTimeout(function () {
          history.push("/dashboard");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Profile" />
      <div id={classes.containerDiv}>
        {loading && <Spin style={{ position: "abolute", top: "40%" }}></Spin>}
        {!loading && (
          <>
            <Grid stretched divided="vertically" id={classes.grid} columns={2}>
              <Grid.Row>
                <Grid.Column id={classes.profileGrid} textAlign="center">
                  <div id={classes.imageDiv}>
                    <Image
                      id={classes.profileImage}
                      size="medium"
                      src={list.Image}
                    />
                    {!isValueChanged ? (
                      <Button
                        size="tiny"
                        icon="pencil"
                        color="instagram"
                        circular
                        onClick={handleClick}
                      />
                    ) : (
                      <Button
                        circular
                        color="facebook"
                        onClick={uploadImage}
                        icon="upload"
                      />
                    )}
                    <input
                      type="file"
                      id="profile-pic"
                      name="profile-pic"
                      hidden
                      accept="image/*"
                      onChange={async (e) => {
                        const res = await setImageUploadFunction(
                          e.target.files[0]
                        );
                        res && uploadImage();
                      }}
                    ></input>
                  </div>
                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="center">
                  <h1 id={classes.name}>{list.Name}</h1>
                  <h4 id={classes.username}>
                    {/* if username exists then convert it to lowercase otherwise it will try to convert undefined to lowercase which will raise error*/}
                    @{list.UserName && list.UserName.toLowerCase()}
                  </h4>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className={classes.row} style={{ padding: 0 }}>
                <Grid.Column textAlign="left">
                  <h6>Name :</h6>
                </Grid.Column>
                <Grid.Column textAlign="left">
                  <p>{list.Name}</p>
                </Grid.Column>
              </Grid.Row>{" "}
              <Grid.Row className={classes.row}>
                <Grid.Column textAlign="left">
                  <h6>Username :</h6>
                </Grid.Column>
                <Grid.Column textAlign="left">
                  <p>{list.UserName}</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className={classes.row}>
                <Grid.Column textAlign="left">
                  <h6>Email :</h6>
                </Grid.Column>
                <Grid.Column textAlign="left">
                  <p>{list.Email}</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className={classes.row}>
                <Grid.Column textAlign="left">
                  <h6>User Type :</h6>
                </Grid.Column>
                <Grid.Column>
                  <p>{list.role}</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className={classes.row}>
                <Grid.Column textAlign="left">
                  <h6>Phone :</h6>
                </Grid.Column>
                <Grid.Column textAlign="left">
                  <p>{list.Phone}</p>
                </Grid.Column>
              </Grid.Row>{" "}
              <Grid.Row className={classes.row}>
                <Grid.Column textAlign="left">
                  <h6>City :</h6>
                </Grid.Column>
                <Grid.Column textAlign="left">
                  <p>Delhi</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className={classes.row}>
                <Grid.Column textAlign="left">
                  <h6>Branch :</h6>
                </Grid.Column>
                <Grid.Column textAlign="left">
                  <p>Delhi</p>
                </Grid.Column>
              </Grid.Row>{" "}
              <Grid.Row className={classes.row}>
                <Grid.Column textAlign="left">
                  <h6>Added :</h6>
                </Grid.Column>
                <Grid.Column textAlign="left">
                  <p>
                    {list.updatedAt &&
                      list.updatedAt
                        .slice(0, 10)
                        .split("-")
                        .reverse()
                        .join("/")}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </div>
    </>
  );
}

export default Profile;

// <div
//         className="container-fluid pt-4"
//         style={{ backgroundColor: '#F0F2FA' }}
//       >
//         <div
//           style={{
//             paddingLeft: '20px',
//             paddingBottom: '10px',
//             fontSize: '25px',
//           }}
//         >
//           {/* <h2>Settings Update</h2> */}
//         </div>
//         <div className="row mt-3 mb-4 pr-3 pl-3">
//           <div className="col-lg-12">
//             <div className="card" style={{ backgroundColor: '#F3F3F3' }}>
//               <div className="card-body">
//                 <div
//                   class="d-flex justify-content-center "
//                   style={{ paddingTop: '10px', paddingBottom: '10px' }}
//                 >
//                   <img src={list.Image} alt="" class="user-img" />
//                 </div>
//                 <div className="row form-group  " style={{ padding: '10px' }}>
//                   <div className="col-lg-6">
//                     Name:
//                     <TextField
//                       value={list.Name}
//                       className="lead-fields"
//                       fullWidth
//                       id="outlined-required"
//                       disabled
//                     />
//                   </div>

//                   <div className="col-lg-6">
//                     Email:
//                     <TextField
//                       value={list.Email}
//                       className="lead-fields"
//                       fullWidth
//                       id="outlined-required"
//                       disabled
//                     />
//                   </div>
//                 </div>

//                 <div className="row form-group  " style={{ padding: '10px' }}>
//                   <div className="col-lg-6">
//                     Phone:
//                     <TextField
//                       value={list.Phone}
//                       className="lead-fields"
//                       fullWidth
//                       id="outlined-required"
//                       disabled
//                     />
//                   </div>

//                   <div className="col-lg-6">
//                     Role:
//                     <TextField
//                       value={list.role}
//                       className="lead-fields"
//                       fullWidth
//                       id="outlined-required"
//                       disabled
//                     />
//                   </div>
//                 </div>

//                 {localStorage.getItem('role') !== 'admin' ? (
//                   <div className="row form-group  " style={{ padding: '10px' }}>
//                     <div className="col-lg-6">
//                       City:
//                       <TextField
//                         value={list.City}
//                         className="lead-fields"
//                         fullWidth
//                         id="outlined-required"
//                         disabled
//                       />
//                     </div>

//                     <div className="col-lg-6">
//                       Branch:
//                       <TextField
//                         value={list.Branch}
//                         className="lead-fields"
//                         fullWidth
//                         id="outlined-required"
//                         disabled
//                       />
//                     </div>
//                   </div>
//                 ) : (
//                   <></>
//                 )}
//               </div>
//             </div>
//             {/*
//             <div class="d-flex justify-content-center ">
//               <div
//                 class="m-b-20 profile-card"
//                 style={{ backgroundColor: '#fff' }}
//               >
//                 {loading && <Spin size="large" />}

//                 <div class="contact-grid">
//                   <div class="profile-header bg-dark">
//                     <div class="user-name">{list.Name}</div>
//                     <div class="name-center">{list.UserType}</div>
//                   </div>
//                   <img src={list.Image} alt="" class="user-img" />

//                   <div>
//                     {localStorage.getItem('role') === 'admin' ? (
//                       <div className="pt-2 pb-2"></div>
//                     ) : (
//                       <></>
//                     )}
//                     <h5>
//                       <div className="pr-1" style={{ display: 'inline-block' }}>
//                         Email:
//                       </div>{' '}
//                       {list.Email}
//                     </h5>

//                     {localStorage.getItem('role') !== 'admin' ? (
//                       <>
//                         <h5>
//                           <div
//                             className="pr-1"
//                             style={{ display: 'inline-block' }}
//                           >
//                             Phone:
//                           </div>{' '}
//                           {list.Phone}
//                         </h5>

//                         <h5>
//                           <div
//                             className="pr-1"
//                             style={{ display: 'inline-block' }}
//                           >
//                             Branch:
//                           </div>{' '}
//                           {list.Branch}
//                         </h5>

//                         <h5>
//                           <div
//                             className="pr-1"
//                             style={{ display: 'inline-block' }}
//                           >
//                             City:
//                           </div>{' '}
//                           {list.City}
//                         </h5>
//                       </>
//                     ) : (
//                       <></>
//                     )}

//                     <h5>
//                       <div className="pr-1" style={{ display: 'inline-block' }}>
//                         Active:
//                       </div>{' '}
//                       {list.Active}
//                     </h5>
//                   </div>
//                 </div>
//               </div>
//             </div> */}
//           </div>
//         </div>
//       </div>
