import React, { useEffect, useState } from "react";
import Axios from "axios";
import Navbar from "./Navbar";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { message, Spin } from "antd";
import { useHistory } from "react-router-dom";

const AddLink = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = "Add Link";
  }, []);

  const [leads, setLeads] = useState([]);
  const [lead, setLead] = useState("");
  const [loading, setLoading] = useState(false);
  const [leadError, setLeadError] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [description, setDescription] = useState("");
  const [links, setLinks] = useState("");
  const [linkError, setLinkError] = useState(false);

  const link1 = "/api/lead/readAllLeads";
  const link2 =
    "/api/lead/showLeadAccToPermission/" + localStorage.getItem("userid");

  let link = "";

  if (localStorage.getItem("role") === "admin") {
    link = link1;
  } else {
    link = link2;
  }

  useEffect(() => {
    Axios.get(`${link}`).then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 100);
      setLeads(response.data.lead);
    });
  }, []);

  const addToList = () => {
    setTitleError(false);
    setLinkError(false);

    if (title === "") {
      setTitleError(true);
      return;
    }

    if (links === "") {
      setLinkError(true);
      return;
    }

    // if (description === '') {
    //   message.error('Please Enter Description')
    //   return
    // }
    const config = { headers: { "Content-Type": "application/json" } };

    Axios.post(
      "/api/templateLink/insertTemplate",
      {
        Title: title,
        Description: description,
        Link: links,
      },
      config
    )
      .then(function (response) {
        message.success(" Added successfully");
        setTimeout(function () {
          history.push("/link-template");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Add Link" show="false" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          className="card"
          style={{
            backgroundColor: "#F3F3F3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: "20px",
              minWidth: "700px",
            }}
          >
            <TextField
              className="lead-fields"
              style={{ width: "100%" }}
              required
              id="outlined-required"
              label="Message Title"
              onChange={(e) => setTitle(e.target.value)}
              error={titleError}
            />
          </div>
          <div
            style={{
              minWidth: "700px",
              marginBottom: "20px",
            }}
          >
            <TextField
              className="lead-fields"
              required
              fullWidth
              id="outlined-required"
              label="Link"
              onChange={(e) => setLinks(e.target.value)}
              error={linkError}
            />
          </div>
          <div
            style={{
              minWidth: "700px",
            }}
          >
            <TextareaAutosize
              aria-label="minimum height"
              minRows={5}
              placeholder="Content"
              style={{ width: "100%", padding: "10px" }}
              onChange={(e) => setDescription(e.target.value)}
              // error={remarksError}
            />
          </div>

          <div>
            <button onClick={addToList} className="btn btn-primary add-button">
              Add Link Template
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLink;
