import React, { useState } from "react";
import Axios from "axios";
import Navbar from "./Navbar";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { message, Spin } from "antd";
import { useHistory } from "react-router-dom";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const AddSms = () => {
  useEffect(() => {
    document.title = "Add SMS";
  }, []);

  const [TemplateMessage, setTemplateMessage] = useState("");
  const [Title, setTitle] = useState("");

  const history = useHistory();

  const send = () => {
    Axios.post(`/api/templateSMS/insertTemplate`, {
      TemplateMessage,
      Title,
    })
      .then(function (response) {
        message.success(" created successfully");
        history.push("/message-template");
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Add Sms" show="false" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          className="card"
          style={{
            backgroundColor: "#F3F3F3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: "20px",
              minWidth: "700px",
            }}
          >
            <TextField
              className="lead-fields"
              style={{ width: "100%" }}
              required
              id="outlined-required"
              label="Message Title"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div
            style={{
              minWidth: "700px",
            }}
          >
            <TextareaAutosize
              aria-label="minimum height"
              minRows={5}
              placeholder="Content"
              style={{ width: "100%", padding: "10px" }}
              onChange={(e) => setTemplateMessage(e.target.value)}
            />
          </div>

          <div>
            <button onClick={send} className="btn btn-primary add-button">
              Add SMS
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSms;
