import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Sticky } from "semantic-ui-react";
import classes from "./Whatsapp.module.css";
import Navbar from "./Navbar";
import Grow from "@mui/material/Grow";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";

const DocList = () => {
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    document.title = "Document List";
  }, []);

  const rowData = [
    {
      title: "Address 1",
      message:
        "hi, [candidate_name] \nkindly find the address as below:\n 94 Co-working Space, sector - 13, 4th floor YC Space, near Radisson Blu, Dwarka, Delhi, 110075",
      status: false,
    },
    {
      title: "Address 2",
      message:
        "hi, [candidate_name]Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. \nkindly find the address as below:\n 94 Co-working Space, sector - 13, 4th floor YC Space, near Radisson Blu, Dwarka, Delhi, 110075",
      status: true,
    },
    {
      title: "Address 3",
      message:
        "hi, [candidate_name] \nkindly find the address as below:\n 94 Co-working Space,  sector - 13, 4th floor YC Space, near Radisson Blu, Dwarka, Delhi, 110075",
      status: true,
    },
    {
      title: "Address 4",
      message:
        "hi, [candidate_name] \nkindly find the address as below:\n 94 Co-working Space, sector - 13, 4th floor YC Space, near Radisson Blu, Dwarka, Delhi, 110075",
      status: false,
    },
    {
      title: "Address",
      message:
        "hi, [candidate_name] \nkindly find the address as below:\n 94 Co-working Space, sector - 13, 4th floor YC Space, near Radisson Blu, Dwarka, Delhi, 110075",
      status: true,
    },

    {
      title: "Address",
      message:
        "hi, [candidate_name] \nkindly find the address as below:\n 94 Co-working Space, sector - 13, 4th floor YC Space, near Radisson Blu, Dwarka, Delhi, 110075",
      status: false,
    },
    {
      title: "Address",
      message:
        "hi, [candidate_name] \nkindly find the address as below:\n 94 Co-working Space, sector - 13, 4th floor YC Space, near Radisson Blu, Dwarka, Delhi, 110075",
      status: false,
    },
    {
      title: "Address",
      message:
        "hi, [candidate_name] \nkindly find the address as below:\n 94 Co-working Space, sector - 13, 4th floor YC Space, near Radisson Blu, Dwarka, Delhi, 110075",
      status: false,
    },
    {
      title: "Address",
      message:
        "hi, [candidate_name] \nkindly find the address as below:\n 94 Co-working Space, sector - 13, 4th floor YC Space, near Radisson Blu, Dwarka, Delhi, 110075",
      status: false,
    },
    {
      title: "Address",
      message:
        "hi, [candidate_name] \nkindly find the address as below:\n 94 Co-working Space, sector - 13, 4th floor YC Space, near Radisson Blu, Dwarka, Delhi, 110075",
      status: false,
    },
  ];

  const [checked, setChecked] = React.useState(false);

  return (
    <React.Fragment>
      <Navbar title="Document List"></Navbar>
      <div
        className="container-fluid pt-3"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <h2>
          <div className="">
            <div className="row">
              <div className="col-3"></div>
              <div className="col-5 search-dash">
                <TextField
                  id="search-bar"
                  fullWidth
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AiOutlineSearch />
                      </InputAdornment>
                    ),
                  }}
                  label="Search"
                  variant="outlined"
                  placeholder=""
                  size="small"
                />
              </div>
              <div className="col-2"></div>
              <div className="col-2 ">
                <Link to="/adddoc">
                  <a>
                    <button
                      className="btn  add-button btn-primary float-right"
                      to="/"
                    >
                      {" "}
                      Add Template{" "}
                    </button>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </h2>

        <div className="row mt-3 mb-4 pr-3 pl-3">
          <div className="col-lg-12">
            <div className="" style={{ backgroundColor: "#F3F3F3" }}>
              <div
                class="table-full template"
                style={{
                  maxHeight: "70vh",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Container
                  className={classes.gridContainer}
                  style={{ width: "100%" }}
                >
                  <Grid id={classes.whatsappGridTable}>
                    <div
                      className={classes.gridDiv}
                      style={{
                        backgroundColor: "#8fcaeb",
                        textAlign: "center",
                      }}
                    >
                      <Grid>
                        <Grid.Row id={classes.gridRow} textAlign="centered">
                          <Grid.Column textAlign="centered" width={5}>
                            <h4>Title</h4>
                          </Grid.Column>
                          {/* <Grid.Column textAlign="centered" width={8}>
                            <h4>Message</h4>
                          </Grid.Column> */}
                          <Grid.Column textAlign="centered" width={5}>
                            <h4>Status</h4>
                          </Grid.Column>
                          <Grid.Column textAlign="centered" width={6}>
                            <h4>Action</h4>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                    <div
                      className={classes.tableContent}
                      style={{ width: "100%", maxHeight: "70vh" }}
                    >
                      {rowData
                        .filter((e) => {
                          if (keyword === "") {
                            return e;
                          } else if (
                            e.title
                              .toLowerCase()
                              .includes(keyword.toLowerCase()) ||
                            e.message
                              .toLowerCase()
                              .includes(keyword.toLowerCase())
                          ) {
                            return e;
                          }
                        })
                        .map((row, index) => {
                          index = index + 1;
                          let alter = index % 2 === 1 ? "#fff" : "#E9F0FF";
                          return (
                            <Grow appear="false" in>
                              <div
                                className={classes.gridDiv}
                                style={{ backgroundColor: alter }}
                              >
                                <Grid>
                                  <Grid.Row
                                    // color={alter}
                                    textAlign="centered"
                                    key={index}
                                    //   style={{ padding: "2rem 0rem" }}
                                  >
                                    <Grid.Column textAlign="centered" width={5}>
                                      <p>{row.title + " "}</p>
                                    </Grid.Column>
                                    {/* <Grid.Column textAlign="centered" width={8}>
                                    <p>{row.message}</p>
                                  </Grid.Column> */}
                                    <Grid.Column textAlign="centered" width={5}>
                                      <Button.Group>
                                        <Button>Active</Button>
                                        <Button.Or />
                                        <Button positive>Inactive</Button>
                                      </Button.Group>
                                    </Grid.Column>
                                    <Grid.Column textAlign="centered" width={6}>
                                      <Link to="/sendwhatsapp/ghh">
                                        <Button circular icon="send"></Button>
                                      </Link>
                                      <Button
                                        circular
                                        color="instagram"
                                        icon="pencil alternate"
                                      ></Button>
                                      <a
                                        data-toggle="modal"
                                        data-target="#mymodal"
                                        // onClick={() => setID(val._id)}
                                      >
                                        <Button
                                          circular
                                          color="red"
                                          icon="trash alternate outline"
                                        ></Button>
                                      </a>
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </div>
                            </Grow>
                          );
                        })}

                      <div
                        className="modal fade"
                        id="mymodal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div
                          className="modal-dialog modal-md modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">Confirmation</h5>
                            </div>
                            <div className="modal-body">
                              Are you sure to delete this Information
                              permanently?
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-primary btn-flat"
                                id="confirm"
                                // onClick={() => deleteLead(ID)}
                                data-dismiss="modal"
                              >
                                Continue
                              </button>
                              <button
                                type="button"
                                className="btn  border btn-flat"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocList;
