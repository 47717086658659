import React, { useState } from "react";
import Axios from "axios";
import { useEffect } from "react";
import classes from "./LeadPeek.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const LeadPeek = (props) => {
  const viewId = props.viewId;
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState([]);

  useEffect(() => {
    const fetchOneLead = async () => {
      try {
        setLoading(true);
        const response = await Axios.get("/api/lead/readOneLead/" + viewId);
        setRemarks(response?.data?.lead?.Remarks?.reverse());
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    // <--- --->
    fetchOneLead();
  }, [viewId]);

  //  SHOWING ALL REMARKS OF LAST DAY
  return (
    <div className={classes.container}>
      {loading ? (
        <div style={{ display: "flex" }}>
          <ClipLoader
            size={20}
            cssOverride={{ margin: "auto" }}
            color="#36d7b7"
          />
        </div>
      ) : remarks.length === 0 ? (
        <p style={{ textAlign: "center", color: "red" }}>No Log Found</p>
      ) : (
        <ol type="1" className={classes.list}>
          {remarks.map((remark, index) => {
            let alter = index % 2;
            return (
              <li
                style={
                  alter === 0
                    ? {
                        backgroundColor: "#ffe8e8",
                        margin: "2px",
                        borderRadius: "2px",
                      }
                    : {
                        backgroundColor: "#e3fcff",
                        margin: "2px",
                        borderRadius: "2px",
                      }
                }
              >
                {remark.length > 1000 ? `${remark.slice(0, 100)}...` : remark}
              </li>
            );
          })}
        </ol>
      )}
    </div>
  );
};

export default LeadPeek;
