import React, { useState, useEffect } from "react";
import "./login.css";
import login from "../src/assets/login.png";
import axios from "axios";
import { message, Spin } from "antd";
import mac from "./Components/Mac";

import { useDispatch } from "react-redux";
import { login as reduxLogin } from "./features/auth-slice";

const Login = () => {
  axios.defaults.withCredentials = true;

  // const userData = useSelector((state) => state.auth);
  // useEffect(() => {
  //   console.log(userData);
  // }, [userData]);

  const dispatch = useDispatch();

  const [state, setState] = useState({
    email: "",
    password: "",
    message: "",
    loading: "",
  });

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const goLogin = async () => {
    let Email = state.email;
    let Password = state.password;
    setState({ ...state, loading: 1 });

    try {
      const config = {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      };
      const res = await axios.post(
        "/api/user/login",
        { Email, Password, MacAddress: mac },
        config
      );

      dispatch(
        reduxLogin({
          userId: res.data.user._id,
          name: res.data.user.Name,
          image: res.data.user.Image,
          username: res.data.user.UserName,
          role: res.data.user.role,
          email: res.data.user.Email,
          phone: res.data.user.Phone,
        })
      );

      message.success("Login successfull");
      setState({
        ...state,
        message: "Success: Please wait Redirecting....",
        loading: "",
      });
    } catch (error) {
      message.error(`${error.response.data.message}`);
      setState({
        ...state,
        loading: "",
        message: "Fail: Invalid Email or Password",
      });
    }
  };

  const goPassword = async (e) => {
    e.preventDefault();
    setState({ ...state, loading: 1 });

    let Email = state.email;

    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const res = await axios.post(
        "/api/user/forgotPassword",
        { Email },
        config
      );
      message.success("Mail sent");

      setState({
        ...state,
        message: "Success: Please wait Redirecting....",
        loading: "",
      });
    } catch (error) {
      message.error(`${error.response.data.message}`);
      setState({
        ...state,
        message: "Fail: Invalid Email or Password",
        loading: "",
      });
    }
  };

  return (
    <>
      {state.loading === "1" ? <Spin size="large" /> : <></>}

      <div className="form-login-body">
        <div className="containerLogin d-flex justify-content-center">
          <div className="row">
            <div className="col-lg-10 mx-auto login-desk">
              <div className="row" style={{ borderRadius: "20px" }}>
                <div className="col-7 detail-box">
                  {/* <img className="logo" src="assets/images/logo.png" alt="">  */}
                  <div className="detailsh">
                    <img className="help img-fluid" src={login} alt="" />
                    <div className="img-fluid">
                      <h4>"Don't Optimize for conversations,</h4>
                    </div>
                    <h4>optimize for revenue"</h4>
                  </div>
                </div>
                <div className="col-5 pt-3 loginform ">
                  <div className="">
                    <div className="welcome">
                      <h3>Welcome Back!!</h3>
                    </div>

                    <div className="login-det">
                      <div className="form-row">
                        <label htmlFor="">Email</label>
                        <div className="input-group mb-3">
                          <input
                            value={state.email}
                            name="email"
                            type="text"
                            className="form-control"
                            placeholder="Enter Email"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleOnChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <label htmlFor="">Password</label>
                        <div className="input-group mb-3">
                          <input
                            value={state.password}
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Enter Password"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                            onChange={handleOnChange}
                          />
                        </div>
                      </div>
                    </div>

                    <button className="btn btn-sm btn-login" onClick={goLogin}>
                      Login
                    </button>

                    <h5
                      style={{ cursor: "pointer", color: "#317fd2" }}
                      data-toggle="modal"
                      data-target="#passwordModal"
                    >
                      Reset Password
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade show"
          id="passwordModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Reset Password
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form method="post" id="username-verification">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-9">
                        <input
                          type="text"
                          id="username"
                          name="email"
                          placeholder="Enter Email"
                          className="form-control"
                          onChange={handleOnChange}
                        />
                      </div>
                      <div className="col-sm-3">
                        <button
                          type="submit"
                          className="btn btn-success"
                          onClick={goPassword}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
