import React, { useEffect } from "react";
import OppComponent from "./Components/OppComponent";

const Dashboard = () => {
  useEffect(() => {
    document.title = "CRM-The XL Academy";
  }, []);

  return (
    <div className="content-body">
      <div className="container-fluid   ">
        <div>
          <OppComponent />
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
