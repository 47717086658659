import React, { useState } from "react";
import Axios from "axios";
import Navbar from "./Navbar";
import TextField from "@mui/material/TextField";
import TextEditor from "./Components/TextEditor";
import { useEffect } from "react";
import { message, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const EditSms = () => {
  const [titleError, setTitleError] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    document.title = "Edit SMS";
  }, []);

  useEffect(() => {
    Axios.get("/api/templatesms/readOneTemplate/" + params.id).then(
      (response) => {
        setTimeout(function () {
          setLoading(false);
        }, 100);
        setRowData(response.data.Templates);
      }
    );
  }, []);

  const [title, setTitle] = useState(rowData.Title);
  const [TemplateMessage, setTemplateMessage] = useState(
    rowData.TemplateMessage
  );

  const addToList = () => {
    // setTitleError(false)

    // if (title === '') {
    //   setTitleError(true)
    //   return
    // }

    const config = { headers: { "Content-Type": "application/json" } };

    Axios.put(
      "/api/templatesms/modifyTemplate/" + params.id,
      {
        Title: title,
        TemplateMessage,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        setTimeout(function () {
          history.push("/message-template");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Edit Sms" show="false" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          className="card"
          style={{
            backgroundColor: "#F3F3F3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: "20px",
              minWidth: "700px",
            }}
          >
            <TextField
              value={rowData.Title}
              className="lead-fields"
              required
              fullWidth
              id="outlined-required"
              onChange={(e) => setTitle(e.target.value)}
              error={titleError}
            />
          </div>
          <div
            style={{
              minWidth: "700px",
            }}
          >
            <TextareaAutosize
              defaultValue={rowData.TemplateMessage}
              aria-label="minimum height"
              minRows={5}
              placeholder="Content"
              style={{ width: "100%", padding: "10px" }}
              onChange={(e) => setTemplateMessage(e.target.value)}
            />
          </div>

          <div>
            <button onClick={addToList} className="btn btn-primary add-button">
              Update SMS
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSms;
