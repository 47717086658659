import React from 'react'
import Navbar from './Navbar'
import OppComponent from './Components/OppComponent'

function Opportunities() {
  return (
    <>
      <Navbar title="Opportunity List" />
      <div
        className="container-fluid pt-2"
        style={{ backgroundColor: '#F0F2FA' }}
      >
        <OppComponent />
      </div>
    </>
  )
}

export default Opportunities
