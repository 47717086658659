import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Transition } from "semantic-ui-react";
import classes from "./Whatsapp.module.css";
import Navbar from "./Navbar";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";
import Axios from "axios";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { AiOutlineSearch } from "react-icons/ai";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { message, Spin } from "antd";

const MessageTemplate = () => {
  const [keyword, setKeyword] = useState("");
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("ASC");
  const [torder, setTOrder] = useState("ASC");
  const [morder, setMOrder] = useState("ASC");
  const [ID, setID] = useState();

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...rowData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );

      if (col === "Title") setTOrder("DSC");
      if (col === "TemplateMessage") setMOrder("DSC");
      setRowData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...rowData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );

      if (col === "Title") setTOrder("ASC");
      if (col === "TemplateMessage") setMOrder("ASC");

      setRowData(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    document.title = "Message Template";
    Axios.get("/api/templatesms/readAllTemplate").then((response) => {
      setRowData(response.data.Templates);
    });
  }, []);

  const deleteLead = (id) => {
    Axios.delete(`/api/templatesms/removeTemplate/${id}`)
      .then(function (response) {
        message.success(" Deleted successfully");
        Axios.get("/api/templatesms/readAllTemplate").then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setRowData(response.data.Templates);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  const activeClick = (val, id) => {
    if (val === "inactive") {
      const config = { headers: { "Content-Type": "application/json" } };

      Axios.put(
        "/api/templatesms/modifyTemplate/" + id,
        {
          active: "active",
        },
        config
      )
        .then(function (response) {
          message.success(" Updated successfully");
          Axios.get("/api/templatesms/readAllTemplate").then((response) => {
            setTimeout(function () {
              setLoading(false);
            }, 100);
            setRowData(response.data.Templates);
          });
        })
        .catch(function (error) {
          message.error(`${error.response.data.message}`);
        });
    } else {
      return;
    }
  };

  const inactiveClick = (val, id) => {
    if (val === "active") {
      const config = { headers: { "Content-Type": "application/json" } };

      Axios.put(
        "/api/templatesms/modifyTemplate/" + id,
        {
          active: "inactive",
        },
        config
      )
        .then(function (response) {
          message.success(" Updated successfully");
          Axios.get("/api/templatesms/readAllTemplate").then((response) => {
            setTimeout(function () {
              setLoading(false);
            }, 100);
            setRowData(response.data.Templates);
          });
        })
        .catch(function (error) {
          message.error(`${error.response.data.message}`);
        });
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      <Navbar title="Message Template" show="false"></Navbar>
      <div
        className="container-fluid pt-2"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <h2>
          <div className="">
            <div className="row">
              <div className="col-3"></div>
              <div className="col-5 search-dash">
                <TextField
                  id="search-bar"
                  fullWidth
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AiOutlineSearch />
                      </InputAdornment>
                    ),
                  }}
                  label="Search"
                  variant="outlined"
                  placeholder=""
                  size="small"
                />
              </div>
              <div className="col-2"></div>
              <div className="col-2 ">
                <Link to="/addsms">
                  <a>
                    <button
                      className="btn  add-button btn-primary float-right"
                      to="/"
                    >
                      {" "}
                      Add Template{" "}
                    </button>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </h2>
        <div className="row mt-3 mb-4 pr-3 pl-3">
          <div className="col-lg-12">
            <div className="" style={{ backgroundColor: "#F3F3F3" }}>
              <div
                class="table-full template"
                style={{
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Container
                  className={classes.gridContainer}
                  style={{ width: "100%" }}
                >
                  <Grid id={classes.whatsappGridTableuh}>
                    <div
                      className={classes.gridDiv}
                      style={{
                        backgroundColor: "#8fcaeb",
                        textAlign: "center",
                      }}
                    >
                      <Grid>
                        <Grid.Row id={classes.gridRow} textAlign="centered">
                          <Grid.Column
                            onClick={() => sorting("Title")}
                            textAlign="centered"
                            width={2}
                          >
                            <h4>
                              Title{" "}
                              {torder === "ASC" ? (
                                <BsArrowDown />
                              ) : (
                                <BsArrowUp />
                              )}
                            </h4>
                          </Grid.Column>
                          <Grid.Column
                            onClick={() => sorting("TemplateMessage")}
                            textAlign="centered"
                            width={8}
                          >
                            <h4>
                              Message{" "}
                              {morder === "ASC" ? (
                                <BsArrowDown />
                              ) : (
                                <BsArrowUp />
                              )}
                            </h4>
                          </Grid.Column>
                          <Grid.Column
                            textAlign="centered"
                            width={2}
                            className={classes.status}
                          >
                            <h4>Status</h4>
                          </Grid.Column>
                          <Grid.Column
                            textAlign="centered"
                            width={2}
                            className={classes.status}
                          >
                            <h4>Action</h4>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                    <div className={classes.tableContent}>
                      {rowData
                        .filter((e) => {
                          if (keyword === "") {
                            return e;
                          } else if (
                            e.Title.toLowerCase().includes(
                              keyword.toLowerCase()
                            ) ||
                            e.TemplateMessage.toLowerCase().includes(
                              keyword.toLowerCase()
                            )
                          ) {
                            return e;
                          } else {
                            return null;
                          }
                        })
                        .map((row, index) => {
                          index = index + 1;
                          let alter = index % 2 === 1 ? "#fff" : "#E9F0FF";

                          let activeColor1 =
                            row.active === "active" ? "positive " : "";

                          let activeColor2 =
                            row.active !== "active" ? "negative " : "";

                          return (
                            <Grow appear="false" in>
                              <div
                                className={classes.gridDiv}
                                style={{ backgroundColor: alter }}
                              >
                                <Grid>
                                  <Grid.Row
                                    textAlign="centered"
                                    key={index}
                                    className={classes.gridRow}
                                    //   style={{ padding: "2rem 0rem" }}
                                  >
                                    <Grid.Column
                                      textAlign="centered"
                                      width={2}
                                      className={classes.title}
                                    >
                                      <p>{row.Title + " "}</p>
                                    </Grid.Column>
                                    <Grid.Column
                                      textAlign="centered"
                                      width={8}
                                      className={classes.message}
                                    >
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: row.TemplateMessage,
                                        }}
                                      ></p>
                                    </Grid.Column>
                                    <Grid.Column textAlign="centered" width={2}>
                                      <Button.Group size="mini">
                                        <Button
                                          color={activeColor1}
                                          onClick={() =>
                                            activeClick(row.active, row._id)
                                          }
                                        >
                                          Active
                                        </Button>
                                        <Button.Or />
                                        <Button
                                          color={activeColor2}
                                          onClick={() =>
                                            inactiveClick(row.active, row._id)
                                          }
                                        >
                                          Inactive
                                        </Button>
                                      </Button.Group>
                                    </Grid.Column>
                                    <Grid.Column
                                      textAlign="centered"
                                      Width={2}
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <Link to={`/editsms/${row._id}`}>
                                        <Button
                                          size="mini"
                                          circular
                                          color="instagram"
                                          icon="pencil alternate"
                                        ></Button>
                                      </Link>
                                      <a
                                        data-toggle="modal"
                                        data-target="#mymodal"
                                        onClick={() => setID(row._id)}
                                      >
                                        <Button
                                          size="mini"
                                          circular
                                          color="red"
                                          icon="trash alternate outline"
                                        ></Button>
                                      </a>

                                      <div
                                        className="modal fade"
                                        id="mymodal"
                                        tabIndex="-1"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                      >
                                        <div
                                          className="modal-dialog modal-md modal-dialog-centered"
                                          role="document"
                                        >
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5 className="modal-title">
                                                Confirmation
                                              </h5>
                                            </div>
                                            <div className="modal-body">
                                              Are you sure to delete this
                                              Information permanently?
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-primary btn-flat"
                                                id="confirm"
                                                onClick={() => deleteLead(ID)}
                                                data-dismiss="modal"
                                              >
                                                Continue
                                              </button>
                                              <button
                                                type="button"
                                                className="btn  border btn-flat"
                                                data-dismiss="modal"
                                              >
                                                Close
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>
                              </div>
                            </Grow>
                          );
                        })}
                    </div>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MessageTemplate;
