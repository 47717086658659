import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import { message, Spin } from "antd";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import Moment from "moment";

function SourceList() {
  Axios.defaults.withCredentials = true;
  const [source, setSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("ASC");
  const [corder, setCOrder] = useState("ASC");
  const [sorder, setSOrder] = useState("ASC");
  const [dorder, setDOrder] = useState("ASC");

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...source].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );

      if (col === "createdAt") setCOrder("DSC");
      if (col === "SourceName") setSOrder("DSC");
      if (col === "Description") setDOrder("DSC");
      setSource(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...source].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      if (col === "createdAt") setCOrder("ASC");
      if (col === "SourceName") setSOrder("ASC");
      if (col === "Description") setDOrder("ASC");
      setSource(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    document.title = "Source List";
  }, []);

  useEffect(() => {
    Axios.get("/api/source/readAllSource").then((response) => {
      setTimeout(function () {
        setLoading(false);
      }, 100);
      setSource(response.data.sources);
    });
  }, []);

  let ID = 0;

  const setID = (id) => {
    ID = id;
  };

  const deleteLead = (id) => {
    Axios.delete(`/api/source/deleteSource/${id}`)
      .then(function (response) {
        message.success(" Deleted successfully");
        Axios.get("/api/source/readAllSource").then((response) => {
          setTimeout(function () {
            setLoading(false);
          }, 100);
          setSource(response.data.sources);
        });
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Source List" />
      <div
        className="container-fluid pt-2"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "50px",
            fontSize: "25px",
          }}
        >
          <h2>
            <div className="text-right">
              {" "}
              <Link to="/addsource">
                <a>
                  <button className="btn  add-button btn-primary">
                    {" "}
                    Add Source{" "}
                  </button>
                </a>
              </Link>
            </div>
          </h2>
        </div>
        <div className="row mt-3 mb-4 pr-3 pl-3">
          <div className="col-lg-12">
            <div className="" style={{ backgroundColor: "#F3F3F3" }}>
              <div className="table-full">
                <table className="table table-bordered table-sm ">
                  <thead>
                    <tr className="bg-secondary text-white table-sel">
                      <td
                        style={{
                          width: "50px",
                        }}
                      >
                        Id
                      </td>
                      <td
                        onClick={() => sorting("createdAt")}
                        style={{
                          width: "150px",
                        }}
                      >
                        Date Created{" "}
                        {corder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                      </td>
                      <td onClick={() => sorting("SourceName")}>
                        Source Name{" "}
                        {sorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                      </td>
                      <td onClick={() => sorting("Description")}>
                        Description
                        {dorder === "ASC" ? <BsArrowDown /> : <BsArrowUp />}
                      </td>
                      <td
                        style={{
                          width: "70px",
                        }}
                      >
                        Action
                      </td>
                    </tr>
                  </thead>

                  {loading && <Spin size="large" />}
                  <tbody className="tbody2">
                    {source &&
                      source.map((val, idx) => {
                        idx = idx + 1;
                        let alter = idx % 2 === 1 ? "#fff" : "#E9F0FF";
                        return (
                          <>
                            <tr
                              className="table-details"
                              key={idx}
                              style={{ backgroundColor: alter }}
                            >
                              <td
                                style={{
                                  width: "50px",
                                }}
                              >
                                {idx}
                              </td>
                              <td
                                style={{
                                  width: "150px",
                                }}
                              >
                                {val.createdAt &&
                                  val.createdAt
                                    .slice(0, 10)
                                    .split("-")
                                    .reverse()
                                    .join("/")}
                              </td>
                              <td>{val.SourceName}</td>
                              <td>{val.Description}</td>
                              <td
                                style={{
                                  width: "70px",
                                }}
                              >
                                {/* <Link to={'/viewsource/' + val._id}>
                                  <i className="fa fa-eye pl-2 pr-2 actionBtn"></i>
                                </Link> */}
                                <Link to={"/editsource/" + val._id}>
                                  {" "}
                                  <i className="fa fa-edit pl-2 pr-2 actionBtn"></i>
                                </Link>
                                <a
                                  data-toggle="modal"
                                  data-target="#mymodal"
                                  onClick={() => setID(val._id)}
                                >
                                  <i className="fa fa-trash pl-2 pr-2 actionBtn"></i>
                                </a>
                              </td>
                            </tr>

                            <div
                              className="modal fade"
                              id="mymodal"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div
                                className="modal-dialog modal-md modal-dialog-centered"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">
                                      Confirmation
                                    </h5>
                                  </div>
                                  <div className="modal-body">
                                    Are you sure to delete this Information
                                    permanently?
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-flat"
                                      id="confirm"
                                      onClick={() => deleteLead(ID)}
                                      data-dismiss="modal"
                                    >
                                      Continue
                                    </button>
                                    <button
                                      type="button"
                                      className="btn  border btn-flat"
                                      data-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SourceList;
