import React, { useEffect, useState } from "react";
import Axios from "axios";
import Navbar from "./Navbar";
import TextField from "@mui/material/TextField";
import { AiOutlineEye } from "react-icons/ai";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "./firebase";
import { v4 } from "uuid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { message, Spin } from "antd";
import { useHistory, useParams } from "react-router-dom";

const EditImage = () => {
  const history = useHistory();
  const params = useParams();
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    document.title = "Edit Image";
  }, []);

  useEffect(() => {
    Axios.get("/api/templateimage/readOneTemplate/" + params.id).then(
      (response) => {
        setTimeout(function () {
          setLoading(false);
        }, 100);
        setRowData(response.data.Templates);
      }
    );
  }, []);

  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [imgurl, setImgurl] = useState(rowData.Image);
  const [fileNames, setFileNames] = useState([]);
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(rowData.Title);
  const [titleError, setTitleError] = useState(false);
  const [description, setDescription] = useState(rowData.Description);

  const setImageUploadFunction = (file) => {
    setImageUpload(file);
    const imageRef = ref(storage, `images/${file.name + v4()}`);
    uploadBytes(imageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
        setImgurl(url);
      });
    });
  };

  const [selectedImage, setSelectedImage] = useState();

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const addToList = () => {
    setTitleError(false);

    if (title === "") {
      setTitleError(true);
      return;
    }

    // if (description === '') {
    //   message.error('Please Enter Description')
    //   return
    // }
    const config = { headers: { "Content-Type": "application/json" } };

    Axios.put(
      "/api/templateImage/modifyTemplate/" + params.id,
      {
        Title: title,
        Description: description,
        Image: imgurl,
      },
      config
    )
      .then(function (response) {
        message.success(" Updated successfully");
        setTimeout(function () {
          history.push("/img-template");
        }, 1000);
      })
      .catch(function (error) {
        message.error(`${error.response.data.message}`);
      });
  };

  return (
    <>
      <Navbar title="Edit Image" show="false" />
      <div
        className="container-fluid pt-4"
        style={{ backgroundColor: "#F0F2FA" }}
      >
        <div
          className="card"
          style={{
            backgroundColor: "#F3F3F3",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: "20px",
              marginTop: "20px",

              minWidth: "700px",
            }}
          >
            <TextField
              value={rowData.Title}
              className="lead-fields"
              style={{ width: "100%" }}
              required
              id="outlined-required"
              onChange={(e) => setTitle(e.target.value)}
              error={titleError}
            />
          </div>
          <div
            style={{
              minWidth: "700px",
              marginBottom: "20px",
            }}
          >
            <input
              style={{ maxWidth: "100%" }}
              type="file"
              accept="image/*"
              required
              onChange={(event) => {
                setImageUploadFunction(event.target.files[0]);
                imageChange(event);
              }}
            />{" "}
          </div>
          <div
            style={{
              minWidth: "700px",
            }}
          >
            <TextareaAutosize
              defaultValue={rowData.Description}
              aria-label="minimum height"
              minRows={5}
              placeholder="Content"
              style={{ width: "100%", padding: "10px" }}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div
            style={{
              minWidth: "200px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {!selectedImage && (
              <div
                className="col-lg-12 pt-2 pb-2"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <div className=" d-flex justify-content-center" style={{}}>
                  <img
                    src={rowData.Image}
                    style={{
                      display: "block",
                      height: "200px",
                      width: "100%",
                    }}
                    className=" img-fluid"
                    alt="Thumb"
                  />
                </div>
              </div>
            )}

            {selectedImage && (
              <div
                className="col-lg-12 pt-2 pb-2"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <div className=" d-flex justify-content-center" style={{}}>
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    style={{
                      display: "block",
                      height: "200px",
                      width: "100%",
                    }}
                    className=" img-fluid"
                    alt="Thumb"
                  />
                </div>
              </div>
            )}
          </div>

          <div>
            <button onClick={addToList} className="btn btn-primary add-button">
              Edit Image Template
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditImage;
